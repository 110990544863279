import { Ampli } from "@/ampli";
import type {} from "@redux-devtools/extension"; // required for devtools typing
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type BootTime = {
  event: string;
  name: string;
  time: string;
};

interface BootTimesState {
  logBootTimes: boolean;
  logsShown: boolean;
  active: boolean;
  bootTimes: BootTime[];
  addBootTime: ({ event, name }: { event: string; name: string }) => void;
  setLogBootTimes: (value: boolean) => void;
  amplitude: Ampli;
  injectAmplitude: (value: Ampli) => void;
  loggBootTimes: () => void;
}

export const useBootTimesStore = create<BootTimesState>()(
  devtools(
    (set, getState) => ({
      amplitude: {} as Ampli,
      logBootTimes: false,
      logsShown: false,
      active: true,
      bootTimes: [],
      setLogBootTimes: (value: boolean) => set({ logBootTimes: value }),
      injectAmplitude: (value: Ampli) => set({ amplitude: value }),
      loggBootTimes: () => {
        const { amplitude, logBootTimes, logsShown, bootTimes, active } =
          getState();

        if (!logBootTimes || active === true || logsShown === true) {
          return;
        }
        const groups = new Set([...bootTimes.map((item) => item.event)]);
        const groupedEvents = [];
        for (const group of groups) {
          const groupItems = bootTimes?.filter((bt) => bt.event === group);
          const starTime = new Date(groupItems[0].time).getTime();
          const endTime = new Date(
            groupItems[groupItems.length - 1].time,
          ).getTime();
          const diff = Math.floor(endTime - starTime) / 1000;
          groupedEvents.push({
            key: group,
            starTime,
            endTime,
            diff,
            groups: groupItems,
          });
        }

        /**
         * Track Each Event via Amplitude
         */
        for (const type of groupedEvents) {
          amplitude.applicationBootTime({
            event: type.key,
            startTime: `${type.starTime}`,
            endTime: `${type.endTime}`,
            totalTime: `${type.diff} seconds`,
          });
        }

        set(() => ({ logsShown: true }));
      },
      addBootTime: ({ event, name }) => {
        set(() => ({ active: true }));
        set((state) => {
          const existing = !!state.bootTimes?.find(
            (bt) => bt.event === event && bt.name === name,
          );
          if (existing) {
            return {
              bootTimes: state.bootTimes,
            };
          }
          return {
            bootTimes: [
              ...state.bootTimes,
              { event, name, time: new Date().toISOString() },
            ],
          };
        });
        const active = getState().active;

        setTimeout(() => {
          if (active === true) {
            set(() => ({ active: false }));
            getState().loggBootTimes();
          }
        }, 30000);
      },
    }),
    {
      name: "boot-times-store",
    },
  ),
);
