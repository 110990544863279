import { createContext, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { WsEvent, WsItem } from "web-client/api/data-contracts";
import { Item } from "@/generated/client";
import { AccountEventType } from "web-client/api/data-contracts";
import {
  updateUnreadItemByEvents,
  updateUnreadItemByFeedId,
} from "@/data/workspace";

type UnreadFeed = {
  feedId: string;
  unreadItems: Array<Item>;
};

export const unreadClearEvents: AccountEventType[] = [
  "Saw Feed Item",
  "Marked Feed as Read",
  "Opened Feed",
  "Started Listening to Feed Item", // used for in-line feed views
];

export type UnreadsState = {
  allUnreadFeeds?: Array<UnreadFeed>;
  unreadFeedIds?: Array<string>;
  handleUnreadItem: (items: WsItem) => Promise<void>;
  handleReadItemEvent: (events: WsEvent) => Promise<void>;
};

export const UnreadsContext = createContext<UnreadsState>({
  allUnreadFeeds: [],
  handleUnreadItem: async () => {},
  handleReadItemEvent: async () => {},
});

const UnreadsContextProvider = ({ children }) => {
  const { db } = useElectric();
  const { workspaceId } = useParams();

  const { results: myAccount } = useLiveQuery(
    db.account.liveFirst({
      where: {
        mine: 1,
      },
    }),
  );

  const { results: myMembership } = useLiveQuery(() => {
    if (!myAccount?.id || !workspaceId) return;
    return db.workspace_membership.liveFirst({
      where: {
        workspaceId: workspaceId,
        accountId: myAccount?.id,
      },
    });
  }, [myAccount?.id, workspaceId]);

  const handleUnreadItem = useCallback(
    async (item: WsItem) => {
      if (!myAccount?.id) return;
      updateUnreadItemByFeedId(db, item, myAccount?.id);
    },
    [db, myAccount?.id],
  );

  const handleReadItemEvent = useCallback(
    async (event: WsEvent) => {
      if (!myAccount?.id) return;
      updateUnreadItemByEvents(db, event, myAccount?.id);
    },
    [db, myAccount?.id],
  );

  const { results: unreadFeedIds } = useLiveQuery(
    db.liveRaw({
      // fetch unread feed ids for feeds that the user has an enabled read permission
      sql: `
      SELECT DISTINCT item.feedId
      FROM item
      JOIN feed 
        ON item.feedId = feed.id
      JOIN permission 
        ON feed.id = permission.feedId
        AND permission.workspace_membershipId = $1
        AND permission.name = 'read'
        AND permission.enabled = true
      WHERE unread = 1
        AND deletedAt IS NULL
      `,
      args: [myMembership?.id],
    }),
  );

  const addBadge = (favicon) => {
    favicon.href = "/favicon-unread.svg";
  };

  const removeBadge = (favicon) => {
    // reset the back to the default favicon
    favicon.href = "/favicon.svg";
  };

  const updateDocumentTitle = (unreadFeedCount = 0) => {
    const baseTitle = document.title.replace(/\(\d+\)\s*/, ""); // Remove any existing (unreadCount)
    if (unreadFeedCount > 0) {
      document.title = `(${unreadFeedCount}) ${baseTitle}`;
    } else {
      document.title = baseTitle;
    }
  };

  useEffect(() => {
    if (!unreadFeedIds) return;
    if (unreadFeedIds?.length > 0) {
      document
        .querySelectorAll(
          'link[rel="mask-icon"], link[rel="icon"], link[rel="apple-touch-icon"]',
        )
        .forEach(addBadge);
      updateDocumentTitle(unreadFeedIds?.length);
    } else {
      document
        .querySelectorAll(
          'link[rel="mask-icon"], link[rel="icon"], link[rel="apple-touch-icon"]',
        )
        .forEach(removeBadge);
      updateDocumentTitle();
    }
  }, [unreadFeedIds?.length]);

  const unreadsState: UnreadsState = {
    unreadFeedIds: unreadFeedIds?.map((ufi) => ufi.feedId),
    handleUnreadItem,
    handleReadItemEvent,
  };

  return (
    <UnreadsContext.Provider value={unreadsState}>
      {children}
    </UnreadsContext.Provider>
  );
};

export default UnreadsContextProvider;
