import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { TableSchema, DbSchema, Relation, ElectricClient, HKT } from 'electric-sql/client/model';
import migrations from './migrations';

/////////////////////////////////////////
// HELPER FUNCTIONS
/////////////////////////////////////////


/////////////////////////////////////////
// ENUMS
/////////////////////////////////////////

export const AccountEventScalarFieldEnumSchema = z.enum(['id','accountId','name','createdAt','feedId','itemId','contentId']);

export const AccountScalarFieldEnumSchema = z.enum(['id','firstName','lastName','email','emailVerified','phoneNumber','phoneNumberVerified','name','avatarColor','accountType','mine','preferredLanguage']);

export const AppPhoneNumberScalarFieldEnumSchema = z.enum(['id','phoneNumber','forwardingNumber','feedId']);

export const AudioEncodingScalarFieldEnumSchema = z.enum(['id','contentId','codec','mimeType','duration','url','transcriptionId','transcriptionType','language','translatedFrom','priority','generatedVoice','generatedService','createdAt']);

export const AudioQueueItemScalarFieldEnumSchema = z.enum(['id','itemId','createdAt']);

export const BroadcastActionScalarFieldEnumSchema = z.enum(['id','workspaceId','workflowItemId','broadcastId','createdAt','updatedAt']);

export const BroadcastRecipientScalarFieldEnumSchema = z.enum(['id','broadcastId','workspaceId','feedId','workspaceMembershipId','createdAt']);

export const BroadcastScalarFieldEnumSchema = z.enum(['id','workspaceId','createdAt']);

export const CallRecordScalarFieldEnumSchema = z.enum(['id','contentId','appPhoneNumberId','toNumber','fromNumber','direction']);

export const DeviceRegistrationScalarFieldEnumSchema = z.enum(['id','createdAt','updatedAt','surface','deviceOsName','deviceOsVersion','deviceModel','friendlyName','drivingDetectionEnabled','wakeWordSensitivity','commandSensitivity','silenceDetectionInitialThreshold','silenceDetectionFinalThreshold','commandTimingWindowMs','recordingTimeoutWindowMs']);

export const DirectWsInvitationScalarFieldEnumSchema = z.enum(['id','workspaceMembershipId','email','phoneNumber','name','claimedAt','claimedBy','createdAt','updatedAt']);

export const DisplayArtifactScalarFieldEnumSchema = z.enum(['id','title','description','contentId','deletedAt','createdAt']);

export const FeedGroupMembershipScalarFieldEnumSchema = z.enum(['id','workspaceId','feedId','groupId','createdAt','updatedAt','enabled']);

export const FeedGroupScalarFieldEnumSchema = z.enum(['id','name','accountId','workspaceId','createdAt','updatedAt','enabled']);

export const FeedScalarFieldEnumSchema = z.enum(['id','title','workspaceId','feedType','readOnly','isPrivate','isDm','isSilent','loadedFirstPage','loadedLastPage','loadedEvents','loadedPermissions','createdAt','updatedAt','latestActivity','lastOpened']);

export const FileScalarFieldEnumSchema = z.enum(['id','contentId','url','mimeType','name']);

export const ItemScalarFieldEnumSchema = z.enum(['id','feedId','contentId','groupId','deletedAt','accountId','loadedContent','isSilent','unread','isDriverMessage','isOrganizerMessage','isAdminMessage','createdAt','status']);

export const LinkScalarFieldEnumSchema = z.enum(['id','contentId','url','description','title','image','linkOrder']);

export const PermissionScalarFieldEnumSchema = z.enum(['id','name','accountId','workspace_membershipId','feedId','workflowItemId','enabled','expiresAt','createdAt','updatedAt']);

export const PipelineArtifactMetadataScalarFieldEnumSchema = z.enum(['id','contentId','vadResult','createdAt','updatedAt']);

export const PublishedWorkflowItemScalarFieldEnumSchema = z.enum(['id','workflowItemId','broadcastId','accountId','workspaceId','contentId','createdAt']);

export const QueryModeSchema = z.enum(['default','insensitive']);

export const ScheduleTriggerScalarFieldEnumSchema = z.enum(['id','oneTimeSchedule','cronSchedule','workspaceId','broadcastActionId','createdAt','updatedAt','timezone','enabled']);

export const SortOrderSchema = z.enum(['asc','desc']);

export const TemplateScalarFieldEnumSchema = z.enum(['id','name','template','workspaceId','authorId','createdAt','updatedAt','deletedAt']);

export const TransactionIsolationLevelSchema = z.enum(['ReadUncommitted','ReadCommitted','RepeatableRead','Serializable']);

export const TranscriptionScalarFieldEnumSchema = z.enum(['id','contentId','transcriptionType','transcriptionContent','language','translatedFrom','backendModel','priority','confidence','executionTime','format','pipeline','bucket','key','url','createdAt']);

export const WorkflowItemScalarFieldEnumSchema = z.enum(['id','workspaceId','contentId','createdAt','deletedAt','displayName']);

export const WorkspaceCommandAliasScalarFieldEnumSchema = z.enum(['id','alias','workspaceId','feedId','workspaceMembershipId','createdAt']);

export const WorkspaceMembershipScalarFieldEnumSchema = z.enum(['id','workspaceId','accountId','status','role']);

export const WorkspaceScalarFieldEnumSchema = z.enum(['id','name']);

export const WsHandsFreeStatusScalarFieldEnumSchema = z.enum(['id','enabled','timestamp']);
/////////////////////////////////////////
// MODELS
/////////////////////////////////////////

/////////////////////////////////////////
// ACCOUNT SCHEMA
/////////////////////////////////////////

export const AccountSchema = z.object({
  id: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string().nullable(),
  emailVerified: z.number().int(),
  phoneNumber: z.string().nullable(),
  phoneNumberVerified: z.number().int(),
  name: z.string().nullable(),
  avatarColor: z.string().nullable(),
  accountType: z.string().nullable(),
  mine: z.number().int(),
  preferredLanguage: z.string().nullable(),
})

export type Account = z.infer<typeof AccountSchema>

/////////////////////////////////////////
// ACCOUNT EVENT SCHEMA
/////////////////////////////////////////

export const AccountEventSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  name: z.string(),
  createdAt: z.string(),
  feedId: z.string().nullable(),
  itemId: z.string().nullable(),
  contentId: z.string().nullable(),
})

export type AccountEvent = z.infer<typeof AccountEventSchema>

/////////////////////////////////////////
// APP PHONE NUMBER SCHEMA
/////////////////////////////////////////

export const AppPhoneNumberSchema = z.object({
  id: z.string(),
  phoneNumber: z.string(),
  forwardingNumber: z.string().nullable(),
  feedId: z.string().nullable(),
})

export type AppPhoneNumber = z.infer<typeof AppPhoneNumberSchema>

/////////////////////////////////////////
// AUDIO ENCODING SCHEMA
/////////////////////////////////////////

export const AudioEncodingSchema = z.object({
  id: z.string(),
  contentId: z.string(),
  codec: z.string(),
  mimeType: z.string().nullable(),
  duration: z.number(),
  url: z.string(),
  transcriptionId: z.string().nullable(),
  transcriptionType: z.string().nullable(),
  language: z.string().nullable(),
  translatedFrom: z.string().nullable(),
  priority: z.number().int(),
  generatedVoice: z.string().nullable(),
  generatedService: z.string().nullable(),
  createdAt: z.string().nullable(),
})

export type AudioEncoding = z.infer<typeof AudioEncodingSchema>

/////////////////////////////////////////
// AUDIO QUEUE ITEM SCHEMA
/////////////////////////////////////////

export const AudioQueueItemSchema = z.object({
  id: z.string(),
  itemId: z.string().nullable(),
  createdAt: z.string().nullable(),
})

export type AudioQueueItem = z.infer<typeof AudioQueueItemSchema>

/////////////////////////////////////////
// BROADCAST SCHEMA
/////////////////////////////////////////

export const BroadcastSchema = z.object({
  id: z.string(),
  workspaceId: z.string(),
  createdAt: z.string().nullable(),
})

export type Broadcast = z.infer<typeof BroadcastSchema>

/////////////////////////////////////////
// BROADCAST ACTION SCHEMA
/////////////////////////////////////////

export const BroadcastActionSchema = z.object({
  id: z.string(),
  workspaceId: z.string(),
  workflowItemId: z.string(),
  broadcastId: z.string(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
})

export type BroadcastAction = z.infer<typeof BroadcastActionSchema>

/////////////////////////////////////////
// BROADCAST RECIPIENT SCHEMA
/////////////////////////////////////////

export const BroadcastRecipientSchema = z.object({
  id: z.string(),
  broadcastId: z.string(),
  workspaceId: z.string(),
  feedId: z.string().nullable(),
  workspaceMembershipId: z.string().nullable(),
  createdAt: z.string().nullable(),
})

export type BroadcastRecipient = z.infer<typeof BroadcastRecipientSchema>

/////////////////////////////////////////
// CALL RECORD SCHEMA
/////////////////////////////////////////

export const CallRecordSchema = z.object({
  id: z.string(),
  contentId: z.string(),
  appPhoneNumberId: z.string(),
  toNumber: z.string().nullable(),
  fromNumber: z.string().nullable(),
  direction: z.string().nullable(),
})

export type CallRecord = z.infer<typeof CallRecordSchema>

/////////////////////////////////////////
// DEVICE REGISTRATION SCHEMA
/////////////////////////////////////////

export const DeviceRegistrationSchema = z.object({
  id: z.string(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
  surface: z.string().nullable(),
  deviceOsName: z.string().nullable(),
  deviceOsVersion: z.string().nullable(),
  deviceModel: z.string().nullable(),
  friendlyName: z.string().nullable(),
  drivingDetectionEnabled: z.number().int(),
  wakeWordSensitivity: z.number(),
  commandSensitivity: z.number(),
  silenceDetectionInitialThreshold: z.number(),
  silenceDetectionFinalThreshold: z.number(),
  commandTimingWindowMs: z.number().int(),
  recordingTimeoutWindowMs: z.number().int(),
})

export type DeviceRegistration = z.infer<typeof DeviceRegistrationSchema>

/////////////////////////////////////////
// DIRECT WS INVITATION SCHEMA
/////////////////////////////////////////

export const DirectWsInvitationSchema = z.object({
  id: z.string(),
  workspaceMembershipId: z.string(),
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  name: z.string().nullable(),
  claimedAt: z.string().nullable(),
  claimedBy: z.string().nullable(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
})

export type DirectWsInvitation = z.infer<typeof DirectWsInvitationSchema>

/////////////////////////////////////////
// DISPLAY ARTIFACT SCHEMA
/////////////////////////////////////////

export const DisplayArtifactSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  contentId: z.string(),
  deletedAt: z.string().nullable(),
  createdAt: z.string(),
})

export type DisplayArtifact = z.infer<typeof DisplayArtifactSchema>

/////////////////////////////////////////
// FEED SCHEMA
/////////////////////////////////////////

export const FeedSchema = z.object({
  id: z.string(),
  title: z.string().nullable(),
  workspaceId: z.string().nullable(),
  feedType: z.string(),
  readOnly: z.number().int(),
  isPrivate: z.number().int(),
  isDm: z.number().int(),
  isSilent: z.number().int(),
  loadedFirstPage: z.number().int(),
  loadedLastPage: z.number().int(),
  loadedEvents: z.number().int(),
  loadedPermissions: z.number().int(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
  latestActivity: z.string().nullable(),
  lastOpened: z.string().nullable(),
})

export type Feed = z.infer<typeof FeedSchema>

/////////////////////////////////////////
// FEED GROUP SCHEMA
/////////////////////////////////////////

export const FeedGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  accountId: z.string().nullable(),
  workspaceId: z.string(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
  enabled: z.number().int(),
})

export type FeedGroup = z.infer<typeof FeedGroupSchema>

/////////////////////////////////////////
// FEED GROUP MEMBERSHIP SCHEMA
/////////////////////////////////////////

export const FeedGroupMembershipSchema = z.object({
  id: z.string(),
  workspaceId: z.string(),
  feedId: z.string(),
  groupId: z.string(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
  enabled: z.number().int(),
})

export type FeedGroupMembership = z.infer<typeof FeedGroupMembershipSchema>

/////////////////////////////////////////
// FILE SCHEMA
/////////////////////////////////////////

export const FileSchema = z.object({
  id: z.string(),
  contentId: z.string(),
  url: z.string(),
  mimeType: z.string().nullable(),
  name: z.string().nullable(),
})

export type File = z.infer<typeof FileSchema>

/////////////////////////////////////////
// ITEM SCHEMA
/////////////////////////////////////////

export const ItemSchema = z.object({
  id: z.string(),
  feedId: z.string(),
  contentId: z.string(),
  groupId: z.string().nullable(),
  deletedAt: z.string().nullable(),
  accountId: z.string().nullable(),
  loadedContent: z.number().int(),
  isSilent: z.number().int(),
  unread: z.number().int().nullable(),
  isDriverMessage: z.number().int().nullable(),
  isOrganizerMessage: z.number().int().nullable(),
  isAdminMessage: z.number().int().nullable(),
  createdAt: z.string(),
  status: z.string().nullable(),
})

export type Item = z.infer<typeof ItemSchema>

/////////////////////////////////////////
// LINK SCHEMA
/////////////////////////////////////////

export const LinkSchema = z.object({
  id: z.string(),
  contentId: z.string(),
  url: z.string(),
  description: z.string().nullable(),
  title: z.string().nullable(),
  image: z.string().nullable(),
  linkOrder: z.number().int(),
})

export type Link = z.infer<typeof LinkSchema>

/////////////////////////////////////////
// PERMISSION SCHEMA
/////////////////////////////////////////

export const PermissionSchema = z.object({
  id: z.string(),
  name: z.string(),
  accountId: z.string().nullable(),
  workspace_membershipId: z.string().nullable(),
  feedId: z.string().nullable(),
  workflowItemId: z.string().nullable(),
  enabled: z.number().int(),
  expiresAt: z.string().nullable(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
})

export type Permission = z.infer<typeof PermissionSchema>

/////////////////////////////////////////
// PIPELINE ARTIFACT METADATA SCHEMA
/////////////////////////////////////////

export const PipelineArtifactMetadataSchema = z.object({
  id: z.string(),
  contentId: z.string(),
  vadResult: z.string().nullable(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
})

export type PipelineArtifactMetadata = z.infer<typeof PipelineArtifactMetadataSchema>

/////////////////////////////////////////
// PUBLISHED WORKFLOW ITEM SCHEMA
/////////////////////////////////////////

export const PublishedWorkflowItemSchema = z.object({
  id: z.string(),
  workflowItemId: z.string(),
  broadcastId: z.string(),
  accountId: z.string(),
  workspaceId: z.string(),
  contentId: z.string(),
  createdAt: z.string().nullable(),
})

export type PublishedWorkflowItem = z.infer<typeof PublishedWorkflowItemSchema>

/////////////////////////////////////////
// SCHEDULE TRIGGER SCHEMA
/////////////////////////////////////////

export const ScheduleTriggerSchema = z.object({
  id: z.string(),
  oneTimeSchedule: z.string().nullable(),
  cronSchedule: z.string().nullable(),
  workspaceId: z.string(),
  broadcastActionId: z.string().nullable(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
  timezone: z.string().nullable(),
  enabled: z.number().int(),
})

export type ScheduleTrigger = z.infer<typeof ScheduleTriggerSchema>

/////////////////////////////////////////
// TEMPLATE SCHEMA
/////////////////////////////////////////

export const TemplateSchema = z.object({
  id: z.string(),
  name: z.string(),
  template: z.string(),
  workspaceId: z.string(),
  authorId: z.string(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
  deletedAt: z.string().nullable(),
})

export type Template = z.infer<typeof TemplateSchema>

/////////////////////////////////////////
// TRANSCRIPTION SCHEMA
/////////////////////////////////////////

export const TranscriptionSchema = z.object({
  id: z.string(),
  contentId: z.string(),
  transcriptionType: z.string(),
  transcriptionContent: z.string(),
  language: z.string(),
  translatedFrom: z.string().nullable(),
  backendModel: z.string().nullable(),
  priority: z.number().int(),
  confidence: z.number().nullable(),
  executionTime: z.number(),
  format: z.string(),
  pipeline: z.string().nullable(),
  bucket: z.string().nullable(),
  key: z.string().nullable(),
  url: z.string().nullable(),
  createdAt: z.string().nullable(),
})

export type Transcription = z.infer<typeof TranscriptionSchema>

/////////////////////////////////////////
// WORKFLOW ITEM SCHEMA
/////////////////////////////////////////

export const WorkflowItemSchema = z.object({
  id: z.string(),
  workspaceId: z.string(),
  contentId: z.string(),
  createdAt: z.string().nullable(),
  deletedAt: z.string().nullable(),
  displayName: z.string().nullable(),
})

export type WorkflowItem = z.infer<typeof WorkflowItemSchema>

/////////////////////////////////////////
// WORKSPACE SCHEMA
/////////////////////////////////////////

export const WorkspaceSchema = z.object({
  id: z.string(),
  name: z.string(),
})

export type Workspace = z.infer<typeof WorkspaceSchema>

/////////////////////////////////////////
// WORKSPACE COMMAND ALIAS SCHEMA
/////////////////////////////////////////

export const WorkspaceCommandAliasSchema = z.object({
  id: z.string(),
  alias: z.string(),
  workspaceId: z.string(),
  feedId: z.string().nullable(),
  workspaceMembershipId: z.string(),
  createdAt: z.string(),
})

export type WorkspaceCommandAlias = z.infer<typeof WorkspaceCommandAliasSchema>

/////////////////////////////////////////
// WORKSPACE MEMBERSHIP SCHEMA
/////////////////////////////////////////

export const WorkspaceMembershipSchema = z.object({
  id: z.string(),
  workspaceId: z.string(),
  accountId: z.string().nullable(),
  status: z.string(),
  role: z.string(),
})

export type WorkspaceMembership = z.infer<typeof WorkspaceMembershipSchema>

/////////////////////////////////////////
// WS HANDS FREE STATUS SCHEMA
/////////////////////////////////////////

export const WsHandsFreeStatusSchema = z.object({
  id: z.string(),
  enabled: z.number().int(),
  timestamp: z.string(),
})

export type WsHandsFreeStatus = z.infer<typeof WsHandsFreeStatusSchema>

/////////////////////////////////////////
// SELECT & INCLUDE
/////////////////////////////////////////

// ACCOUNT
//------------------------------------------------------

export const AccountSelectSchema: z.ZodType<Prisma.AccountSelect> = z.object({
  id: z.boolean().optional(),
  firstName: z.boolean().optional(),
  lastName: z.boolean().optional(),
  email: z.boolean().optional(),
  emailVerified: z.boolean().optional(),
  phoneNumber: z.boolean().optional(),
  phoneNumberVerified: z.boolean().optional(),
  name: z.boolean().optional(),
  avatarColor: z.boolean().optional(),
  accountType: z.boolean().optional(),
  mine: z.boolean().optional(),
  preferredLanguage: z.boolean().optional(),
}).strict()

// ACCOUNT EVENT
//------------------------------------------------------

export const AccountEventSelectSchema: z.ZodType<Prisma.AccountEventSelect> = z.object({
  id: z.boolean().optional(),
  accountId: z.boolean().optional(),
  name: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  feedId: z.boolean().optional(),
  itemId: z.boolean().optional(),
  contentId: z.boolean().optional(),
}).strict()

// APP PHONE NUMBER
//------------------------------------------------------

export const AppPhoneNumberSelectSchema: z.ZodType<Prisma.AppPhoneNumberSelect> = z.object({
  id: z.boolean().optional(),
  phoneNumber: z.boolean().optional(),
  forwardingNumber: z.boolean().optional(),
  feedId: z.boolean().optional(),
}).strict()

// AUDIO ENCODING
//------------------------------------------------------

export const AudioEncodingSelectSchema: z.ZodType<Prisma.AudioEncodingSelect> = z.object({
  id: z.boolean().optional(),
  contentId: z.boolean().optional(),
  codec: z.boolean().optional(),
  mimeType: z.boolean().optional(),
  duration: z.boolean().optional(),
  url: z.boolean().optional(),
  transcriptionId: z.boolean().optional(),
  transcriptionType: z.boolean().optional(),
  language: z.boolean().optional(),
  translatedFrom: z.boolean().optional(),
  priority: z.boolean().optional(),
  generatedVoice: z.boolean().optional(),
  generatedService: z.boolean().optional(),
  createdAt: z.boolean().optional(),
}).strict()

// AUDIO QUEUE ITEM
//------------------------------------------------------

export const AudioQueueItemSelectSchema: z.ZodType<Prisma.AudioQueueItemSelect> = z.object({
  id: z.boolean().optional(),
  itemId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
}).strict()

// BROADCAST
//------------------------------------------------------

export const BroadcastSelectSchema: z.ZodType<Prisma.BroadcastSelect> = z.object({
  id: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
}).strict()

// BROADCAST ACTION
//------------------------------------------------------

export const BroadcastActionSelectSchema: z.ZodType<Prisma.BroadcastActionSelect> = z.object({
  id: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  workflowItemId: z.boolean().optional(),
  broadcastId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
}).strict()

// BROADCAST RECIPIENT
//------------------------------------------------------

export const BroadcastRecipientSelectSchema: z.ZodType<Prisma.BroadcastRecipientSelect> = z.object({
  id: z.boolean().optional(),
  broadcastId: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  feedId: z.boolean().optional(),
  workspaceMembershipId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
}).strict()

// CALL RECORD
//------------------------------------------------------

export const CallRecordSelectSchema: z.ZodType<Prisma.CallRecordSelect> = z.object({
  id: z.boolean().optional(),
  contentId: z.boolean().optional(),
  appPhoneNumberId: z.boolean().optional(),
  toNumber: z.boolean().optional(),
  fromNumber: z.boolean().optional(),
  direction: z.boolean().optional(),
}).strict()

// DEVICE REGISTRATION
//------------------------------------------------------

export const DeviceRegistrationSelectSchema: z.ZodType<Prisma.DeviceRegistrationSelect> = z.object({
  id: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  surface: z.boolean().optional(),
  deviceOsName: z.boolean().optional(),
  deviceOsVersion: z.boolean().optional(),
  deviceModel: z.boolean().optional(),
  friendlyName: z.boolean().optional(),
  drivingDetectionEnabled: z.boolean().optional(),
  wakeWordSensitivity: z.boolean().optional(),
  commandSensitivity: z.boolean().optional(),
  silenceDetectionInitialThreshold: z.boolean().optional(),
  silenceDetectionFinalThreshold: z.boolean().optional(),
  commandTimingWindowMs: z.boolean().optional(),
  recordingTimeoutWindowMs: z.boolean().optional(),
}).strict()

// DIRECT WS INVITATION
//------------------------------------------------------

export const DirectWsInvitationSelectSchema: z.ZodType<Prisma.DirectWsInvitationSelect> = z.object({
  id: z.boolean().optional(),
  workspaceMembershipId: z.boolean().optional(),
  email: z.boolean().optional(),
  phoneNumber: z.boolean().optional(),
  name: z.boolean().optional(),
  claimedAt: z.boolean().optional(),
  claimedBy: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
}).strict()

// DISPLAY ARTIFACT
//------------------------------------------------------

export const DisplayArtifactSelectSchema: z.ZodType<Prisma.DisplayArtifactSelect> = z.object({
  id: z.boolean().optional(),
  title: z.boolean().optional(),
  description: z.boolean().optional(),
  contentId: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  createdAt: z.boolean().optional(),
}).strict()

// FEED
//------------------------------------------------------

export const FeedSelectSchema: z.ZodType<Prisma.FeedSelect> = z.object({
  id: z.boolean().optional(),
  title: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  feedType: z.boolean().optional(),
  readOnly: z.boolean().optional(),
  isPrivate: z.boolean().optional(),
  isDm: z.boolean().optional(),
  isSilent: z.boolean().optional(),
  loadedFirstPage: z.boolean().optional(),
  loadedLastPage: z.boolean().optional(),
  loadedEvents: z.boolean().optional(),
  loadedPermissions: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  latestActivity: z.boolean().optional(),
  lastOpened: z.boolean().optional(),
}).strict()

// FEED GROUP
//------------------------------------------------------

export const FeedGroupSelectSchema: z.ZodType<Prisma.FeedGroupSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
  accountId: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  enabled: z.boolean().optional(),
}).strict()

// FEED GROUP MEMBERSHIP
//------------------------------------------------------

export const FeedGroupMembershipSelectSchema: z.ZodType<Prisma.FeedGroupMembershipSelect> = z.object({
  id: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  feedId: z.boolean().optional(),
  groupId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  enabled: z.boolean().optional(),
}).strict()

// FILE
//------------------------------------------------------

export const FileSelectSchema: z.ZodType<Prisma.FileSelect> = z.object({
  id: z.boolean().optional(),
  contentId: z.boolean().optional(),
  url: z.boolean().optional(),
  mimeType: z.boolean().optional(),
  name: z.boolean().optional(),
}).strict()

// ITEM
//------------------------------------------------------

export const ItemSelectSchema: z.ZodType<Prisma.ItemSelect> = z.object({
  id: z.boolean().optional(),
  feedId: z.boolean().optional(),
  contentId: z.boolean().optional(),
  groupId: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  accountId: z.boolean().optional(),
  loadedContent: z.boolean().optional(),
  isSilent: z.boolean().optional(),
  unread: z.boolean().optional(),
  isDriverMessage: z.boolean().optional(),
  isOrganizerMessage: z.boolean().optional(),
  isAdminMessage: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  status: z.boolean().optional(),
}).strict()

// LINK
//------------------------------------------------------

export const LinkSelectSchema: z.ZodType<Prisma.LinkSelect> = z.object({
  id: z.boolean().optional(),
  contentId: z.boolean().optional(),
  url: z.boolean().optional(),
  description: z.boolean().optional(),
  title: z.boolean().optional(),
  image: z.boolean().optional(),
  linkOrder: z.boolean().optional(),
}).strict()

// PERMISSION
//------------------------------------------------------

export const PermissionSelectSchema: z.ZodType<Prisma.PermissionSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
  accountId: z.boolean().optional(),
  workspace_membershipId: z.boolean().optional(),
  feedId: z.boolean().optional(),
  workflowItemId: z.boolean().optional(),
  enabled: z.boolean().optional(),
  expiresAt: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
}).strict()

// PIPELINE ARTIFACT METADATA
//------------------------------------------------------

export const PipelineArtifactMetadataSelectSchema: z.ZodType<Prisma.PipelineArtifactMetadataSelect> = z.object({
  id: z.boolean().optional(),
  contentId: z.boolean().optional(),
  vadResult: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
}).strict()

// PUBLISHED WORKFLOW ITEM
//------------------------------------------------------

export const PublishedWorkflowItemSelectSchema: z.ZodType<Prisma.PublishedWorkflowItemSelect> = z.object({
  id: z.boolean().optional(),
  workflowItemId: z.boolean().optional(),
  broadcastId: z.boolean().optional(),
  accountId: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  contentId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
}).strict()

// SCHEDULE TRIGGER
//------------------------------------------------------

export const ScheduleTriggerSelectSchema: z.ZodType<Prisma.ScheduleTriggerSelect> = z.object({
  id: z.boolean().optional(),
  oneTimeSchedule: z.boolean().optional(),
  cronSchedule: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  broadcastActionId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  timezone: z.boolean().optional(),
  enabled: z.boolean().optional(),
}).strict()

// TEMPLATE
//------------------------------------------------------

export const TemplateSelectSchema: z.ZodType<Prisma.TemplateSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
  template: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  authorId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
}).strict()

// TRANSCRIPTION
//------------------------------------------------------

export const TranscriptionSelectSchema: z.ZodType<Prisma.TranscriptionSelect> = z.object({
  id: z.boolean().optional(),
  contentId: z.boolean().optional(),
  transcriptionType: z.boolean().optional(),
  transcriptionContent: z.boolean().optional(),
  language: z.boolean().optional(),
  translatedFrom: z.boolean().optional(),
  backendModel: z.boolean().optional(),
  priority: z.boolean().optional(),
  confidence: z.boolean().optional(),
  executionTime: z.boolean().optional(),
  format: z.boolean().optional(),
  pipeline: z.boolean().optional(),
  bucket: z.boolean().optional(),
  key: z.boolean().optional(),
  url: z.boolean().optional(),
  createdAt: z.boolean().optional(),
}).strict()

// WORKFLOW ITEM
//------------------------------------------------------

export const WorkflowItemSelectSchema: z.ZodType<Prisma.WorkflowItemSelect> = z.object({
  id: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  contentId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  displayName: z.boolean().optional(),
}).strict()

// WORKSPACE
//------------------------------------------------------

export const WorkspaceSelectSchema: z.ZodType<Prisma.WorkspaceSelect> = z.object({
  id: z.boolean().optional(),
  name: z.boolean().optional(),
}).strict()

// WORKSPACE COMMAND ALIAS
//------------------------------------------------------

export const WorkspaceCommandAliasSelectSchema: z.ZodType<Prisma.WorkspaceCommandAliasSelect> = z.object({
  id: z.boolean().optional(),
  alias: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  feedId: z.boolean().optional(),
  workspaceMembershipId: z.boolean().optional(),
  createdAt: z.boolean().optional(),
}).strict()

// WORKSPACE MEMBERSHIP
//------------------------------------------------------

export const WorkspaceMembershipSelectSchema: z.ZodType<Prisma.WorkspaceMembershipSelect> = z.object({
  id: z.boolean().optional(),
  workspaceId: z.boolean().optional(),
  accountId: z.boolean().optional(),
  status: z.boolean().optional(),
  role: z.boolean().optional(),
}).strict()

// WS HANDS FREE STATUS
//------------------------------------------------------

export const WsHandsFreeStatusSelectSchema: z.ZodType<Prisma.WsHandsFreeStatusSelect> = z.object({
  id: z.boolean().optional(),
  enabled: z.boolean().optional(),
  timestamp: z.boolean().optional(),
}).strict()


/////////////////////////////////////////
// INPUT TYPES
/////////////////////////////////////////

export const AccountWhereInputSchema: z.ZodType<Prisma.AccountWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountWhereInputSchema),z.lazy(() => AccountWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  firstName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  email: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  emailVerified: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  phoneNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  phoneNumberVerified: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  avatarColor: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  accountType: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  mine: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  preferredLanguage: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const AccountOrderByWithRelationInputSchema: z.ZodType<Prisma.AccountOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  phoneNumberVerified: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  avatarColor: z.lazy(() => SortOrderSchema).optional(),
  accountType: z.lazy(() => SortOrderSchema).optional(),
  mine: z.lazy(() => SortOrderSchema).optional(),
  preferredLanguage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountWhereUniqueInputSchema: z.ZodType<Prisma.AccountWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const AccountOrderByWithAggregationInputSchema: z.ZodType<Prisma.AccountOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  phoneNumberVerified: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  avatarColor: z.lazy(() => SortOrderSchema).optional(),
  accountType: z.lazy(() => SortOrderSchema).optional(),
  mine: z.lazy(() => SortOrderSchema).optional(),
  preferredLanguage: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => AccountCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => AccountAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AccountMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AccountMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => AccountSumOrderByAggregateInputSchema).optional()
}).strict();

export const AccountScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AccountScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AccountScalarWhereWithAggregatesInputSchema),z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountScalarWhereWithAggregatesInputSchema),z.lazy(() => AccountScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  firstName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  lastName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  email: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  emailVerified: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  phoneNumber: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  phoneNumberVerified: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  name: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  avatarColor: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  accountType: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  mine: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  preferredLanguage: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const AccountEventWhereInputSchema: z.ZodType<Prisma.AccountEventWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AccountEventWhereInputSchema),z.lazy(() => AccountEventWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountEventWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountEventWhereInputSchema),z.lazy(() => AccountEventWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  feedId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  itemId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  contentId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const AccountEventOrderByWithRelationInputSchema: z.ZodType<Prisma.AccountEventOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  itemId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountEventWhereUniqueInputSchema: z.ZodType<Prisma.AccountEventWhereUniqueInput> = z.object({
  id: z.string().optional(),
  accountId_feedId_itemId_contentId_name: z.lazy(() => AccountEventAccountIdFeedIdItemIdContentIdNameCompoundUniqueInputSchema).optional()
}).strict();

export const AccountEventOrderByWithAggregationInputSchema: z.ZodType<Prisma.AccountEventOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  itemId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => AccountEventCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AccountEventMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AccountEventMinOrderByAggregateInputSchema).optional()
}).strict();

export const AccountEventScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AccountEventScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AccountEventScalarWhereWithAggregatesInputSchema),z.lazy(() => AccountEventScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AccountEventScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AccountEventScalarWhereWithAggregatesInputSchema),z.lazy(() => AccountEventScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  feedId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  itemId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  contentId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const AppPhoneNumberWhereInputSchema: z.ZodType<Prisma.AppPhoneNumberWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AppPhoneNumberWhereInputSchema),z.lazy(() => AppPhoneNumberWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AppPhoneNumberWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AppPhoneNumberWhereInputSchema),z.lazy(() => AppPhoneNumberWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  phoneNumber: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  forwardingNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  feedId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const AppPhoneNumberOrderByWithRelationInputSchema: z.ZodType<Prisma.AppPhoneNumberOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  forwardingNumber: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AppPhoneNumberWhereUniqueInputSchema: z.ZodType<Prisma.AppPhoneNumberWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const AppPhoneNumberOrderByWithAggregationInputSchema: z.ZodType<Prisma.AppPhoneNumberOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  forwardingNumber: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => AppPhoneNumberCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AppPhoneNumberMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AppPhoneNumberMinOrderByAggregateInputSchema).optional()
}).strict();

export const AppPhoneNumberScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AppPhoneNumberScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AppPhoneNumberScalarWhereWithAggregatesInputSchema),z.lazy(() => AppPhoneNumberScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AppPhoneNumberScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AppPhoneNumberScalarWhereWithAggregatesInputSchema),z.lazy(() => AppPhoneNumberScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  phoneNumber: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  forwardingNumber: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  feedId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const AudioEncodingWhereInputSchema: z.ZodType<Prisma.AudioEncodingWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AudioEncodingWhereInputSchema),z.lazy(() => AudioEncodingWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AudioEncodingWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AudioEncodingWhereInputSchema),z.lazy(() => AudioEncodingWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  codec: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  mimeType: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  duration: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  url: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  transcriptionId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  transcriptionType: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  language: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  translatedFrom: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  priority: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  generatedVoice: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  generatedService: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const AudioEncodingOrderByWithRelationInputSchema: z.ZodType<Prisma.AudioEncodingOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  codec: z.lazy(() => SortOrderSchema).optional(),
  mimeType: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  transcriptionId: z.lazy(() => SortOrderSchema).optional(),
  transcriptionType: z.lazy(() => SortOrderSchema).optional(),
  language: z.lazy(() => SortOrderSchema).optional(),
  translatedFrom: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  generatedVoice: z.lazy(() => SortOrderSchema).optional(),
  generatedService: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AudioEncodingWhereUniqueInputSchema: z.ZodType<Prisma.AudioEncodingWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const AudioEncodingOrderByWithAggregationInputSchema: z.ZodType<Prisma.AudioEncodingOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  codec: z.lazy(() => SortOrderSchema).optional(),
  mimeType: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  transcriptionId: z.lazy(() => SortOrderSchema).optional(),
  transcriptionType: z.lazy(() => SortOrderSchema).optional(),
  language: z.lazy(() => SortOrderSchema).optional(),
  translatedFrom: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  generatedVoice: z.lazy(() => SortOrderSchema).optional(),
  generatedService: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => AudioEncodingCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => AudioEncodingAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AudioEncodingMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AudioEncodingMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => AudioEncodingSumOrderByAggregateInputSchema).optional()
}).strict();

export const AudioEncodingScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AudioEncodingScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AudioEncodingScalarWhereWithAggregatesInputSchema),z.lazy(() => AudioEncodingScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AudioEncodingScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AudioEncodingScalarWhereWithAggregatesInputSchema),z.lazy(() => AudioEncodingScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  codec: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  mimeType: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  duration: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  url: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  transcriptionId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  transcriptionType: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  language: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  translatedFrom: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  priority: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  generatedVoice: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  generatedService: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const AudioQueueItemWhereInputSchema: z.ZodType<Prisma.AudioQueueItemWhereInput> = z.object({
  AND: z.union([ z.lazy(() => AudioQueueItemWhereInputSchema),z.lazy(() => AudioQueueItemWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => AudioQueueItemWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AudioQueueItemWhereInputSchema),z.lazy(() => AudioQueueItemWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  itemId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const AudioQueueItemOrderByWithRelationInputSchema: z.ZodType<Prisma.AudioQueueItemOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  itemId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AudioQueueItemWhereUniqueInputSchema: z.ZodType<Prisma.AudioQueueItemWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const AudioQueueItemOrderByWithAggregationInputSchema: z.ZodType<Prisma.AudioQueueItemOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  itemId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => AudioQueueItemCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => AudioQueueItemMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => AudioQueueItemMinOrderByAggregateInputSchema).optional()
}).strict();

export const AudioQueueItemScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.AudioQueueItemScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => AudioQueueItemScalarWhereWithAggregatesInputSchema),z.lazy(() => AudioQueueItemScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => AudioQueueItemScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => AudioQueueItemScalarWhereWithAggregatesInputSchema),z.lazy(() => AudioQueueItemScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  itemId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const BroadcastWhereInputSchema: z.ZodType<Prisma.BroadcastWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BroadcastWhereInputSchema),z.lazy(() => BroadcastWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BroadcastWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BroadcastWhereInputSchema),z.lazy(() => BroadcastWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const BroadcastOrderByWithRelationInputSchema: z.ZodType<Prisma.BroadcastOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastWhereUniqueInputSchema: z.ZodType<Prisma.BroadcastWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const BroadcastOrderByWithAggregationInputSchema: z.ZodType<Prisma.BroadcastOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => BroadcastCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => BroadcastMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => BroadcastMinOrderByAggregateInputSchema).optional()
}).strict();

export const BroadcastScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.BroadcastScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => BroadcastScalarWhereWithAggregatesInputSchema),z.lazy(() => BroadcastScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => BroadcastScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BroadcastScalarWhereWithAggregatesInputSchema),z.lazy(() => BroadcastScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const BroadcastActionWhereInputSchema: z.ZodType<Prisma.BroadcastActionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BroadcastActionWhereInputSchema),z.lazy(() => BroadcastActionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BroadcastActionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BroadcastActionWhereInputSchema),z.lazy(() => BroadcastActionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workflowItemId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  broadcastId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const BroadcastActionOrderByWithRelationInputSchema: z.ZodType<Prisma.BroadcastActionOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastActionWhereUniqueInputSchema: z.ZodType<Prisma.BroadcastActionWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const BroadcastActionOrderByWithAggregationInputSchema: z.ZodType<Prisma.BroadcastActionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => BroadcastActionCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => BroadcastActionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => BroadcastActionMinOrderByAggregateInputSchema).optional()
}).strict();

export const BroadcastActionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.BroadcastActionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => BroadcastActionScalarWhereWithAggregatesInputSchema),z.lazy(() => BroadcastActionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => BroadcastActionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BroadcastActionScalarWhereWithAggregatesInputSchema),z.lazy(() => BroadcastActionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workflowItemId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  broadcastId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const BroadcastRecipientWhereInputSchema: z.ZodType<Prisma.BroadcastRecipientWhereInput> = z.object({
  AND: z.union([ z.lazy(() => BroadcastRecipientWhereInputSchema),z.lazy(() => BroadcastRecipientWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => BroadcastRecipientWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BroadcastRecipientWhereInputSchema),z.lazy(() => BroadcastRecipientWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  broadcastId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  feedId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const BroadcastRecipientOrderByWithRelationInputSchema: z.ZodType<Prisma.BroadcastRecipientOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastRecipientWhereUniqueInputSchema: z.ZodType<Prisma.BroadcastRecipientWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const BroadcastRecipientOrderByWithAggregationInputSchema: z.ZodType<Prisma.BroadcastRecipientOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => BroadcastRecipientCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => BroadcastRecipientMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => BroadcastRecipientMinOrderByAggregateInputSchema).optional()
}).strict();

export const BroadcastRecipientScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.BroadcastRecipientScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => BroadcastRecipientScalarWhereWithAggregatesInputSchema),z.lazy(() => BroadcastRecipientScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => BroadcastRecipientScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => BroadcastRecipientScalarWhereWithAggregatesInputSchema),z.lazy(() => BroadcastRecipientScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  broadcastId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  feedId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const CallRecordWhereInputSchema: z.ZodType<Prisma.CallRecordWhereInput> = z.object({
  AND: z.union([ z.lazy(() => CallRecordWhereInputSchema),z.lazy(() => CallRecordWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => CallRecordWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CallRecordWhereInputSchema),z.lazy(() => CallRecordWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  appPhoneNumberId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  toNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  fromNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  direction: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const CallRecordOrderByWithRelationInputSchema: z.ZodType<Prisma.CallRecordOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  appPhoneNumberId: z.lazy(() => SortOrderSchema).optional(),
  toNumber: z.lazy(() => SortOrderSchema).optional(),
  fromNumber: z.lazy(() => SortOrderSchema).optional(),
  direction: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CallRecordWhereUniqueInputSchema: z.ZodType<Prisma.CallRecordWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const CallRecordOrderByWithAggregationInputSchema: z.ZodType<Prisma.CallRecordOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  appPhoneNumberId: z.lazy(() => SortOrderSchema).optional(),
  toNumber: z.lazy(() => SortOrderSchema).optional(),
  fromNumber: z.lazy(() => SortOrderSchema).optional(),
  direction: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => CallRecordCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => CallRecordMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => CallRecordMinOrderByAggregateInputSchema).optional()
}).strict();

export const CallRecordScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.CallRecordScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => CallRecordScalarWhereWithAggregatesInputSchema),z.lazy(() => CallRecordScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => CallRecordScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => CallRecordScalarWhereWithAggregatesInputSchema),z.lazy(() => CallRecordScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  appPhoneNumberId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  toNumber: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  fromNumber: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  direction: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const DeviceRegistrationWhereInputSchema: z.ZodType<Prisma.DeviceRegistrationWhereInput> = z.object({
  AND: z.union([ z.lazy(() => DeviceRegistrationWhereInputSchema),z.lazy(() => DeviceRegistrationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => DeviceRegistrationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DeviceRegistrationWhereInputSchema),z.lazy(() => DeviceRegistrationWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  surface: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  deviceOsName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  deviceOsVersion: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  deviceModel: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  friendlyName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  drivingDetectionEnabled: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  wakeWordSensitivity: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  commandSensitivity: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  silenceDetectionInitialThreshold: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  silenceDetectionFinalThreshold: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  commandTimingWindowMs: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  recordingTimeoutWindowMs: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const DeviceRegistrationOrderByWithRelationInputSchema: z.ZodType<Prisma.DeviceRegistrationOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  surface: z.lazy(() => SortOrderSchema).optional(),
  deviceOsName: z.lazy(() => SortOrderSchema).optional(),
  deviceOsVersion: z.lazy(() => SortOrderSchema).optional(),
  deviceModel: z.lazy(() => SortOrderSchema).optional(),
  friendlyName: z.lazy(() => SortOrderSchema).optional(),
  drivingDetectionEnabled: z.lazy(() => SortOrderSchema).optional(),
  wakeWordSensitivity: z.lazy(() => SortOrderSchema).optional(),
  commandSensitivity: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionInitialThreshold: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionFinalThreshold: z.lazy(() => SortOrderSchema).optional(),
  commandTimingWindowMs: z.lazy(() => SortOrderSchema).optional(),
  recordingTimeoutWindowMs: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DeviceRegistrationWhereUniqueInputSchema: z.ZodType<Prisma.DeviceRegistrationWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const DeviceRegistrationOrderByWithAggregationInputSchema: z.ZodType<Prisma.DeviceRegistrationOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  surface: z.lazy(() => SortOrderSchema).optional(),
  deviceOsName: z.lazy(() => SortOrderSchema).optional(),
  deviceOsVersion: z.lazy(() => SortOrderSchema).optional(),
  deviceModel: z.lazy(() => SortOrderSchema).optional(),
  friendlyName: z.lazy(() => SortOrderSchema).optional(),
  drivingDetectionEnabled: z.lazy(() => SortOrderSchema).optional(),
  wakeWordSensitivity: z.lazy(() => SortOrderSchema).optional(),
  commandSensitivity: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionInitialThreshold: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionFinalThreshold: z.lazy(() => SortOrderSchema).optional(),
  commandTimingWindowMs: z.lazy(() => SortOrderSchema).optional(),
  recordingTimeoutWindowMs: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => DeviceRegistrationCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => DeviceRegistrationAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => DeviceRegistrationMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => DeviceRegistrationMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => DeviceRegistrationSumOrderByAggregateInputSchema).optional()
}).strict();

export const DeviceRegistrationScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.DeviceRegistrationScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => DeviceRegistrationScalarWhereWithAggregatesInputSchema),z.lazy(() => DeviceRegistrationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => DeviceRegistrationScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DeviceRegistrationScalarWhereWithAggregatesInputSchema),z.lazy(() => DeviceRegistrationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  surface: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  deviceOsName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  deviceOsVersion: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  deviceModel: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  friendlyName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  drivingDetectionEnabled: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  wakeWordSensitivity: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  commandSensitivity: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  silenceDetectionInitialThreshold: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  silenceDetectionFinalThreshold: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  commandTimingWindowMs: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  recordingTimeoutWindowMs: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const DirectWsInvitationWhereInputSchema: z.ZodType<Prisma.DirectWsInvitationWhereInput> = z.object({
  AND: z.union([ z.lazy(() => DirectWsInvitationWhereInputSchema),z.lazy(() => DirectWsInvitationWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => DirectWsInvitationWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DirectWsInvitationWhereInputSchema),z.lazy(() => DirectWsInvitationWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workspaceMembershipId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  phoneNumber: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  claimedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  claimedBy: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const DirectWsInvitationOrderByWithRelationInputSchema: z.ZodType<Prisma.DirectWsInvitationOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  claimedAt: z.lazy(() => SortOrderSchema).optional(),
  claimedBy: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DirectWsInvitationWhereUniqueInputSchema: z.ZodType<Prisma.DirectWsInvitationWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const DirectWsInvitationOrderByWithAggregationInputSchema: z.ZodType<Prisma.DirectWsInvitationOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  claimedAt: z.lazy(() => SortOrderSchema).optional(),
  claimedBy: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => DirectWsInvitationCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => DirectWsInvitationMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => DirectWsInvitationMinOrderByAggregateInputSchema).optional()
}).strict();

export const DirectWsInvitationScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.DirectWsInvitationScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => DirectWsInvitationScalarWhereWithAggregatesInputSchema),z.lazy(() => DirectWsInvitationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => DirectWsInvitationScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DirectWsInvitationScalarWhereWithAggregatesInputSchema),z.lazy(() => DirectWsInvitationScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workspaceMembershipId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  email: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  phoneNumber: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  name: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  claimedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  claimedBy: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const DisplayArtifactWhereInputSchema: z.ZodType<Prisma.DisplayArtifactWhereInput> = z.object({
  AND: z.union([ z.lazy(() => DisplayArtifactWhereInputSchema),z.lazy(() => DisplayArtifactWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => DisplayArtifactWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DisplayArtifactWhereInputSchema),z.lazy(() => DisplayArtifactWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  description: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  contentId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  deletedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict();

export const DisplayArtifactOrderByWithRelationInputSchema: z.ZodType<Prisma.DisplayArtifactOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DisplayArtifactWhereUniqueInputSchema: z.ZodType<Prisma.DisplayArtifactWhereUniqueInput> = z.object({
  id: z.string().optional(),
  contentId: z.string().optional()
}).strict();

export const DisplayArtifactOrderByWithAggregationInputSchema: z.ZodType<Prisma.DisplayArtifactOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => DisplayArtifactCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => DisplayArtifactMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => DisplayArtifactMinOrderByAggregateInputSchema).optional()
}).strict();

export const DisplayArtifactScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.DisplayArtifactScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => DisplayArtifactScalarWhereWithAggregatesInputSchema),z.lazy(() => DisplayArtifactScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => DisplayArtifactScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => DisplayArtifactScalarWhereWithAggregatesInputSchema),z.lazy(() => DisplayArtifactScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  description: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  contentId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  deletedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export const FeedWhereInputSchema: z.ZodType<Prisma.FeedWhereInput> = z.object({
  AND: z.union([ z.lazy(() => FeedWhereInputSchema),z.lazy(() => FeedWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => FeedWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => FeedWhereInputSchema),z.lazy(() => FeedWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workspaceId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  feedType: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  readOnly: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  isPrivate: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  isDm: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  isSilent: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  loadedFirstPage: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  loadedLastPage: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  loadedEvents: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  loadedPermissions: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  latestActivity: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  lastOpened: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const FeedOrderByWithRelationInputSchema: z.ZodType<Prisma.FeedOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedType: z.lazy(() => SortOrderSchema).optional(),
  readOnly: z.lazy(() => SortOrderSchema).optional(),
  isPrivate: z.lazy(() => SortOrderSchema).optional(),
  isDm: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  loadedFirstPage: z.lazy(() => SortOrderSchema).optional(),
  loadedLastPage: z.lazy(() => SortOrderSchema).optional(),
  loadedEvents: z.lazy(() => SortOrderSchema).optional(),
  loadedPermissions: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  latestActivity: z.lazy(() => SortOrderSchema).optional(),
  lastOpened: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedWhereUniqueInputSchema: z.ZodType<Prisma.FeedWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const FeedOrderByWithAggregationInputSchema: z.ZodType<Prisma.FeedOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedType: z.lazy(() => SortOrderSchema).optional(),
  readOnly: z.lazy(() => SortOrderSchema).optional(),
  isPrivate: z.lazy(() => SortOrderSchema).optional(),
  isDm: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  loadedFirstPage: z.lazy(() => SortOrderSchema).optional(),
  loadedLastPage: z.lazy(() => SortOrderSchema).optional(),
  loadedEvents: z.lazy(() => SortOrderSchema).optional(),
  loadedPermissions: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  latestActivity: z.lazy(() => SortOrderSchema).optional(),
  lastOpened: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => FeedCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => FeedAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => FeedMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => FeedMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => FeedSumOrderByAggregateInputSchema).optional()
}).strict();

export const FeedScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.FeedScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => FeedScalarWhereWithAggregatesInputSchema),z.lazy(() => FeedScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => FeedScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => FeedScalarWhereWithAggregatesInputSchema),z.lazy(() => FeedScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  title: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  workspaceId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  feedType: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  readOnly: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  isPrivate: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  isDm: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  isSilent: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  loadedFirstPage: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  loadedLastPage: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  loadedEvents: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  loadedPermissions: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  latestActivity: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  lastOpened: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const FeedGroupWhereInputSchema: z.ZodType<Prisma.FeedGroupWhereInput> = z.object({
  AND: z.union([ z.lazy(() => FeedGroupWhereInputSchema),z.lazy(() => FeedGroupWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => FeedGroupWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => FeedGroupWhereInputSchema),z.lazy(() => FeedGroupWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workspaceId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  enabled: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const FeedGroupOrderByWithRelationInputSchema: z.ZodType<Prisma.FeedGroupOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupWhereUniqueInputSchema: z.ZodType<Prisma.FeedGroupWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const FeedGroupOrderByWithAggregationInputSchema: z.ZodType<Prisma.FeedGroupOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => FeedGroupCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => FeedGroupAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => FeedGroupMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => FeedGroupMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => FeedGroupSumOrderByAggregateInputSchema).optional()
}).strict();

export const FeedGroupScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.FeedGroupScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => FeedGroupScalarWhereWithAggregatesInputSchema),z.lazy(() => FeedGroupScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => FeedGroupScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => FeedGroupScalarWhereWithAggregatesInputSchema),z.lazy(() => FeedGroupScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  workspaceId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  enabled: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const FeedGroupMembershipWhereInputSchema: z.ZodType<Prisma.FeedGroupMembershipWhereInput> = z.object({
  AND: z.union([ z.lazy(() => FeedGroupMembershipWhereInputSchema),z.lazy(() => FeedGroupMembershipWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => FeedGroupMembershipWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => FeedGroupMembershipWhereInputSchema),z.lazy(() => FeedGroupMembershipWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  feedId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  groupId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  enabled: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const FeedGroupMembershipOrderByWithRelationInputSchema: z.ZodType<Prisma.FeedGroupMembershipOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  groupId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupMembershipWhereUniqueInputSchema: z.ZodType<Prisma.FeedGroupMembershipWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const FeedGroupMembershipOrderByWithAggregationInputSchema: z.ZodType<Prisma.FeedGroupMembershipOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  groupId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => FeedGroupMembershipCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => FeedGroupMembershipAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => FeedGroupMembershipMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => FeedGroupMembershipMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => FeedGroupMembershipSumOrderByAggregateInputSchema).optional()
}).strict();

export const FeedGroupMembershipScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.FeedGroupMembershipScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => FeedGroupMembershipScalarWhereWithAggregatesInputSchema),z.lazy(() => FeedGroupMembershipScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => FeedGroupMembershipScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => FeedGroupMembershipScalarWhereWithAggregatesInputSchema),z.lazy(() => FeedGroupMembershipScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  feedId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  groupId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  enabled: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const FileWhereInputSchema: z.ZodType<Prisma.FileWhereInput> = z.object({
  AND: z.union([ z.lazy(() => FileWhereInputSchema),z.lazy(() => FileWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => FileWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => FileWhereInputSchema),z.lazy(() => FileWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  url: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  mimeType: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  name: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const FileOrderByWithRelationInputSchema: z.ZodType<Prisma.FileOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  mimeType: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FileWhereUniqueInputSchema: z.ZodType<Prisma.FileWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const FileOrderByWithAggregationInputSchema: z.ZodType<Prisma.FileOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  mimeType: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => FileCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => FileMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => FileMinOrderByAggregateInputSchema).optional()
}).strict();

export const FileScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.FileScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => FileScalarWhereWithAggregatesInputSchema),z.lazy(() => FileScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => FileScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => FileScalarWhereWithAggregatesInputSchema),z.lazy(() => FileScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  url: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  mimeType: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  name: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const ItemWhereInputSchema: z.ZodType<Prisma.ItemWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ItemWhereInputSchema),z.lazy(() => ItemWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ItemWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ItemWhereInputSchema),z.lazy(() => ItemWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  feedId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  groupId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  deletedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  accountId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  loadedContent: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  isSilent: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  unread: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  isDriverMessage: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  isOrganizerMessage: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  isAdminMessage: z.union([ z.lazy(() => IntNullableFilterSchema),z.number() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const ItemOrderByWithRelationInputSchema: z.ZodType<Prisma.ItemOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  groupId: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  loadedContent: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  unread: z.lazy(() => SortOrderSchema).optional(),
  isDriverMessage: z.lazy(() => SortOrderSchema).optional(),
  isOrganizerMessage: z.lazy(() => SortOrderSchema).optional(),
  isAdminMessage: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ItemWhereUniqueInputSchema: z.ZodType<Prisma.ItemWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const ItemOrderByWithAggregationInputSchema: z.ZodType<Prisma.ItemOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  groupId: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  loadedContent: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  unread: z.lazy(() => SortOrderSchema).optional(),
  isDriverMessage: z.lazy(() => SortOrderSchema).optional(),
  isOrganizerMessage: z.lazy(() => SortOrderSchema).optional(),
  isAdminMessage: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => ItemCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => ItemAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => ItemMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => ItemMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => ItemSumOrderByAggregateInputSchema).optional()
}).strict();

export const ItemScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.ItemScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => ItemScalarWhereWithAggregatesInputSchema),z.lazy(() => ItemScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => ItemScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ItemScalarWhereWithAggregatesInputSchema),z.lazy(() => ItemScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  feedId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  groupId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  deletedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  accountId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  loadedContent: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  isSilent: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  unread: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  isDriverMessage: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  isOrganizerMessage: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  isAdminMessage: z.union([ z.lazy(() => IntNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  status: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const LinkWhereInputSchema: z.ZodType<Prisma.LinkWhereInput> = z.object({
  AND: z.union([ z.lazy(() => LinkWhereInputSchema),z.lazy(() => LinkWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => LinkWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => LinkWhereInputSchema),z.lazy(() => LinkWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  url: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  description: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  title: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  image: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  linkOrder: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const LinkOrderByWithRelationInputSchema: z.ZodType<Prisma.LinkOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  linkOrder: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const LinkWhereUniqueInputSchema: z.ZodType<Prisma.LinkWhereUniqueInput> = z.object({
  id: z.string().optional(),
  contentId_url: z.lazy(() => LinkContentIdUrlCompoundUniqueInputSchema).optional()
}).strict();

export const LinkOrderByWithAggregationInputSchema: z.ZodType<Prisma.LinkOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  linkOrder: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => LinkCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => LinkAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => LinkMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => LinkMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => LinkSumOrderByAggregateInputSchema).optional()
}).strict();

export const LinkScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.LinkScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => LinkScalarWhereWithAggregatesInputSchema),z.lazy(() => LinkScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => LinkScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => LinkScalarWhereWithAggregatesInputSchema),z.lazy(() => LinkScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  url: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  description: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  title: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  image: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  linkOrder: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const PermissionWhereInputSchema: z.ZodType<Prisma.PermissionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PermissionWhereInputSchema),z.lazy(() => PermissionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PermissionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PermissionWhereInputSchema),z.lazy(() => PermissionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workspace_membershipId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  feedId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workflowItemId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  enabled: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  expiresAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const PermissionOrderByWithRelationInputSchema: z.ZodType<Prisma.PermissionOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspace_membershipId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  expiresAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PermissionWhereUniqueInputSchema: z.ZodType<Prisma.PermissionWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const PermissionOrderByWithAggregationInputSchema: z.ZodType<Prisma.PermissionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspace_membershipId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  expiresAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => PermissionCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => PermissionAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PermissionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PermissionMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => PermissionSumOrderByAggregateInputSchema).optional()
}).strict();

export const PermissionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PermissionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PermissionScalarWhereWithAggregatesInputSchema),z.lazy(() => PermissionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PermissionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PermissionScalarWhereWithAggregatesInputSchema),z.lazy(() => PermissionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  workspace_membershipId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  feedId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  workflowItemId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  enabled: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  expiresAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const PipelineArtifactMetadataWhereInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PipelineArtifactMetadataWhereInputSchema),z.lazy(() => PipelineArtifactMetadataWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PipelineArtifactMetadataWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PipelineArtifactMetadataWhereInputSchema),z.lazy(() => PipelineArtifactMetadataWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  vadResult: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const PipelineArtifactMetadataOrderByWithRelationInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  vadResult: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PipelineArtifactMetadataWhereUniqueInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const PipelineArtifactMetadataOrderByWithAggregationInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  vadResult: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => PipelineArtifactMetadataCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PipelineArtifactMetadataMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PipelineArtifactMetadataMinOrderByAggregateInputSchema).optional()
}).strict();

export const PipelineArtifactMetadataScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PipelineArtifactMetadataScalarWhereWithAggregatesInputSchema),z.lazy(() => PipelineArtifactMetadataScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PipelineArtifactMetadataScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PipelineArtifactMetadataScalarWhereWithAggregatesInputSchema),z.lazy(() => PipelineArtifactMetadataScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  vadResult: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const PublishedWorkflowItemWhereInputSchema: z.ZodType<Prisma.PublishedWorkflowItemWhereInput> = z.object({
  AND: z.union([ z.lazy(() => PublishedWorkflowItemWhereInputSchema),z.lazy(() => PublishedWorkflowItemWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => PublishedWorkflowItemWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PublishedWorkflowItemWhereInputSchema),z.lazy(() => PublishedWorkflowItemWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workflowItemId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  broadcastId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const PublishedWorkflowItemOrderByWithRelationInputSchema: z.ZodType<Prisma.PublishedWorkflowItemOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PublishedWorkflowItemWhereUniqueInputSchema: z.ZodType<Prisma.PublishedWorkflowItemWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const PublishedWorkflowItemOrderByWithAggregationInputSchema: z.ZodType<Prisma.PublishedWorkflowItemOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => PublishedWorkflowItemCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => PublishedWorkflowItemMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => PublishedWorkflowItemMinOrderByAggregateInputSchema).optional()
}).strict();

export const PublishedWorkflowItemScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.PublishedWorkflowItemScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => PublishedWorkflowItemScalarWhereWithAggregatesInputSchema),z.lazy(() => PublishedWorkflowItemScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => PublishedWorkflowItemScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => PublishedWorkflowItemScalarWhereWithAggregatesInputSchema),z.lazy(() => PublishedWorkflowItemScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workflowItemId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  broadcastId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const ScheduleTriggerWhereInputSchema: z.ZodType<Prisma.ScheduleTriggerWhereInput> = z.object({
  AND: z.union([ z.lazy(() => ScheduleTriggerWhereInputSchema),z.lazy(() => ScheduleTriggerWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => ScheduleTriggerWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ScheduleTriggerWhereInputSchema),z.lazy(() => ScheduleTriggerWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  oneTimeSchedule: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  cronSchedule: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workspaceId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  broadcastActionId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  timezone: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  enabled: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
}).strict();

export const ScheduleTriggerOrderByWithRelationInputSchema: z.ZodType<Prisma.ScheduleTriggerOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  oneTimeSchedule: z.lazy(() => SortOrderSchema).optional(),
  cronSchedule: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  broadcastActionId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  timezone: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ScheduleTriggerWhereUniqueInputSchema: z.ZodType<Prisma.ScheduleTriggerWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const ScheduleTriggerOrderByWithAggregationInputSchema: z.ZodType<Prisma.ScheduleTriggerOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  oneTimeSchedule: z.lazy(() => SortOrderSchema).optional(),
  cronSchedule: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  broadcastActionId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  timezone: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => ScheduleTriggerCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => ScheduleTriggerAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => ScheduleTriggerMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => ScheduleTriggerMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => ScheduleTriggerSumOrderByAggregateInputSchema).optional()
}).strict();

export const ScheduleTriggerScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.ScheduleTriggerScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => ScheduleTriggerScalarWhereWithAggregatesInputSchema),z.lazy(() => ScheduleTriggerScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => ScheduleTriggerScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => ScheduleTriggerScalarWhereWithAggregatesInputSchema),z.lazy(() => ScheduleTriggerScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  oneTimeSchedule: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  cronSchedule: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  workspaceId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  broadcastActionId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  timezone: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  enabled: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
}).strict();

export const TemplateWhereInputSchema: z.ZodType<Prisma.TemplateWhereInput> = z.object({
  AND: z.union([ z.lazy(() => TemplateWhereInputSchema),z.lazy(() => TemplateWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => TemplateWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TemplateWhereInputSchema),z.lazy(() => TemplateWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  template: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  authorId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  deletedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const TemplateOrderByWithRelationInputSchema: z.ZodType<Prisma.TemplateOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  template: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TemplateWhereUniqueInputSchema: z.ZodType<Prisma.TemplateWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const TemplateOrderByWithAggregationInputSchema: z.ZodType<Prisma.TemplateOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  template: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => TemplateCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => TemplateMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => TemplateMinOrderByAggregateInputSchema).optional()
}).strict();

export const TemplateScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.TemplateScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => TemplateScalarWhereWithAggregatesInputSchema),z.lazy(() => TemplateScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => TemplateScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TemplateScalarWhereWithAggregatesInputSchema),z.lazy(() => TemplateScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  template: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  authorId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  updatedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  deletedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const TranscriptionWhereInputSchema: z.ZodType<Prisma.TranscriptionWhereInput> = z.object({
  AND: z.union([ z.lazy(() => TranscriptionWhereInputSchema),z.lazy(() => TranscriptionWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => TranscriptionWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TranscriptionWhereInputSchema),z.lazy(() => TranscriptionWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  transcriptionType: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  transcriptionContent: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  language: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  translatedFrom: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  backendModel: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  priority: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  confidence: z.union([ z.lazy(() => FloatNullableFilterSchema),z.number() ]).optional().nullable(),
  executionTime: z.union([ z.lazy(() => FloatFilterSchema),z.number() ]).optional(),
  format: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  pipeline: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  bucket: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  key: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  url: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const TranscriptionOrderByWithRelationInputSchema: z.ZodType<Prisma.TranscriptionOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  transcriptionType: z.lazy(() => SortOrderSchema).optional(),
  transcriptionContent: z.lazy(() => SortOrderSchema).optional(),
  language: z.lazy(() => SortOrderSchema).optional(),
  translatedFrom: z.lazy(() => SortOrderSchema).optional(),
  backendModel: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  confidence: z.lazy(() => SortOrderSchema).optional(),
  executionTime: z.lazy(() => SortOrderSchema).optional(),
  format: z.lazy(() => SortOrderSchema).optional(),
  pipeline: z.lazy(() => SortOrderSchema).optional(),
  bucket: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TranscriptionWhereUniqueInputSchema: z.ZodType<Prisma.TranscriptionWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const TranscriptionOrderByWithAggregationInputSchema: z.ZodType<Prisma.TranscriptionOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  transcriptionType: z.lazy(() => SortOrderSchema).optional(),
  transcriptionContent: z.lazy(() => SortOrderSchema).optional(),
  language: z.lazy(() => SortOrderSchema).optional(),
  translatedFrom: z.lazy(() => SortOrderSchema).optional(),
  backendModel: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  confidence: z.lazy(() => SortOrderSchema).optional(),
  executionTime: z.lazy(() => SortOrderSchema).optional(),
  format: z.lazy(() => SortOrderSchema).optional(),
  pipeline: z.lazy(() => SortOrderSchema).optional(),
  bucket: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => TranscriptionCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => TranscriptionAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => TranscriptionMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => TranscriptionMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => TranscriptionSumOrderByAggregateInputSchema).optional()
}).strict();

export const TranscriptionScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.TranscriptionScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => TranscriptionScalarWhereWithAggregatesInputSchema),z.lazy(() => TranscriptionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => TranscriptionScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => TranscriptionScalarWhereWithAggregatesInputSchema),z.lazy(() => TranscriptionScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  transcriptionType: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  transcriptionContent: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  language: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  translatedFrom: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  backendModel: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  priority: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  confidence: z.union([ z.lazy(() => FloatNullableWithAggregatesFilterSchema),z.number() ]).optional().nullable(),
  executionTime: z.union([ z.lazy(() => FloatWithAggregatesFilterSchema),z.number() ]).optional(),
  format: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  pipeline: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  bucket: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  key: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  url: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const WorkflowItemWhereInputSchema: z.ZodType<Prisma.WorkflowItemWhereInput> = z.object({
  AND: z.union([ z.lazy(() => WorkflowItemWhereInputSchema),z.lazy(() => WorkflowItemWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorkflowItemWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorkflowItemWhereInputSchema),z.lazy(() => WorkflowItemWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  deletedAt: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  displayName: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const WorkflowItemOrderByWithRelationInputSchema: z.ZodType<Prisma.WorkflowItemOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  displayName: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkflowItemWhereUniqueInputSchema: z.ZodType<Prisma.WorkflowItemWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const WorkflowItemOrderByWithAggregationInputSchema: z.ZodType<Prisma.WorkflowItemOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  displayName: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => WorkflowItemCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => WorkflowItemMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => WorkflowItemMinOrderByAggregateInputSchema).optional()
}).strict();

export const WorkflowItemScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.WorkflowItemScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => WorkflowItemScalarWhereWithAggregatesInputSchema),z.lazy(() => WorkflowItemScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorkflowItemScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorkflowItemScalarWhereWithAggregatesInputSchema),z.lazy(() => WorkflowItemScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  contentId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  deletedAt: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  displayName: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
}).strict();

export const WorkspaceWhereInputSchema: z.ZodType<Prisma.WorkspaceWhereInput> = z.object({
  AND: z.union([ z.lazy(() => WorkspaceWhereInputSchema),z.lazy(() => WorkspaceWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorkspaceWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorkspaceWhereInputSchema),z.lazy(() => WorkspaceWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict();

export const WorkspaceOrderByWithRelationInputSchema: z.ZodType<Prisma.WorkspaceOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceWhereUniqueInputSchema: z.ZodType<Prisma.WorkspaceWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const WorkspaceOrderByWithAggregationInputSchema: z.ZodType<Prisma.WorkspaceOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => WorkspaceCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => WorkspaceMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => WorkspaceMinOrderByAggregateInputSchema).optional()
}).strict();

export const WorkspaceScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.WorkspaceScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => WorkspaceScalarWhereWithAggregatesInputSchema),z.lazy(() => WorkspaceScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorkspaceScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorkspaceScalarWhereWithAggregatesInputSchema),z.lazy(() => WorkspaceScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  name: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export const WorkspaceCommandAliasWhereInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasWhereInput> = z.object({
  AND: z.union([ z.lazy(() => WorkspaceCommandAliasWhereInputSchema),z.lazy(() => WorkspaceCommandAliasWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorkspaceCommandAliasWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorkspaceCommandAliasWhereInputSchema),z.lazy(() => WorkspaceCommandAliasWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  alias: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  feedId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict();

export const WorkspaceCommandAliasOrderByWithRelationInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  alias: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceCommandAliasWhereUniqueInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const WorkspaceCommandAliasOrderByWithAggregationInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  alias: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => WorkspaceCommandAliasCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => WorkspaceCommandAliasMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => WorkspaceCommandAliasMinOrderByAggregateInputSchema).optional()
}).strict();

export const WorkspaceCommandAliasScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => WorkspaceCommandAliasScalarWhereWithAggregatesInputSchema),z.lazy(() => WorkspaceCommandAliasScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorkspaceCommandAliasScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorkspaceCommandAliasScalarWhereWithAggregatesInputSchema),z.lazy(() => WorkspaceCommandAliasScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  alias: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  feedId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  createdAt: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export const WorkspaceMembershipWhereInputSchema: z.ZodType<Prisma.WorkspaceMembershipWhereInput> = z.object({
  AND: z.union([ z.lazy(() => WorkspaceMembershipWhereInputSchema),z.lazy(() => WorkspaceMembershipWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorkspaceMembershipWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorkspaceMembershipWhereInputSchema),z.lazy(() => WorkspaceMembershipWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringNullableFilterSchema),z.string() ]).optional().nullable(),
  status: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  role: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict();

export const WorkspaceMembershipOrderByWithRelationInputSchema: z.ZodType<Prisma.WorkspaceMembershipOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceMembershipWhereUniqueInputSchema: z.ZodType<Prisma.WorkspaceMembershipWhereUniqueInput> = z.object({
  id: z.string().optional(),
  workspaceId_accountId: z.lazy(() => WorkspaceMembershipWorkspaceIdAccountIdCompoundUniqueInputSchema).optional()
}).strict();

export const WorkspaceMembershipOrderByWithAggregationInputSchema: z.ZodType<Prisma.WorkspaceMembershipOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => WorkspaceMembershipCountOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => WorkspaceMembershipMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => WorkspaceMembershipMinOrderByAggregateInputSchema).optional()
}).strict();

export const WorkspaceMembershipScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.WorkspaceMembershipScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => WorkspaceMembershipScalarWhereWithAggregatesInputSchema),z.lazy(() => WorkspaceMembershipScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => WorkspaceMembershipScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WorkspaceMembershipScalarWhereWithAggregatesInputSchema),z.lazy(() => WorkspaceMembershipScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  workspaceId: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  accountId: z.union([ z.lazy(() => StringNullableWithAggregatesFilterSchema),z.string() ]).optional().nullable(),
  status: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  role: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export const WsHandsFreeStatusWhereInputSchema: z.ZodType<Prisma.WsHandsFreeStatusWhereInput> = z.object({
  AND: z.union([ z.lazy(() => WsHandsFreeStatusWhereInputSchema),z.lazy(() => WsHandsFreeStatusWhereInputSchema).array() ]).optional(),
  OR: z.lazy(() => WsHandsFreeStatusWhereInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WsHandsFreeStatusWhereInputSchema),z.lazy(() => WsHandsFreeStatusWhereInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
  enabled: z.union([ z.lazy(() => IntFilterSchema),z.number() ]).optional(),
  timestamp: z.union([ z.lazy(() => StringFilterSchema),z.string() ]).optional(),
}).strict();

export const WsHandsFreeStatusOrderByWithRelationInputSchema: z.ZodType<Prisma.WsHandsFreeStatusOrderByWithRelationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WsHandsFreeStatusWhereUniqueInputSchema: z.ZodType<Prisma.WsHandsFreeStatusWhereUniqueInput> = z.object({
  id: z.string().optional()
}).strict();

export const WsHandsFreeStatusOrderByWithAggregationInputSchema: z.ZodType<Prisma.WsHandsFreeStatusOrderByWithAggregationInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional(),
  _count: z.lazy(() => WsHandsFreeStatusCountOrderByAggregateInputSchema).optional(),
  _avg: z.lazy(() => WsHandsFreeStatusAvgOrderByAggregateInputSchema).optional(),
  _max: z.lazy(() => WsHandsFreeStatusMaxOrderByAggregateInputSchema).optional(),
  _min: z.lazy(() => WsHandsFreeStatusMinOrderByAggregateInputSchema).optional(),
  _sum: z.lazy(() => WsHandsFreeStatusSumOrderByAggregateInputSchema).optional()
}).strict();

export const WsHandsFreeStatusScalarWhereWithAggregatesInputSchema: z.ZodType<Prisma.WsHandsFreeStatusScalarWhereWithAggregatesInput> = z.object({
  AND: z.union([ z.lazy(() => WsHandsFreeStatusScalarWhereWithAggregatesInputSchema),z.lazy(() => WsHandsFreeStatusScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  OR: z.lazy(() => WsHandsFreeStatusScalarWhereWithAggregatesInputSchema).array().optional(),
  NOT: z.union([ z.lazy(() => WsHandsFreeStatusScalarWhereWithAggregatesInputSchema),z.lazy(() => WsHandsFreeStatusScalarWhereWithAggregatesInputSchema).array() ]).optional(),
  id: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
  enabled: z.union([ z.lazy(() => IntWithAggregatesFilterSchema),z.number() ]).optional(),
  timestamp: z.union([ z.lazy(() => StringWithAggregatesFilterSchema),z.string() ]).optional(),
}).strict();

export const AccountCreateInputSchema: z.ZodType<Prisma.AccountCreateInput> = z.object({
  id: z.string(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  emailVerified: z.number().int().optional(),
  phoneNumber: z.string().optional().nullable(),
  phoneNumberVerified: z.number().int().optional(),
  name: z.string().optional().nullable(),
  avatarColor: z.string().optional().nullable(),
  accountType: z.string().optional().nullable(),
  mine: z.number().int().optional(),
  preferredLanguage: z.string().optional().nullable()
}).strict();

export const AccountUncheckedCreateInputSchema: z.ZodType<Prisma.AccountUncheckedCreateInput> = z.object({
  id: z.string(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  emailVerified: z.number().int().optional(),
  phoneNumber: z.string().optional().nullable(),
  phoneNumberVerified: z.number().int().optional(),
  name: z.string().optional().nullable(),
  avatarColor: z.string().optional().nullable(),
  accountType: z.string().optional().nullable(),
  mine: z.number().int().optional(),
  preferredLanguage: z.string().optional().nullable()
}).strict();

export const AccountUpdateInputSchema: z.ZodType<Prisma.AccountUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  phoneNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phoneNumberVerified: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  avatarColor: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  mine: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  preferredLanguage: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountUncheckedUpdateInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  phoneNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phoneNumberVerified: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  avatarColor: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  mine: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  preferredLanguage: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountCreateManyInputSchema: z.ZodType<Prisma.AccountCreateManyInput> = z.object({
  id: z.string(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  emailVerified: z.number().int().optional(),
  phoneNumber: z.string().optional().nullable(),
  phoneNumberVerified: z.number().int().optional(),
  name: z.string().optional().nullable(),
  avatarColor: z.string().optional().nullable(),
  accountType: z.string().optional().nullable(),
  mine: z.number().int().optional(),
  preferredLanguage: z.string().optional().nullable()
}).strict();

export const AccountUpdateManyMutationInputSchema: z.ZodType<Prisma.AccountUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  phoneNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phoneNumberVerified: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  avatarColor: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  mine: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  preferredLanguage: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AccountUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  firstName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  emailVerified: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  phoneNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phoneNumberVerified: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  avatarColor: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  mine: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  preferredLanguage: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountEventCreateInputSchema: z.ZodType<Prisma.AccountEventCreateInput> = z.object({
  id: z.string(),
  accountId: z.string(),
  name: z.string(),
  createdAt: z.string(),
  feedId: z.string().optional().nullable(),
  itemId: z.string().optional().nullable(),
  contentId: z.string().optional().nullable()
}).strict();

export const AccountEventUncheckedCreateInputSchema: z.ZodType<Prisma.AccountEventUncheckedCreateInput> = z.object({
  id: z.string(),
  accountId: z.string(),
  name: z.string(),
  createdAt: z.string(),
  feedId: z.string().optional().nullable(),
  itemId: z.string().optional().nullable(),
  contentId: z.string().optional().nullable()
}).strict();

export const AccountEventUpdateInputSchema: z.ZodType<Prisma.AccountEventUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  itemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountEventUncheckedUpdateInputSchema: z.ZodType<Prisma.AccountEventUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  itemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountEventCreateManyInputSchema: z.ZodType<Prisma.AccountEventCreateManyInput> = z.object({
  id: z.string(),
  accountId: z.string(),
  name: z.string(),
  createdAt: z.string(),
  feedId: z.string().optional().nullable(),
  itemId: z.string().optional().nullable(),
  contentId: z.string().optional().nullable()
}).strict();

export const AccountEventUpdateManyMutationInputSchema: z.ZodType<Prisma.AccountEventUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  itemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AccountEventUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AccountEventUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  itemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contentId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AppPhoneNumberCreateInputSchema: z.ZodType<Prisma.AppPhoneNumberCreateInput> = z.object({
  id: z.string(),
  phoneNumber: z.string(),
  forwardingNumber: z.string().optional().nullable(),
  feedId: z.string().optional().nullable()
}).strict();

export const AppPhoneNumberUncheckedCreateInputSchema: z.ZodType<Prisma.AppPhoneNumberUncheckedCreateInput> = z.object({
  id: z.string(),
  phoneNumber: z.string(),
  forwardingNumber: z.string().optional().nullable(),
  feedId: z.string().optional().nullable()
}).strict();

export const AppPhoneNumberUpdateInputSchema: z.ZodType<Prisma.AppPhoneNumberUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phoneNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  forwardingNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AppPhoneNumberUncheckedUpdateInputSchema: z.ZodType<Prisma.AppPhoneNumberUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phoneNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  forwardingNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AppPhoneNumberCreateManyInputSchema: z.ZodType<Prisma.AppPhoneNumberCreateManyInput> = z.object({
  id: z.string(),
  phoneNumber: z.string(),
  forwardingNumber: z.string().optional().nullable(),
  feedId: z.string().optional().nullable()
}).strict();

export const AppPhoneNumberUpdateManyMutationInputSchema: z.ZodType<Prisma.AppPhoneNumberUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phoneNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  forwardingNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AppPhoneNumberUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AppPhoneNumberUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  phoneNumber: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  forwardingNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AudioEncodingCreateInputSchema: z.ZodType<Prisma.AudioEncodingCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  codec: z.string(),
  mimeType: z.string().optional().nullable(),
  duration: z.number(),
  url: z.string(),
  transcriptionId: z.string().optional().nullable(),
  transcriptionType: z.string().optional().nullable(),
  language: z.string().optional().nullable(),
  translatedFrom: z.string().optional().nullable(),
  priority: z.number().int().optional(),
  generatedVoice: z.string().optional().nullable(),
  generatedService: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const AudioEncodingUncheckedCreateInputSchema: z.ZodType<Prisma.AudioEncodingUncheckedCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  codec: z.string(),
  mimeType: z.string().optional().nullable(),
  duration: z.number(),
  url: z.string(),
  transcriptionId: z.string().optional().nullable(),
  transcriptionType: z.string().optional().nullable(),
  language: z.string().optional().nullable(),
  translatedFrom: z.string().optional().nullable(),
  priority: z.number().int().optional(),
  generatedVoice: z.string().optional().nullable(),
  generatedService: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const AudioEncodingUpdateInputSchema: z.ZodType<Prisma.AudioEncodingUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  codec: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mimeType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  transcriptionType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  language: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  translatedFrom: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  priority: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  generatedVoice: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  generatedService: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AudioEncodingUncheckedUpdateInputSchema: z.ZodType<Prisma.AudioEncodingUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  codec: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mimeType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  transcriptionType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  language: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  translatedFrom: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  priority: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  generatedVoice: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  generatedService: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AudioEncodingCreateManyInputSchema: z.ZodType<Prisma.AudioEncodingCreateManyInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  codec: z.string(),
  mimeType: z.string().optional().nullable(),
  duration: z.number(),
  url: z.string(),
  transcriptionId: z.string().optional().nullable(),
  transcriptionType: z.string().optional().nullable(),
  language: z.string().optional().nullable(),
  translatedFrom: z.string().optional().nullable(),
  priority: z.number().int().optional(),
  generatedVoice: z.string().optional().nullable(),
  generatedService: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const AudioEncodingUpdateManyMutationInputSchema: z.ZodType<Prisma.AudioEncodingUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  codec: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mimeType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  transcriptionType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  language: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  translatedFrom: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  priority: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  generatedVoice: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  generatedService: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AudioEncodingUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AudioEncodingUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  codec: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mimeType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  duration: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  transcriptionType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  language: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  translatedFrom: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  priority: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  generatedVoice: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  generatedService: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AudioQueueItemCreateInputSchema: z.ZodType<Prisma.AudioQueueItemCreateInput> = z.object({
  id: z.string(),
  itemId: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const AudioQueueItemUncheckedCreateInputSchema: z.ZodType<Prisma.AudioQueueItemUncheckedCreateInput> = z.object({
  id: z.string(),
  itemId: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const AudioQueueItemUpdateInputSchema: z.ZodType<Prisma.AudioQueueItemUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  itemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AudioQueueItemUncheckedUpdateInputSchema: z.ZodType<Prisma.AudioQueueItemUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  itemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AudioQueueItemCreateManyInputSchema: z.ZodType<Prisma.AudioQueueItemCreateManyInput> = z.object({
  id: z.string(),
  itemId: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const AudioQueueItemUpdateManyMutationInputSchema: z.ZodType<Prisma.AudioQueueItemUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  itemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const AudioQueueItemUncheckedUpdateManyInputSchema: z.ZodType<Prisma.AudioQueueItemUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  itemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastCreateInputSchema: z.ZodType<Prisma.BroadcastCreateInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  createdAt: z.string().optional().nullable()
}).strict();

export const BroadcastUncheckedCreateInputSchema: z.ZodType<Prisma.BroadcastUncheckedCreateInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  createdAt: z.string().optional().nullable()
}).strict();

export const BroadcastUpdateInputSchema: z.ZodType<Prisma.BroadcastUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastUncheckedUpdateInputSchema: z.ZodType<Prisma.BroadcastUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastCreateManyInputSchema: z.ZodType<Prisma.BroadcastCreateManyInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  createdAt: z.string().optional().nullable()
}).strict();

export const BroadcastUpdateManyMutationInputSchema: z.ZodType<Prisma.BroadcastUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastUncheckedUpdateManyInputSchema: z.ZodType<Prisma.BroadcastUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastActionCreateInputSchema: z.ZodType<Prisma.BroadcastActionCreateInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  workflowItemId: z.string(),
  broadcastId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const BroadcastActionUncheckedCreateInputSchema: z.ZodType<Prisma.BroadcastActionUncheckedCreateInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  workflowItemId: z.string(),
  broadcastId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const BroadcastActionUpdateInputSchema: z.ZodType<Prisma.BroadcastActionUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workflowItemId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastActionUncheckedUpdateInputSchema: z.ZodType<Prisma.BroadcastActionUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workflowItemId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastActionCreateManyInputSchema: z.ZodType<Prisma.BroadcastActionCreateManyInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  workflowItemId: z.string(),
  broadcastId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const BroadcastActionUpdateManyMutationInputSchema: z.ZodType<Prisma.BroadcastActionUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workflowItemId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastActionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.BroadcastActionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workflowItemId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastRecipientCreateInputSchema: z.ZodType<Prisma.BroadcastRecipientCreateInput> = z.object({
  id: z.string(),
  broadcastId: z.string(),
  workspaceId: z.string(),
  feedId: z.string().optional().nullable(),
  workspaceMembershipId: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const BroadcastRecipientUncheckedCreateInputSchema: z.ZodType<Prisma.BroadcastRecipientUncheckedCreateInput> = z.object({
  id: z.string(),
  broadcastId: z.string(),
  workspaceId: z.string(),
  feedId: z.string().optional().nullable(),
  workspaceMembershipId: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const BroadcastRecipientUpdateInputSchema: z.ZodType<Prisma.BroadcastRecipientUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastRecipientUncheckedUpdateInputSchema: z.ZodType<Prisma.BroadcastRecipientUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastRecipientCreateManyInputSchema: z.ZodType<Prisma.BroadcastRecipientCreateManyInput> = z.object({
  id: z.string(),
  broadcastId: z.string(),
  workspaceId: z.string(),
  feedId: z.string().optional().nullable(),
  workspaceMembershipId: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const BroadcastRecipientUpdateManyMutationInputSchema: z.ZodType<Prisma.BroadcastRecipientUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const BroadcastRecipientUncheckedUpdateManyInputSchema: z.ZodType<Prisma.BroadcastRecipientUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const CallRecordCreateInputSchema: z.ZodType<Prisma.CallRecordCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  appPhoneNumberId: z.string(),
  toNumber: z.string().optional().nullable(),
  fromNumber: z.string().optional().nullable(),
  direction: z.string().optional().nullable()
}).strict();

export const CallRecordUncheckedCreateInputSchema: z.ZodType<Prisma.CallRecordUncheckedCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  appPhoneNumberId: z.string(),
  toNumber: z.string().optional().nullable(),
  fromNumber: z.string().optional().nullable(),
  direction: z.string().optional().nullable()
}).strict();

export const CallRecordUpdateInputSchema: z.ZodType<Prisma.CallRecordUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  appPhoneNumberId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  toNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  fromNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  direction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const CallRecordUncheckedUpdateInputSchema: z.ZodType<Prisma.CallRecordUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  appPhoneNumberId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  toNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  fromNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  direction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const CallRecordCreateManyInputSchema: z.ZodType<Prisma.CallRecordCreateManyInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  appPhoneNumberId: z.string(),
  toNumber: z.string().optional().nullable(),
  fromNumber: z.string().optional().nullable(),
  direction: z.string().optional().nullable()
}).strict();

export const CallRecordUpdateManyMutationInputSchema: z.ZodType<Prisma.CallRecordUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  appPhoneNumberId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  toNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  fromNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  direction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const CallRecordUncheckedUpdateManyInputSchema: z.ZodType<Prisma.CallRecordUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  appPhoneNumberId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  toNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  fromNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  direction: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const DeviceRegistrationCreateInputSchema: z.ZodType<Prisma.DeviceRegistrationCreateInput> = z.object({
  id: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  surface: z.string().optional().nullable(),
  deviceOsName: z.string().optional().nullable(),
  deviceOsVersion: z.string().optional().nullable(),
  deviceModel: z.string().optional().nullable(),
  friendlyName: z.string().optional().nullable(),
  drivingDetectionEnabled: z.number().int().optional(),
  wakeWordSensitivity: z.number().optional(),
  commandSensitivity: z.number().optional(),
  silenceDetectionInitialThreshold: z.number().optional(),
  silenceDetectionFinalThreshold: z.number().optional(),
  commandTimingWindowMs: z.number().int().optional(),
  recordingTimeoutWindowMs: z.number().int().optional()
}).strict();

export const DeviceRegistrationUncheckedCreateInputSchema: z.ZodType<Prisma.DeviceRegistrationUncheckedCreateInput> = z.object({
  id: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  surface: z.string().optional().nullable(),
  deviceOsName: z.string().optional().nullable(),
  deviceOsVersion: z.string().optional().nullable(),
  deviceModel: z.string().optional().nullable(),
  friendlyName: z.string().optional().nullable(),
  drivingDetectionEnabled: z.number().int().optional(),
  wakeWordSensitivity: z.number().optional(),
  commandSensitivity: z.number().optional(),
  silenceDetectionInitialThreshold: z.number().optional(),
  silenceDetectionFinalThreshold: z.number().optional(),
  commandTimingWindowMs: z.number().int().optional(),
  recordingTimeoutWindowMs: z.number().int().optional()
}).strict();

export const DeviceRegistrationUpdateInputSchema: z.ZodType<Prisma.DeviceRegistrationUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  surface: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceOsName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceOsVersion: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceModel: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  friendlyName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drivingDetectionEnabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  wakeWordSensitivity: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  commandSensitivity: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  silenceDetectionInitialThreshold: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  silenceDetectionFinalThreshold: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  commandTimingWindowMs: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  recordingTimeoutWindowMs: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const DeviceRegistrationUncheckedUpdateInputSchema: z.ZodType<Prisma.DeviceRegistrationUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  surface: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceOsName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceOsVersion: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceModel: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  friendlyName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drivingDetectionEnabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  wakeWordSensitivity: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  commandSensitivity: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  silenceDetectionInitialThreshold: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  silenceDetectionFinalThreshold: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  commandTimingWindowMs: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  recordingTimeoutWindowMs: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const DeviceRegistrationCreateManyInputSchema: z.ZodType<Prisma.DeviceRegistrationCreateManyInput> = z.object({
  id: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  surface: z.string().optional().nullable(),
  deviceOsName: z.string().optional().nullable(),
  deviceOsVersion: z.string().optional().nullable(),
  deviceModel: z.string().optional().nullable(),
  friendlyName: z.string().optional().nullable(),
  drivingDetectionEnabled: z.number().int().optional(),
  wakeWordSensitivity: z.number().optional(),
  commandSensitivity: z.number().optional(),
  silenceDetectionInitialThreshold: z.number().optional(),
  silenceDetectionFinalThreshold: z.number().optional(),
  commandTimingWindowMs: z.number().int().optional(),
  recordingTimeoutWindowMs: z.number().int().optional()
}).strict();

export const DeviceRegistrationUpdateManyMutationInputSchema: z.ZodType<Prisma.DeviceRegistrationUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  surface: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceOsName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceOsVersion: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceModel: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  friendlyName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drivingDetectionEnabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  wakeWordSensitivity: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  commandSensitivity: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  silenceDetectionInitialThreshold: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  silenceDetectionFinalThreshold: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  commandTimingWindowMs: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  recordingTimeoutWindowMs: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const DeviceRegistrationUncheckedUpdateManyInputSchema: z.ZodType<Prisma.DeviceRegistrationUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  surface: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceOsName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceOsVersion: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deviceModel: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  friendlyName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  drivingDetectionEnabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  wakeWordSensitivity: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  commandSensitivity: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  silenceDetectionInitialThreshold: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  silenceDetectionFinalThreshold: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  commandTimingWindowMs: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  recordingTimeoutWindowMs: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const DirectWsInvitationCreateInputSchema: z.ZodType<Prisma.DirectWsInvitationCreateInput> = z.object({
  id: z.string(),
  workspaceMembershipId: z.string(),
  email: z.string().optional().nullable(),
  phoneNumber: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  claimedAt: z.string().optional().nullable(),
  claimedBy: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const DirectWsInvitationUncheckedCreateInputSchema: z.ZodType<Prisma.DirectWsInvitationUncheckedCreateInput> = z.object({
  id: z.string(),
  workspaceMembershipId: z.string(),
  email: z.string().optional().nullable(),
  phoneNumber: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  claimedAt: z.string().optional().nullable(),
  claimedBy: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const DirectWsInvitationUpdateInputSchema: z.ZodType<Prisma.DirectWsInvitationUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phoneNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  claimedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  claimedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const DirectWsInvitationUncheckedUpdateInputSchema: z.ZodType<Prisma.DirectWsInvitationUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phoneNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  claimedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  claimedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const DirectWsInvitationCreateManyInputSchema: z.ZodType<Prisma.DirectWsInvitationCreateManyInput> = z.object({
  id: z.string(),
  workspaceMembershipId: z.string(),
  email: z.string().optional().nullable(),
  phoneNumber: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  claimedAt: z.string().optional().nullable(),
  claimedBy: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const DirectWsInvitationUpdateManyMutationInputSchema: z.ZodType<Prisma.DirectWsInvitationUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phoneNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  claimedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  claimedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const DirectWsInvitationUncheckedUpdateManyInputSchema: z.ZodType<Prisma.DirectWsInvitationUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  email: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  phoneNumber: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  claimedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  claimedBy: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const DisplayArtifactCreateInputSchema: z.ZodType<Prisma.DisplayArtifactCreateInput> = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().optional().nullable(),
  contentId: z.string(),
  deletedAt: z.string().optional().nullable(),
  createdAt: z.string()
}).strict();

export const DisplayArtifactUncheckedCreateInputSchema: z.ZodType<Prisma.DisplayArtifactUncheckedCreateInput> = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().optional().nullable(),
  contentId: z.string(),
  deletedAt: z.string().optional().nullable(),
  createdAt: z.string()
}).strict();

export const DisplayArtifactUpdateInputSchema: z.ZodType<Prisma.DisplayArtifactUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const DisplayArtifactUncheckedUpdateInputSchema: z.ZodType<Prisma.DisplayArtifactUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const DisplayArtifactCreateManyInputSchema: z.ZodType<Prisma.DisplayArtifactCreateManyInput> = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().optional().nullable(),
  contentId: z.string(),
  deletedAt: z.string().optional().nullable(),
  createdAt: z.string()
}).strict();

export const DisplayArtifactUpdateManyMutationInputSchema: z.ZodType<Prisma.DisplayArtifactUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const DisplayArtifactUncheckedUpdateManyInputSchema: z.ZodType<Prisma.DisplayArtifactUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const FeedCreateInputSchema: z.ZodType<Prisma.FeedCreateInput> = z.object({
  id: z.string(),
  title: z.string().optional().nullable(),
  workspaceId: z.string().optional().nullable(),
  feedType: z.string().optional(),
  readOnly: z.number().int().optional(),
  isPrivate: z.number().int().optional(),
  isDm: z.number().int().optional(),
  isSilent: z.number().int().optional(),
  loadedFirstPage: z.number().int().optional(),
  loadedLastPage: z.number().int().optional(),
  loadedEvents: z.number().int().optional(),
  loadedPermissions: z.number().int().optional(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  latestActivity: z.string().optional().nullable(),
  lastOpened: z.string().optional().nullable()
}).strict();

export const FeedUncheckedCreateInputSchema: z.ZodType<Prisma.FeedUncheckedCreateInput> = z.object({
  id: z.string(),
  title: z.string().optional().nullable(),
  workspaceId: z.string().optional().nullable(),
  feedType: z.string().optional(),
  readOnly: z.number().int().optional(),
  isPrivate: z.number().int().optional(),
  isDm: z.number().int().optional(),
  isSilent: z.number().int().optional(),
  loadedFirstPage: z.number().int().optional(),
  loadedLastPage: z.number().int().optional(),
  loadedEvents: z.number().int().optional(),
  loadedPermissions: z.number().int().optional(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  latestActivity: z.string().optional().nullable(),
  lastOpened: z.string().optional().nullable()
}).strict();

export const FeedUpdateInputSchema: z.ZodType<Prisma.FeedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  readOnly: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isPrivate: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isDm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isSilent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedFirstPage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedLastPage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedEvents: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedPermissions: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latestActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastOpened: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const FeedUncheckedUpdateInputSchema: z.ZodType<Prisma.FeedUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  readOnly: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isPrivate: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isDm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isSilent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedFirstPage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedLastPage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedEvents: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedPermissions: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latestActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastOpened: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const FeedCreateManyInputSchema: z.ZodType<Prisma.FeedCreateManyInput> = z.object({
  id: z.string(),
  title: z.string().optional().nullable(),
  workspaceId: z.string().optional().nullable(),
  feedType: z.string().optional(),
  readOnly: z.number().int().optional(),
  isPrivate: z.number().int().optional(),
  isDm: z.number().int().optional(),
  isSilent: z.number().int().optional(),
  loadedFirstPage: z.number().int().optional(),
  loadedLastPage: z.number().int().optional(),
  loadedEvents: z.number().int().optional(),
  loadedPermissions: z.number().int().optional(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  latestActivity: z.string().optional().nullable(),
  lastOpened: z.string().optional().nullable()
}).strict();

export const FeedUpdateManyMutationInputSchema: z.ZodType<Prisma.FeedUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  readOnly: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isPrivate: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isDm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isSilent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedFirstPage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedLastPage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedEvents: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedPermissions: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latestActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastOpened: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const FeedUncheckedUpdateManyInputSchema: z.ZodType<Prisma.FeedUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  title: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  readOnly: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isPrivate: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isDm: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isSilent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedFirstPage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedLastPage: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedEvents: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  loadedPermissions: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  latestActivity: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  lastOpened: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const FeedGroupCreateInputSchema: z.ZodType<Prisma.FeedGroupCreateInput> = z.object({
  id: z.string(),
  name: z.string(),
  accountId: z.string().optional().nullable(),
  workspaceId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  enabled: z.number().int().optional()
}).strict();

export const FeedGroupUncheckedCreateInputSchema: z.ZodType<Prisma.FeedGroupUncheckedCreateInput> = z.object({
  id: z.string(),
  name: z.string(),
  accountId: z.string().optional().nullable(),
  workspaceId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  enabled: z.number().int().optional()
}).strict();

export const FeedGroupUpdateInputSchema: z.ZodType<Prisma.FeedGroupUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const FeedGroupUncheckedUpdateInputSchema: z.ZodType<Prisma.FeedGroupUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const FeedGroupCreateManyInputSchema: z.ZodType<Prisma.FeedGroupCreateManyInput> = z.object({
  id: z.string(),
  name: z.string(),
  accountId: z.string().optional().nullable(),
  workspaceId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  enabled: z.number().int().optional()
}).strict();

export const FeedGroupUpdateManyMutationInputSchema: z.ZodType<Prisma.FeedGroupUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const FeedGroupUncheckedUpdateManyInputSchema: z.ZodType<Prisma.FeedGroupUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const FeedGroupMembershipCreateInputSchema: z.ZodType<Prisma.FeedGroupMembershipCreateInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  feedId: z.string(),
  groupId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  enabled: z.number().int().optional()
}).strict();

export const FeedGroupMembershipUncheckedCreateInputSchema: z.ZodType<Prisma.FeedGroupMembershipUncheckedCreateInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  feedId: z.string(),
  groupId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  enabled: z.number().int().optional()
}).strict();

export const FeedGroupMembershipUpdateInputSchema: z.ZodType<Prisma.FeedGroupMembershipUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  groupId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const FeedGroupMembershipUncheckedUpdateInputSchema: z.ZodType<Prisma.FeedGroupMembershipUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  groupId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const FeedGroupMembershipCreateManyInputSchema: z.ZodType<Prisma.FeedGroupMembershipCreateManyInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  feedId: z.string(),
  groupId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  enabled: z.number().int().optional()
}).strict();

export const FeedGroupMembershipUpdateManyMutationInputSchema: z.ZodType<Prisma.FeedGroupMembershipUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  groupId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const FeedGroupMembershipUncheckedUpdateManyInputSchema: z.ZodType<Prisma.FeedGroupMembershipUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  groupId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const FileCreateInputSchema: z.ZodType<Prisma.FileCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  url: z.string(),
  mimeType: z.string().optional().nullable(),
  name: z.string().optional().nullable()
}).strict();

export const FileUncheckedCreateInputSchema: z.ZodType<Prisma.FileUncheckedCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  url: z.string(),
  mimeType: z.string().optional().nullable(),
  name: z.string().optional().nullable()
}).strict();

export const FileUpdateInputSchema: z.ZodType<Prisma.FileUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mimeType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const FileUncheckedUpdateInputSchema: z.ZodType<Prisma.FileUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mimeType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const FileCreateManyInputSchema: z.ZodType<Prisma.FileCreateManyInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  url: z.string(),
  mimeType: z.string().optional().nullable(),
  name: z.string().optional().nullable()
}).strict();

export const FileUpdateManyMutationInputSchema: z.ZodType<Prisma.FileUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mimeType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const FileUncheckedUpdateManyInputSchema: z.ZodType<Prisma.FileUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  mimeType: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  name: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ItemCreateInputSchema: z.ZodType<Prisma.ItemCreateInput> = z.object({
  id: z.string(),
  feedId: z.string(),
  contentId: z.string(),
  groupId: z.string().optional().nullable(),
  deletedAt: z.string().optional().nullable(),
  accountId: z.string().optional().nullable(),
  loadedContent: z.number().int().optional(),
  isSilent: z.number().int().optional(),
  unread: z.number().int().optional().nullable(),
  isDriverMessage: z.number().int().optional().nullable(),
  isOrganizerMessage: z.number().int().optional().nullable(),
  isAdminMessage: z.number().int().optional().nullable(),
  createdAt: z.string(),
  status: z.string().optional().nullable()
}).strict();

export const ItemUncheckedCreateInputSchema: z.ZodType<Prisma.ItemUncheckedCreateInput> = z.object({
  id: z.string(),
  feedId: z.string(),
  contentId: z.string(),
  groupId: z.string().optional().nullable(),
  deletedAt: z.string().optional().nullable(),
  accountId: z.string().optional().nullable(),
  loadedContent: z.number().int().optional(),
  isSilent: z.number().int().optional(),
  unread: z.number().int().optional().nullable(),
  isDriverMessage: z.number().int().optional().nullable(),
  isOrganizerMessage: z.number().int().optional().nullable(),
  isAdminMessage: z.number().int().optional().nullable(),
  createdAt: z.string(),
  status: z.string().optional().nullable()
}).strict();

export const ItemUpdateInputSchema: z.ZodType<Prisma.ItemUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  groupId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loadedContent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isSilent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  unread: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isDriverMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOrganizerMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isAdminMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ItemUncheckedUpdateInputSchema: z.ZodType<Prisma.ItemUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  groupId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loadedContent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isSilent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  unread: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isDriverMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOrganizerMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isAdminMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ItemCreateManyInputSchema: z.ZodType<Prisma.ItemCreateManyInput> = z.object({
  id: z.string(),
  feedId: z.string(),
  contentId: z.string(),
  groupId: z.string().optional().nullable(),
  deletedAt: z.string().optional().nullable(),
  accountId: z.string().optional().nullable(),
  loadedContent: z.number().int().optional(),
  isSilent: z.number().int().optional(),
  unread: z.number().int().optional().nullable(),
  isDriverMessage: z.number().int().optional().nullable(),
  isOrganizerMessage: z.number().int().optional().nullable(),
  isAdminMessage: z.number().int().optional().nullable(),
  createdAt: z.string(),
  status: z.string().optional().nullable()
}).strict();

export const ItemUpdateManyMutationInputSchema: z.ZodType<Prisma.ItemUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  groupId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loadedContent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isSilent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  unread: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isDriverMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOrganizerMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isAdminMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ItemUncheckedUpdateManyInputSchema: z.ZodType<Prisma.ItemUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  groupId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  loadedContent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  isSilent: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  unread: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isDriverMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isOrganizerMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  isAdminMessage: z.union([ z.number().int(),z.lazy(() => NullableIntFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  status: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const LinkCreateInputSchema: z.ZodType<Prisma.LinkCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  url: z.string(),
  description: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  image: z.string().optional().nullable(),
  linkOrder: z.number().int().optional()
}).strict();

export const LinkUncheckedCreateInputSchema: z.ZodType<Prisma.LinkUncheckedCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  url: z.string(),
  description: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  image: z.string().optional().nullable(),
  linkOrder: z.number().int().optional()
}).strict();

export const LinkUpdateInputSchema: z.ZodType<Prisma.LinkUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  linkOrder: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const LinkUncheckedUpdateInputSchema: z.ZodType<Prisma.LinkUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  linkOrder: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const LinkCreateManyInputSchema: z.ZodType<Prisma.LinkCreateManyInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  url: z.string(),
  description: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  image: z.string().optional().nullable(),
  linkOrder: z.number().int().optional()
}).strict();

export const LinkUpdateManyMutationInputSchema: z.ZodType<Prisma.LinkUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  linkOrder: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const LinkUncheckedUpdateManyInputSchema: z.ZodType<Prisma.LinkUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  url: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  description: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  title: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  image: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  linkOrder: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const PermissionCreateInputSchema: z.ZodType<Prisma.PermissionCreateInput> = z.object({
  id: z.string(),
  name: z.string(),
  accountId: z.string().optional().nullable(),
  workspace_membershipId: z.string().optional().nullable(),
  feedId: z.string().optional().nullable(),
  workflowItemId: z.string().optional().nullable(),
  enabled: z.number().int(),
  expiresAt: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const PermissionUncheckedCreateInputSchema: z.ZodType<Prisma.PermissionUncheckedCreateInput> = z.object({
  id: z.string(),
  name: z.string(),
  accountId: z.string().optional().nullable(),
  workspace_membershipId: z.string().optional().nullable(),
  feedId: z.string().optional().nullable(),
  workflowItemId: z.string().optional().nullable(),
  enabled: z.number().int(),
  expiresAt: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const PermissionUpdateInputSchema: z.ZodType<Prisma.PermissionUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspace_membershipId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workflowItemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  expiresAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PermissionUncheckedUpdateInputSchema: z.ZodType<Prisma.PermissionUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspace_membershipId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workflowItemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  expiresAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PermissionCreateManyInputSchema: z.ZodType<Prisma.PermissionCreateManyInput> = z.object({
  id: z.string(),
  name: z.string(),
  accountId: z.string().optional().nullable(),
  workspace_membershipId: z.string().optional().nullable(),
  feedId: z.string().optional().nullable(),
  workflowItemId: z.string().optional().nullable(),
  enabled: z.number().int(),
  expiresAt: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const PermissionUpdateManyMutationInputSchema: z.ZodType<Prisma.PermissionUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspace_membershipId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workflowItemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  expiresAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PermissionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PermissionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspace_membershipId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workflowItemId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  expiresAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PipelineArtifactMetadataCreateInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  vadResult: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const PipelineArtifactMetadataUncheckedCreateInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataUncheckedCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  vadResult: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const PipelineArtifactMetadataUpdateInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  vadResult: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PipelineArtifactMetadataUncheckedUpdateInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  vadResult: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PipelineArtifactMetadataCreateManyInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataCreateManyInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  vadResult: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable()
}).strict();

export const PipelineArtifactMetadataUpdateManyMutationInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  vadResult: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PipelineArtifactMetadataUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  vadResult: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PublishedWorkflowItemCreateInputSchema: z.ZodType<Prisma.PublishedWorkflowItemCreateInput> = z.object({
  id: z.string(),
  workflowItemId: z.string(),
  broadcastId: z.string(),
  accountId: z.string(),
  workspaceId: z.string(),
  contentId: z.string(),
  createdAt: z.string().optional().nullable()
}).strict();

export const PublishedWorkflowItemUncheckedCreateInputSchema: z.ZodType<Prisma.PublishedWorkflowItemUncheckedCreateInput> = z.object({
  id: z.string(),
  workflowItemId: z.string(),
  broadcastId: z.string(),
  accountId: z.string(),
  workspaceId: z.string(),
  contentId: z.string(),
  createdAt: z.string().optional().nullable()
}).strict();

export const PublishedWorkflowItemUpdateInputSchema: z.ZodType<Prisma.PublishedWorkflowItemUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workflowItemId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PublishedWorkflowItemUncheckedUpdateInputSchema: z.ZodType<Prisma.PublishedWorkflowItemUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workflowItemId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PublishedWorkflowItemCreateManyInputSchema: z.ZodType<Prisma.PublishedWorkflowItemCreateManyInput> = z.object({
  id: z.string(),
  workflowItemId: z.string(),
  broadcastId: z.string(),
  accountId: z.string(),
  workspaceId: z.string(),
  contentId: z.string(),
  createdAt: z.string().optional().nullable()
}).strict();

export const PublishedWorkflowItemUpdateManyMutationInputSchema: z.ZodType<Prisma.PublishedWorkflowItemUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workflowItemId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const PublishedWorkflowItemUncheckedUpdateManyInputSchema: z.ZodType<Prisma.PublishedWorkflowItemUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workflowItemId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const ScheduleTriggerCreateInputSchema: z.ZodType<Prisma.ScheduleTriggerCreateInput> = z.object({
  id: z.string(),
  oneTimeSchedule: z.string().optional().nullable(),
  cronSchedule: z.string().optional().nullable(),
  workspaceId: z.string(),
  broadcastActionId: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  timezone: z.string().optional().nullable(),
  enabled: z.number().int().optional()
}).strict();

export const ScheduleTriggerUncheckedCreateInputSchema: z.ZodType<Prisma.ScheduleTriggerUncheckedCreateInput> = z.object({
  id: z.string(),
  oneTimeSchedule: z.string().optional().nullable(),
  cronSchedule: z.string().optional().nullable(),
  workspaceId: z.string(),
  broadcastActionId: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  timezone: z.string().optional().nullable(),
  enabled: z.number().int().optional()
}).strict();

export const ScheduleTriggerUpdateInputSchema: z.ZodType<Prisma.ScheduleTriggerUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  oneTimeSchedule: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cronSchedule: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastActionId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timezone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const ScheduleTriggerUncheckedUpdateInputSchema: z.ZodType<Prisma.ScheduleTriggerUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  oneTimeSchedule: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cronSchedule: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastActionId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timezone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const ScheduleTriggerCreateManyInputSchema: z.ZodType<Prisma.ScheduleTriggerCreateManyInput> = z.object({
  id: z.string(),
  oneTimeSchedule: z.string().optional().nullable(),
  cronSchedule: z.string().optional().nullable(),
  workspaceId: z.string(),
  broadcastActionId: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  timezone: z.string().optional().nullable(),
  enabled: z.number().int().optional()
}).strict();

export const ScheduleTriggerUpdateManyMutationInputSchema: z.ZodType<Prisma.ScheduleTriggerUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  oneTimeSchedule: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cronSchedule: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastActionId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timezone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const ScheduleTriggerUncheckedUpdateManyInputSchema: z.ZodType<Prisma.ScheduleTriggerUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  oneTimeSchedule: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  cronSchedule: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  broadcastActionId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  timezone: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const TemplateCreateInputSchema: z.ZodType<Prisma.TemplateCreateInput> = z.object({
  id: z.string(),
  name: z.string(),
  template: z.string(),
  workspaceId: z.string(),
  authorId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  deletedAt: z.string().optional().nullable()
}).strict();

export const TemplateUncheckedCreateInputSchema: z.ZodType<Prisma.TemplateUncheckedCreateInput> = z.object({
  id: z.string(),
  name: z.string(),
  template: z.string(),
  workspaceId: z.string(),
  authorId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  deletedAt: z.string().optional().nullable()
}).strict();

export const TemplateUpdateInputSchema: z.ZodType<Prisma.TemplateUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  template: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const TemplateUncheckedUpdateInputSchema: z.ZodType<Prisma.TemplateUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  template: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const TemplateCreateManyInputSchema: z.ZodType<Prisma.TemplateCreateManyInput> = z.object({
  id: z.string(),
  name: z.string(),
  template: z.string(),
  workspaceId: z.string(),
  authorId: z.string(),
  createdAt: z.string().optional().nullable(),
  updatedAt: z.string().optional().nullable(),
  deletedAt: z.string().optional().nullable()
}).strict();

export const TemplateUpdateManyMutationInputSchema: z.ZodType<Prisma.TemplateUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  template: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const TemplateUncheckedUpdateManyInputSchema: z.ZodType<Prisma.TemplateUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  template: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  authorId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  updatedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const TranscriptionCreateInputSchema: z.ZodType<Prisma.TranscriptionCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  transcriptionType: z.string(),
  transcriptionContent: z.string(),
  language: z.string().optional(),
  translatedFrom: z.string().optional().nullable(),
  backendModel: z.string().optional().nullable(),
  priority: z.number().int().optional(),
  confidence: z.number().optional().nullable(),
  executionTime: z.number().optional(),
  format: z.string().optional(),
  pipeline: z.string().optional().nullable(),
  bucket: z.string().optional().nullable(),
  key: z.string().optional().nullable(),
  url: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const TranscriptionUncheckedCreateInputSchema: z.ZodType<Prisma.TranscriptionUncheckedCreateInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  transcriptionType: z.string(),
  transcriptionContent: z.string(),
  language: z.string().optional(),
  translatedFrom: z.string().optional().nullable(),
  backendModel: z.string().optional().nullable(),
  priority: z.number().int().optional(),
  confidence: z.number().optional().nullable(),
  executionTime: z.number().optional(),
  format: z.string().optional(),
  pipeline: z.string().optional().nullable(),
  bucket: z.string().optional().nullable(),
  key: z.string().optional().nullable(),
  url: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const TranscriptionUpdateInputSchema: z.ZodType<Prisma.TranscriptionUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionContent: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  language: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  translatedFrom: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  backendModel: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  priority: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  confidence: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  executionTime: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  format: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pipeline: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bucket: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  key: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const TranscriptionUncheckedUpdateInputSchema: z.ZodType<Prisma.TranscriptionUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionContent: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  language: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  translatedFrom: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  backendModel: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  priority: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  confidence: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  executionTime: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  format: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pipeline: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bucket: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  key: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const TranscriptionCreateManyInputSchema: z.ZodType<Prisma.TranscriptionCreateManyInput> = z.object({
  id: z.string(),
  contentId: z.string(),
  transcriptionType: z.string(),
  transcriptionContent: z.string(),
  language: z.string().optional(),
  translatedFrom: z.string().optional().nullable(),
  backendModel: z.string().optional().nullable(),
  priority: z.number().int().optional(),
  confidence: z.number().optional().nullable(),
  executionTime: z.number().optional(),
  format: z.string().optional(),
  pipeline: z.string().optional().nullable(),
  bucket: z.string().optional().nullable(),
  key: z.string().optional().nullable(),
  url: z.string().optional().nullable(),
  createdAt: z.string().optional().nullable()
}).strict();

export const TranscriptionUpdateManyMutationInputSchema: z.ZodType<Prisma.TranscriptionUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionContent: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  language: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  translatedFrom: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  backendModel: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  priority: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  confidence: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  executionTime: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  format: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pipeline: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bucket: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  key: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const TranscriptionUncheckedUpdateManyInputSchema: z.ZodType<Prisma.TranscriptionUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionType: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  transcriptionContent: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  language: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  translatedFrom: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  backendModel: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  priority: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  confidence: z.union([ z.number(),z.lazy(() => NullableFloatFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  executionTime: z.union([ z.number(),z.lazy(() => FloatFieldUpdateOperationsInputSchema) ]).optional(),
  format: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  pipeline: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  bucket: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  key: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  url: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const WorkflowItemCreateInputSchema: z.ZodType<Prisma.WorkflowItemCreateInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  contentId: z.string(),
  createdAt: z.string().optional().nullable(),
  deletedAt: z.string().optional().nullable(),
  displayName: z.string().optional().nullable()
}).strict();

export const WorkflowItemUncheckedCreateInputSchema: z.ZodType<Prisma.WorkflowItemUncheckedCreateInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  contentId: z.string(),
  createdAt: z.string().optional().nullable(),
  deletedAt: z.string().optional().nullable(),
  displayName: z.string().optional().nullable()
}).strict();

export const WorkflowItemUpdateInputSchema: z.ZodType<Prisma.WorkflowItemUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  displayName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const WorkflowItemUncheckedUpdateInputSchema: z.ZodType<Prisma.WorkflowItemUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  displayName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const WorkflowItemCreateManyInputSchema: z.ZodType<Prisma.WorkflowItemCreateManyInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  contentId: z.string(),
  createdAt: z.string().optional().nullable(),
  deletedAt: z.string().optional().nullable(),
  displayName: z.string().optional().nullable()
}).strict();

export const WorkflowItemUpdateManyMutationInputSchema: z.ZodType<Prisma.WorkflowItemUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  displayName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const WorkflowItemUncheckedUpdateManyInputSchema: z.ZodType<Prisma.WorkflowItemUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  contentId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  deletedAt: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  displayName: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
}).strict();

export const WorkspaceCreateInputSchema: z.ZodType<Prisma.WorkspaceCreateInput> = z.object({
  id: z.string(),
  name: z.string()
}).strict();

export const WorkspaceUncheckedCreateInputSchema: z.ZodType<Prisma.WorkspaceUncheckedCreateInput> = z.object({
  id: z.string(),
  name: z.string()
}).strict();

export const WorkspaceUpdateInputSchema: z.ZodType<Prisma.WorkspaceUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorkspaceUncheckedUpdateInputSchema: z.ZodType<Prisma.WorkspaceUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorkspaceCreateManyInputSchema: z.ZodType<Prisma.WorkspaceCreateManyInput> = z.object({
  id: z.string(),
  name: z.string()
}).strict();

export const WorkspaceUpdateManyMutationInputSchema: z.ZodType<Prisma.WorkspaceUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorkspaceUncheckedUpdateManyInputSchema: z.ZodType<Prisma.WorkspaceUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  name: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorkspaceCommandAliasCreateInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasCreateInput> = z.object({
  id: z.string(),
  alias: z.string(),
  workspaceId: z.string(),
  feedId: z.string().optional().nullable(),
  workspaceMembershipId: z.string(),
  createdAt: z.string()
}).strict();

export const WorkspaceCommandAliasUncheckedCreateInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasUncheckedCreateInput> = z.object({
  id: z.string(),
  alias: z.string(),
  workspaceId: z.string(),
  feedId: z.string().optional().nullable(),
  workspaceMembershipId: z.string(),
  createdAt: z.string()
}).strict();

export const WorkspaceCommandAliasUpdateInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  alias: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorkspaceCommandAliasUncheckedUpdateInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  alias: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorkspaceCommandAliasCreateManyInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasCreateManyInput> = z.object({
  id: z.string(),
  alias: z.string(),
  workspaceId: z.string(),
  feedId: z.string().optional().nullable(),
  workspaceMembershipId: z.string(),
  createdAt: z.string()
}).strict();

export const WorkspaceCommandAliasUpdateManyMutationInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  alias: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorkspaceCommandAliasUncheckedUpdateManyInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  alias: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  feedId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  workspaceMembershipId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  createdAt: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorkspaceMembershipCreateInputSchema: z.ZodType<Prisma.WorkspaceMembershipCreateInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  accountId: z.string().optional().nullable(),
  status: z.string(),
  role: z.string()
}).strict();

export const WorkspaceMembershipUncheckedCreateInputSchema: z.ZodType<Prisma.WorkspaceMembershipUncheckedCreateInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  accountId: z.string().optional().nullable(),
  status: z.string(),
  role: z.string()
}).strict();

export const WorkspaceMembershipUpdateInputSchema: z.ZodType<Prisma.WorkspaceMembershipUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorkspaceMembershipUncheckedUpdateInputSchema: z.ZodType<Prisma.WorkspaceMembershipUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorkspaceMembershipCreateManyInputSchema: z.ZodType<Prisma.WorkspaceMembershipCreateManyInput> = z.object({
  id: z.string(),
  workspaceId: z.string(),
  accountId: z.string().optional().nullable(),
  status: z.string(),
  role: z.string()
}).strict();

export const WorkspaceMembershipUpdateManyMutationInputSchema: z.ZodType<Prisma.WorkspaceMembershipUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WorkspaceMembershipUncheckedUpdateManyInputSchema: z.ZodType<Prisma.WorkspaceMembershipUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  workspaceId: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  accountId: z.union([ z.string(),z.lazy(() => NullableStringFieldUpdateOperationsInputSchema) ]).optional().nullable(),
  status: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  role: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WsHandsFreeStatusCreateInputSchema: z.ZodType<Prisma.WsHandsFreeStatusCreateInput> = z.object({
  id: z.string(),
  enabled: z.number().int().optional(),
  timestamp: z.string()
}).strict();

export const WsHandsFreeStatusUncheckedCreateInputSchema: z.ZodType<Prisma.WsHandsFreeStatusUncheckedCreateInput> = z.object({
  id: z.string(),
  enabled: z.number().int().optional(),
  timestamp: z.string()
}).strict();

export const WsHandsFreeStatusUpdateInputSchema: z.ZodType<Prisma.WsHandsFreeStatusUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WsHandsFreeStatusUncheckedUpdateInputSchema: z.ZodType<Prisma.WsHandsFreeStatusUncheckedUpdateInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WsHandsFreeStatusCreateManyInputSchema: z.ZodType<Prisma.WsHandsFreeStatusCreateManyInput> = z.object({
  id: z.string(),
  enabled: z.number().int().optional(),
  timestamp: z.string()
}).strict();

export const WsHandsFreeStatusUpdateManyMutationInputSchema: z.ZodType<Prisma.WsHandsFreeStatusUpdateManyMutationInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const WsHandsFreeStatusUncheckedUpdateManyInputSchema: z.ZodType<Prisma.WsHandsFreeStatusUncheckedUpdateManyInput> = z.object({
  id: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
  enabled: z.union([ z.number().int(),z.lazy(() => IntFieldUpdateOperationsInputSchema) ]).optional(),
  timestamp: z.union([ z.string(),z.lazy(() => StringFieldUpdateOperationsInputSchema) ]).optional(),
}).strict();

export const StringFilterSchema: z.ZodType<Prisma.StringFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict();

export const StringNullableFilterSchema: z.ZodType<Prisma.StringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const IntFilterSchema: z.ZodType<Prisma.IntFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict();

export const AccountCountOrderByAggregateInputSchema: z.ZodType<Prisma.AccountCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  phoneNumberVerified: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  avatarColor: z.lazy(() => SortOrderSchema).optional(),
  accountType: z.lazy(() => SortOrderSchema).optional(),
  mine: z.lazy(() => SortOrderSchema).optional(),
  preferredLanguage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountAvgOrderByAggregateInputSchema: z.ZodType<Prisma.AccountAvgOrderByAggregateInput> = z.object({
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  phoneNumberVerified: z.lazy(() => SortOrderSchema).optional(),
  mine: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AccountMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  phoneNumberVerified: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  avatarColor: z.lazy(() => SortOrderSchema).optional(),
  accountType: z.lazy(() => SortOrderSchema).optional(),
  mine: z.lazy(() => SortOrderSchema).optional(),
  preferredLanguage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountMinOrderByAggregateInputSchema: z.ZodType<Prisma.AccountMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  firstName: z.lazy(() => SortOrderSchema).optional(),
  lastName: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  phoneNumberVerified: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  avatarColor: z.lazy(() => SortOrderSchema).optional(),
  accountType: z.lazy(() => SortOrderSchema).optional(),
  mine: z.lazy(() => SortOrderSchema).optional(),
  preferredLanguage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountSumOrderByAggregateInputSchema: z.ZodType<Prisma.AccountSumOrderByAggregateInput> = z.object({
  emailVerified: z.lazy(() => SortOrderSchema).optional(),
  phoneNumberVerified: z.lazy(() => SortOrderSchema).optional(),
  mine: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const StringWithAggregatesFilterSchema: z.ZodType<Prisma.StringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const StringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.StringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  mode: z.lazy(() => QueryModeSchema).optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const IntWithAggregatesFilterSchema: z.ZodType<Prisma.IntWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedIntFilterSchema).optional(),
  _max: z.lazy(() => NestedIntFilterSchema).optional()
}).strict();

export const AccountEventAccountIdFeedIdItemIdContentIdNameCompoundUniqueInputSchema: z.ZodType<Prisma.AccountEventAccountIdFeedIdItemIdContentIdNameCompoundUniqueInput> = z.object({
  accountId: z.string(),
  feedId: z.string(),
  itemId: z.string(),
  contentId: z.string(),
  name: z.string()
}).strict();

export const AccountEventCountOrderByAggregateInputSchema: z.ZodType<Prisma.AccountEventCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  itemId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountEventMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AccountEventMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  itemId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AccountEventMinOrderByAggregateInputSchema: z.ZodType<Prisma.AccountEventMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  itemId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AppPhoneNumberCountOrderByAggregateInputSchema: z.ZodType<Prisma.AppPhoneNumberCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  forwardingNumber: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AppPhoneNumberMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AppPhoneNumberMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  forwardingNumber: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AppPhoneNumberMinOrderByAggregateInputSchema: z.ZodType<Prisma.AppPhoneNumberMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  forwardingNumber: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FloatFilterSchema: z.ZodType<Prisma.FloatFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatFilterSchema) ]).optional(),
}).strict();

export const AudioEncodingCountOrderByAggregateInputSchema: z.ZodType<Prisma.AudioEncodingCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  codec: z.lazy(() => SortOrderSchema).optional(),
  mimeType: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  transcriptionId: z.lazy(() => SortOrderSchema).optional(),
  transcriptionType: z.lazy(() => SortOrderSchema).optional(),
  language: z.lazy(() => SortOrderSchema).optional(),
  translatedFrom: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  generatedVoice: z.lazy(() => SortOrderSchema).optional(),
  generatedService: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AudioEncodingAvgOrderByAggregateInputSchema: z.ZodType<Prisma.AudioEncodingAvgOrderByAggregateInput> = z.object({
  duration: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AudioEncodingMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AudioEncodingMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  codec: z.lazy(() => SortOrderSchema).optional(),
  mimeType: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  transcriptionId: z.lazy(() => SortOrderSchema).optional(),
  transcriptionType: z.lazy(() => SortOrderSchema).optional(),
  language: z.lazy(() => SortOrderSchema).optional(),
  translatedFrom: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  generatedVoice: z.lazy(() => SortOrderSchema).optional(),
  generatedService: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AudioEncodingMinOrderByAggregateInputSchema: z.ZodType<Prisma.AudioEncodingMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  codec: z.lazy(() => SortOrderSchema).optional(),
  mimeType: z.lazy(() => SortOrderSchema).optional(),
  duration: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  transcriptionId: z.lazy(() => SortOrderSchema).optional(),
  transcriptionType: z.lazy(() => SortOrderSchema).optional(),
  language: z.lazy(() => SortOrderSchema).optional(),
  translatedFrom: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  generatedVoice: z.lazy(() => SortOrderSchema).optional(),
  generatedService: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AudioEncodingSumOrderByAggregateInputSchema: z.ZodType<Prisma.AudioEncodingSumOrderByAggregateInput> = z.object({
  duration: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FloatWithAggregatesFilterSchema: z.ZodType<Prisma.FloatWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatFilterSchema).optional()
}).strict();

export const AudioQueueItemCountOrderByAggregateInputSchema: z.ZodType<Prisma.AudioQueueItemCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  itemId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AudioQueueItemMaxOrderByAggregateInputSchema: z.ZodType<Prisma.AudioQueueItemMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  itemId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const AudioQueueItemMinOrderByAggregateInputSchema: z.ZodType<Prisma.AudioQueueItemMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  itemId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastCountOrderByAggregateInputSchema: z.ZodType<Prisma.BroadcastCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastMaxOrderByAggregateInputSchema: z.ZodType<Prisma.BroadcastMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastMinOrderByAggregateInputSchema: z.ZodType<Prisma.BroadcastMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastActionCountOrderByAggregateInputSchema: z.ZodType<Prisma.BroadcastActionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastActionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.BroadcastActionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastActionMinOrderByAggregateInputSchema: z.ZodType<Prisma.BroadcastActionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastRecipientCountOrderByAggregateInputSchema: z.ZodType<Prisma.BroadcastRecipientCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastRecipientMaxOrderByAggregateInputSchema: z.ZodType<Prisma.BroadcastRecipientMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const BroadcastRecipientMinOrderByAggregateInputSchema: z.ZodType<Prisma.BroadcastRecipientMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CallRecordCountOrderByAggregateInputSchema: z.ZodType<Prisma.CallRecordCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  appPhoneNumberId: z.lazy(() => SortOrderSchema).optional(),
  toNumber: z.lazy(() => SortOrderSchema).optional(),
  fromNumber: z.lazy(() => SortOrderSchema).optional(),
  direction: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CallRecordMaxOrderByAggregateInputSchema: z.ZodType<Prisma.CallRecordMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  appPhoneNumberId: z.lazy(() => SortOrderSchema).optional(),
  toNumber: z.lazy(() => SortOrderSchema).optional(),
  fromNumber: z.lazy(() => SortOrderSchema).optional(),
  direction: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const CallRecordMinOrderByAggregateInputSchema: z.ZodType<Prisma.CallRecordMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  appPhoneNumberId: z.lazy(() => SortOrderSchema).optional(),
  toNumber: z.lazy(() => SortOrderSchema).optional(),
  fromNumber: z.lazy(() => SortOrderSchema).optional(),
  direction: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DeviceRegistrationCountOrderByAggregateInputSchema: z.ZodType<Prisma.DeviceRegistrationCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  surface: z.lazy(() => SortOrderSchema).optional(),
  deviceOsName: z.lazy(() => SortOrderSchema).optional(),
  deviceOsVersion: z.lazy(() => SortOrderSchema).optional(),
  deviceModel: z.lazy(() => SortOrderSchema).optional(),
  friendlyName: z.lazy(() => SortOrderSchema).optional(),
  drivingDetectionEnabled: z.lazy(() => SortOrderSchema).optional(),
  wakeWordSensitivity: z.lazy(() => SortOrderSchema).optional(),
  commandSensitivity: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionInitialThreshold: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionFinalThreshold: z.lazy(() => SortOrderSchema).optional(),
  commandTimingWindowMs: z.lazy(() => SortOrderSchema).optional(),
  recordingTimeoutWindowMs: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DeviceRegistrationAvgOrderByAggregateInputSchema: z.ZodType<Prisma.DeviceRegistrationAvgOrderByAggregateInput> = z.object({
  drivingDetectionEnabled: z.lazy(() => SortOrderSchema).optional(),
  wakeWordSensitivity: z.lazy(() => SortOrderSchema).optional(),
  commandSensitivity: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionInitialThreshold: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionFinalThreshold: z.lazy(() => SortOrderSchema).optional(),
  commandTimingWindowMs: z.lazy(() => SortOrderSchema).optional(),
  recordingTimeoutWindowMs: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DeviceRegistrationMaxOrderByAggregateInputSchema: z.ZodType<Prisma.DeviceRegistrationMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  surface: z.lazy(() => SortOrderSchema).optional(),
  deviceOsName: z.lazy(() => SortOrderSchema).optional(),
  deviceOsVersion: z.lazy(() => SortOrderSchema).optional(),
  deviceModel: z.lazy(() => SortOrderSchema).optional(),
  friendlyName: z.lazy(() => SortOrderSchema).optional(),
  drivingDetectionEnabled: z.lazy(() => SortOrderSchema).optional(),
  wakeWordSensitivity: z.lazy(() => SortOrderSchema).optional(),
  commandSensitivity: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionInitialThreshold: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionFinalThreshold: z.lazy(() => SortOrderSchema).optional(),
  commandTimingWindowMs: z.lazy(() => SortOrderSchema).optional(),
  recordingTimeoutWindowMs: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DeviceRegistrationMinOrderByAggregateInputSchema: z.ZodType<Prisma.DeviceRegistrationMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  surface: z.lazy(() => SortOrderSchema).optional(),
  deviceOsName: z.lazy(() => SortOrderSchema).optional(),
  deviceOsVersion: z.lazy(() => SortOrderSchema).optional(),
  deviceModel: z.lazy(() => SortOrderSchema).optional(),
  friendlyName: z.lazy(() => SortOrderSchema).optional(),
  drivingDetectionEnabled: z.lazy(() => SortOrderSchema).optional(),
  wakeWordSensitivity: z.lazy(() => SortOrderSchema).optional(),
  commandSensitivity: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionInitialThreshold: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionFinalThreshold: z.lazy(() => SortOrderSchema).optional(),
  commandTimingWindowMs: z.lazy(() => SortOrderSchema).optional(),
  recordingTimeoutWindowMs: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DeviceRegistrationSumOrderByAggregateInputSchema: z.ZodType<Prisma.DeviceRegistrationSumOrderByAggregateInput> = z.object({
  drivingDetectionEnabled: z.lazy(() => SortOrderSchema).optional(),
  wakeWordSensitivity: z.lazy(() => SortOrderSchema).optional(),
  commandSensitivity: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionInitialThreshold: z.lazy(() => SortOrderSchema).optional(),
  silenceDetectionFinalThreshold: z.lazy(() => SortOrderSchema).optional(),
  commandTimingWindowMs: z.lazy(() => SortOrderSchema).optional(),
  recordingTimeoutWindowMs: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DirectWsInvitationCountOrderByAggregateInputSchema: z.ZodType<Prisma.DirectWsInvitationCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  claimedAt: z.lazy(() => SortOrderSchema).optional(),
  claimedBy: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DirectWsInvitationMaxOrderByAggregateInputSchema: z.ZodType<Prisma.DirectWsInvitationMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  claimedAt: z.lazy(() => SortOrderSchema).optional(),
  claimedBy: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DirectWsInvitationMinOrderByAggregateInputSchema: z.ZodType<Prisma.DirectWsInvitationMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  email: z.lazy(() => SortOrderSchema).optional(),
  phoneNumber: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  claimedAt: z.lazy(() => SortOrderSchema).optional(),
  claimedBy: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DisplayArtifactCountOrderByAggregateInputSchema: z.ZodType<Prisma.DisplayArtifactCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DisplayArtifactMaxOrderByAggregateInputSchema: z.ZodType<Prisma.DisplayArtifactMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const DisplayArtifactMinOrderByAggregateInputSchema: z.ZodType<Prisma.DisplayArtifactMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedCountOrderByAggregateInputSchema: z.ZodType<Prisma.FeedCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedType: z.lazy(() => SortOrderSchema).optional(),
  readOnly: z.lazy(() => SortOrderSchema).optional(),
  isPrivate: z.lazy(() => SortOrderSchema).optional(),
  isDm: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  loadedFirstPage: z.lazy(() => SortOrderSchema).optional(),
  loadedLastPage: z.lazy(() => SortOrderSchema).optional(),
  loadedEvents: z.lazy(() => SortOrderSchema).optional(),
  loadedPermissions: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  latestActivity: z.lazy(() => SortOrderSchema).optional(),
  lastOpened: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedAvgOrderByAggregateInputSchema: z.ZodType<Prisma.FeedAvgOrderByAggregateInput> = z.object({
  readOnly: z.lazy(() => SortOrderSchema).optional(),
  isPrivate: z.lazy(() => SortOrderSchema).optional(),
  isDm: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  loadedFirstPage: z.lazy(() => SortOrderSchema).optional(),
  loadedLastPage: z.lazy(() => SortOrderSchema).optional(),
  loadedEvents: z.lazy(() => SortOrderSchema).optional(),
  loadedPermissions: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedMaxOrderByAggregateInputSchema: z.ZodType<Prisma.FeedMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedType: z.lazy(() => SortOrderSchema).optional(),
  readOnly: z.lazy(() => SortOrderSchema).optional(),
  isPrivate: z.lazy(() => SortOrderSchema).optional(),
  isDm: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  loadedFirstPage: z.lazy(() => SortOrderSchema).optional(),
  loadedLastPage: z.lazy(() => SortOrderSchema).optional(),
  loadedEvents: z.lazy(() => SortOrderSchema).optional(),
  loadedPermissions: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  latestActivity: z.lazy(() => SortOrderSchema).optional(),
  lastOpened: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedMinOrderByAggregateInputSchema: z.ZodType<Prisma.FeedMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedType: z.lazy(() => SortOrderSchema).optional(),
  readOnly: z.lazy(() => SortOrderSchema).optional(),
  isPrivate: z.lazy(() => SortOrderSchema).optional(),
  isDm: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  loadedFirstPage: z.lazy(() => SortOrderSchema).optional(),
  loadedLastPage: z.lazy(() => SortOrderSchema).optional(),
  loadedEvents: z.lazy(() => SortOrderSchema).optional(),
  loadedPermissions: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  latestActivity: z.lazy(() => SortOrderSchema).optional(),
  lastOpened: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedSumOrderByAggregateInputSchema: z.ZodType<Prisma.FeedSumOrderByAggregateInput> = z.object({
  readOnly: z.lazy(() => SortOrderSchema).optional(),
  isPrivate: z.lazy(() => SortOrderSchema).optional(),
  isDm: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  loadedFirstPage: z.lazy(() => SortOrderSchema).optional(),
  loadedLastPage: z.lazy(() => SortOrderSchema).optional(),
  loadedEvents: z.lazy(() => SortOrderSchema).optional(),
  loadedPermissions: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupCountOrderByAggregateInputSchema: z.ZodType<Prisma.FeedGroupCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupAvgOrderByAggregateInputSchema: z.ZodType<Prisma.FeedGroupAvgOrderByAggregateInput> = z.object({
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupMaxOrderByAggregateInputSchema: z.ZodType<Prisma.FeedGroupMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupMinOrderByAggregateInputSchema: z.ZodType<Prisma.FeedGroupMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupSumOrderByAggregateInputSchema: z.ZodType<Prisma.FeedGroupSumOrderByAggregateInput> = z.object({
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupMembershipCountOrderByAggregateInputSchema: z.ZodType<Prisma.FeedGroupMembershipCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  groupId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupMembershipAvgOrderByAggregateInputSchema: z.ZodType<Prisma.FeedGroupMembershipAvgOrderByAggregateInput> = z.object({
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupMembershipMaxOrderByAggregateInputSchema: z.ZodType<Prisma.FeedGroupMembershipMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  groupId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupMembershipMinOrderByAggregateInputSchema: z.ZodType<Prisma.FeedGroupMembershipMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  groupId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FeedGroupMembershipSumOrderByAggregateInputSchema: z.ZodType<Prisma.FeedGroupMembershipSumOrderByAggregateInput> = z.object({
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FileCountOrderByAggregateInputSchema: z.ZodType<Prisma.FileCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  mimeType: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FileMaxOrderByAggregateInputSchema: z.ZodType<Prisma.FileMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  mimeType: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FileMinOrderByAggregateInputSchema: z.ZodType<Prisma.FileMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  mimeType: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const IntNullableFilterSchema: z.ZodType<Prisma.IntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const ItemCountOrderByAggregateInputSchema: z.ZodType<Prisma.ItemCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  groupId: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  loadedContent: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  unread: z.lazy(() => SortOrderSchema).optional(),
  isDriverMessage: z.lazy(() => SortOrderSchema).optional(),
  isOrganizerMessage: z.lazy(() => SortOrderSchema).optional(),
  isAdminMessage: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ItemAvgOrderByAggregateInputSchema: z.ZodType<Prisma.ItemAvgOrderByAggregateInput> = z.object({
  loadedContent: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  unread: z.lazy(() => SortOrderSchema).optional(),
  isDriverMessage: z.lazy(() => SortOrderSchema).optional(),
  isOrganizerMessage: z.lazy(() => SortOrderSchema).optional(),
  isAdminMessage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ItemMaxOrderByAggregateInputSchema: z.ZodType<Prisma.ItemMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  groupId: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  loadedContent: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  unread: z.lazy(() => SortOrderSchema).optional(),
  isDriverMessage: z.lazy(() => SortOrderSchema).optional(),
  isOrganizerMessage: z.lazy(() => SortOrderSchema).optional(),
  isAdminMessage: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ItemMinOrderByAggregateInputSchema: z.ZodType<Prisma.ItemMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  groupId: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  loadedContent: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  unread: z.lazy(() => SortOrderSchema).optional(),
  isDriverMessage: z.lazy(() => SortOrderSchema).optional(),
  isOrganizerMessage: z.lazy(() => SortOrderSchema).optional(),
  isAdminMessage: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ItemSumOrderByAggregateInputSchema: z.ZodType<Prisma.ItemSumOrderByAggregateInput> = z.object({
  loadedContent: z.lazy(() => SortOrderSchema).optional(),
  isSilent: z.lazy(() => SortOrderSchema).optional(),
  unread: z.lazy(() => SortOrderSchema).optional(),
  isDriverMessage: z.lazy(() => SortOrderSchema).optional(),
  isOrganizerMessage: z.lazy(() => SortOrderSchema).optional(),
  isAdminMessage: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const IntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.IntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict();

export const LinkContentIdUrlCompoundUniqueInputSchema: z.ZodType<Prisma.LinkContentIdUrlCompoundUniqueInput> = z.object({
  contentId: z.string(),
  url: z.string()
}).strict();

export const LinkCountOrderByAggregateInputSchema: z.ZodType<Prisma.LinkCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  linkOrder: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const LinkAvgOrderByAggregateInputSchema: z.ZodType<Prisma.LinkAvgOrderByAggregateInput> = z.object({
  linkOrder: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const LinkMaxOrderByAggregateInputSchema: z.ZodType<Prisma.LinkMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  linkOrder: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const LinkMinOrderByAggregateInputSchema: z.ZodType<Prisma.LinkMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  description: z.lazy(() => SortOrderSchema).optional(),
  title: z.lazy(() => SortOrderSchema).optional(),
  image: z.lazy(() => SortOrderSchema).optional(),
  linkOrder: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const LinkSumOrderByAggregateInputSchema: z.ZodType<Prisma.LinkSumOrderByAggregateInput> = z.object({
  linkOrder: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PermissionCountOrderByAggregateInputSchema: z.ZodType<Prisma.PermissionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspace_membershipId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  expiresAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PermissionAvgOrderByAggregateInputSchema: z.ZodType<Prisma.PermissionAvgOrderByAggregateInput> = z.object({
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PermissionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PermissionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspace_membershipId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  expiresAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PermissionMinOrderByAggregateInputSchema: z.ZodType<Prisma.PermissionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspace_membershipId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  expiresAt: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PermissionSumOrderByAggregateInputSchema: z.ZodType<Prisma.PermissionSumOrderByAggregateInput> = z.object({
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PipelineArtifactMetadataCountOrderByAggregateInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  vadResult: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PipelineArtifactMetadataMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  vadResult: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PipelineArtifactMetadataMinOrderByAggregateInputSchema: z.ZodType<Prisma.PipelineArtifactMetadataMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  vadResult: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PublishedWorkflowItemCountOrderByAggregateInputSchema: z.ZodType<Prisma.PublishedWorkflowItemCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PublishedWorkflowItemMaxOrderByAggregateInputSchema: z.ZodType<Prisma.PublishedWorkflowItemMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const PublishedWorkflowItemMinOrderByAggregateInputSchema: z.ZodType<Prisma.PublishedWorkflowItemMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workflowItemId: z.lazy(() => SortOrderSchema).optional(),
  broadcastId: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ScheduleTriggerCountOrderByAggregateInputSchema: z.ZodType<Prisma.ScheduleTriggerCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  oneTimeSchedule: z.lazy(() => SortOrderSchema).optional(),
  cronSchedule: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  broadcastActionId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  timezone: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ScheduleTriggerAvgOrderByAggregateInputSchema: z.ZodType<Prisma.ScheduleTriggerAvgOrderByAggregateInput> = z.object({
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ScheduleTriggerMaxOrderByAggregateInputSchema: z.ZodType<Prisma.ScheduleTriggerMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  oneTimeSchedule: z.lazy(() => SortOrderSchema).optional(),
  cronSchedule: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  broadcastActionId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  timezone: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ScheduleTriggerMinOrderByAggregateInputSchema: z.ZodType<Prisma.ScheduleTriggerMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  oneTimeSchedule: z.lazy(() => SortOrderSchema).optional(),
  cronSchedule: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  broadcastActionId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  timezone: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const ScheduleTriggerSumOrderByAggregateInputSchema: z.ZodType<Prisma.ScheduleTriggerSumOrderByAggregateInput> = z.object({
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TemplateCountOrderByAggregateInputSchema: z.ZodType<Prisma.TemplateCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  template: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TemplateMaxOrderByAggregateInputSchema: z.ZodType<Prisma.TemplateMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  template: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TemplateMinOrderByAggregateInputSchema: z.ZodType<Prisma.TemplateMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional(),
  template: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  authorId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  updatedAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FloatNullableFilterSchema: z.ZodType<Prisma.FloatNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const TranscriptionCountOrderByAggregateInputSchema: z.ZodType<Prisma.TranscriptionCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  transcriptionType: z.lazy(() => SortOrderSchema).optional(),
  transcriptionContent: z.lazy(() => SortOrderSchema).optional(),
  language: z.lazy(() => SortOrderSchema).optional(),
  translatedFrom: z.lazy(() => SortOrderSchema).optional(),
  backendModel: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  confidence: z.lazy(() => SortOrderSchema).optional(),
  executionTime: z.lazy(() => SortOrderSchema).optional(),
  format: z.lazy(() => SortOrderSchema).optional(),
  pipeline: z.lazy(() => SortOrderSchema).optional(),
  bucket: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TranscriptionAvgOrderByAggregateInputSchema: z.ZodType<Prisma.TranscriptionAvgOrderByAggregateInput> = z.object({
  priority: z.lazy(() => SortOrderSchema).optional(),
  confidence: z.lazy(() => SortOrderSchema).optional(),
  executionTime: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TranscriptionMaxOrderByAggregateInputSchema: z.ZodType<Prisma.TranscriptionMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  transcriptionType: z.lazy(() => SortOrderSchema).optional(),
  transcriptionContent: z.lazy(() => SortOrderSchema).optional(),
  language: z.lazy(() => SortOrderSchema).optional(),
  translatedFrom: z.lazy(() => SortOrderSchema).optional(),
  backendModel: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  confidence: z.lazy(() => SortOrderSchema).optional(),
  executionTime: z.lazy(() => SortOrderSchema).optional(),
  format: z.lazy(() => SortOrderSchema).optional(),
  pipeline: z.lazy(() => SortOrderSchema).optional(),
  bucket: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TranscriptionMinOrderByAggregateInputSchema: z.ZodType<Prisma.TranscriptionMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  transcriptionType: z.lazy(() => SortOrderSchema).optional(),
  transcriptionContent: z.lazy(() => SortOrderSchema).optional(),
  language: z.lazy(() => SortOrderSchema).optional(),
  translatedFrom: z.lazy(() => SortOrderSchema).optional(),
  backendModel: z.lazy(() => SortOrderSchema).optional(),
  priority: z.lazy(() => SortOrderSchema).optional(),
  confidence: z.lazy(() => SortOrderSchema).optional(),
  executionTime: z.lazy(() => SortOrderSchema).optional(),
  format: z.lazy(() => SortOrderSchema).optional(),
  pipeline: z.lazy(() => SortOrderSchema).optional(),
  bucket: z.lazy(() => SortOrderSchema).optional(),
  key: z.lazy(() => SortOrderSchema).optional(),
  url: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const TranscriptionSumOrderByAggregateInputSchema: z.ZodType<Prisma.TranscriptionSumOrderByAggregateInput> = z.object({
  priority: z.lazy(() => SortOrderSchema).optional(),
  confidence: z.lazy(() => SortOrderSchema).optional(),
  executionTime: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const FloatNullableWithAggregatesFilterSchema: z.ZodType<Prisma.FloatNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatNullableFilterSchema).optional()
}).strict();

export const WorkflowItemCountOrderByAggregateInputSchema: z.ZodType<Prisma.WorkflowItemCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  displayName: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkflowItemMaxOrderByAggregateInputSchema: z.ZodType<Prisma.WorkflowItemMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  displayName: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkflowItemMinOrderByAggregateInputSchema: z.ZodType<Prisma.WorkflowItemMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  contentId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional(),
  deletedAt: z.lazy(() => SortOrderSchema).optional(),
  displayName: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceCountOrderByAggregateInputSchema: z.ZodType<Prisma.WorkspaceCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceMaxOrderByAggregateInputSchema: z.ZodType<Prisma.WorkspaceMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceMinOrderByAggregateInputSchema: z.ZodType<Prisma.WorkspaceMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  name: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceCommandAliasCountOrderByAggregateInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  alias: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceCommandAliasMaxOrderByAggregateInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  alias: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceCommandAliasMinOrderByAggregateInputSchema: z.ZodType<Prisma.WorkspaceCommandAliasMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  alias: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  feedId: z.lazy(() => SortOrderSchema).optional(),
  workspaceMembershipId: z.lazy(() => SortOrderSchema).optional(),
  createdAt: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceMembershipWorkspaceIdAccountIdCompoundUniqueInputSchema: z.ZodType<Prisma.WorkspaceMembershipWorkspaceIdAccountIdCompoundUniqueInput> = z.object({
  workspaceId: z.string(),
  accountId: z.string()
}).strict();

export const WorkspaceMembershipCountOrderByAggregateInputSchema: z.ZodType<Prisma.WorkspaceMembershipCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceMembershipMaxOrderByAggregateInputSchema: z.ZodType<Prisma.WorkspaceMembershipMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WorkspaceMembershipMinOrderByAggregateInputSchema: z.ZodType<Prisma.WorkspaceMembershipMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  workspaceId: z.lazy(() => SortOrderSchema).optional(),
  accountId: z.lazy(() => SortOrderSchema).optional(),
  status: z.lazy(() => SortOrderSchema).optional(),
  role: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WsHandsFreeStatusCountOrderByAggregateInputSchema: z.ZodType<Prisma.WsHandsFreeStatusCountOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WsHandsFreeStatusAvgOrderByAggregateInputSchema: z.ZodType<Prisma.WsHandsFreeStatusAvgOrderByAggregateInput> = z.object({
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WsHandsFreeStatusMaxOrderByAggregateInputSchema: z.ZodType<Prisma.WsHandsFreeStatusMaxOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WsHandsFreeStatusMinOrderByAggregateInputSchema: z.ZodType<Prisma.WsHandsFreeStatusMinOrderByAggregateInput> = z.object({
  id: z.lazy(() => SortOrderSchema).optional(),
  enabled: z.lazy(() => SortOrderSchema).optional(),
  timestamp: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const WsHandsFreeStatusSumOrderByAggregateInputSchema: z.ZodType<Prisma.WsHandsFreeStatusSumOrderByAggregateInput> = z.object({
  enabled: z.lazy(() => SortOrderSchema).optional()
}).strict();

export const StringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.StringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional()
}).strict();

export const NullableStringFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableStringFieldUpdateOperationsInput> = z.object({
  set: z.string().optional().nullable()
}).strict();

export const IntFieldUpdateOperationsInputSchema: z.ZodType<Prisma.IntFieldUpdateOperationsInput> = z.object({
  set: z.number().optional(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const FloatFieldUpdateOperationsInputSchema: z.ZodType<Prisma.FloatFieldUpdateOperationsInput> = z.object({
  set: z.number().optional(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const NullableIntFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableIntFieldUpdateOperationsInput> = z.object({
  set: z.number().optional().nullable(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const NullableFloatFieldUpdateOperationsInputSchema: z.ZodType<Prisma.NullableFloatFieldUpdateOperationsInput> = z.object({
  set: z.number().optional().nullable(),
  increment: z.number().optional(),
  decrement: z.number().optional(),
  multiply: z.number().optional(),
  divide: z.number().optional()
}).strict();

export const NestedStringFilterSchema: z.ZodType<Prisma.NestedStringFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringFilterSchema) ]).optional(),
}).strict();

export const NestedStringNullableFilterSchema: z.ZodType<Prisma.NestedStringNullableFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedIntFilterSchema: z.ZodType<Prisma.NestedIntFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntFilterSchema) ]).optional(),
}).strict();

export const NestedStringWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringWithAggregatesFilter> = z.object({
  equals: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedStringFilterSchema).optional(),
  _max: z.lazy(() => NestedStringFilterSchema).optional()
}).strict();

export const NestedStringNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedStringNullableWithAggregatesFilter> = z.object({
  equals: z.string().optional().nullable(),
  in: z.string().array().optional().nullable(),
  notIn: z.string().array().optional().nullable(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  contains: z.string().optional(),
  startsWith: z.string().optional(),
  endsWith: z.string().optional(),
  not: z.union([ z.string(),z.lazy(() => NestedStringNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedStringNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedStringNullableFilterSchema).optional()
}).strict();

export const NestedIntNullableFilterSchema: z.ZodType<Prisma.NestedIntNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedIntWithAggregatesFilterSchema: z.ZodType<Prisma.NestedIntWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntFilterSchema).optional(),
  _min: z.lazy(() => NestedIntFilterSchema).optional(),
  _max: z.lazy(() => NestedIntFilterSchema).optional()
}).strict();

export const NestedFloatFilterSchema: z.ZodType<Prisma.NestedFloatFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatFilterSchema) ]).optional(),
}).strict();

export const NestedFloatWithAggregatesFilterSchema: z.ZodType<Prisma.NestedFloatWithAggregatesFilter> = z.object({
  equals: z.number().optional(),
  in: z.number().array().optional(),
  notIn: z.number().array().optional(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatWithAggregatesFilterSchema) ]).optional(),
  _count: z.lazy(() => NestedIntFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatFilterSchema).optional()
}).strict();

export const NestedIntNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedIntNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedIntNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedIntNullableFilterSchema).optional()
}).strict();

export const NestedFloatNullableFilterSchema: z.ZodType<Prisma.NestedFloatNullableFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableFilterSchema) ]).optional().nullable(),
}).strict();

export const NestedFloatNullableWithAggregatesFilterSchema: z.ZodType<Prisma.NestedFloatNullableWithAggregatesFilter> = z.object({
  equals: z.number().optional().nullable(),
  in: z.number().array().optional().nullable(),
  notIn: z.number().array().optional().nullable(),
  lt: z.number().optional(),
  lte: z.number().optional(),
  gt: z.number().optional(),
  gte: z.number().optional(),
  not: z.union([ z.number(),z.lazy(() => NestedFloatNullableWithAggregatesFilterSchema) ]).optional().nullable(),
  _count: z.lazy(() => NestedIntNullableFilterSchema).optional(),
  _avg: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _sum: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _min: z.lazy(() => NestedFloatNullableFilterSchema).optional(),
  _max: z.lazy(() => NestedFloatNullableFilterSchema).optional()
}).strict();

/////////////////////////////////////////
// ARGS
/////////////////////////////////////////

export const AccountFindFirstArgsSchema: z.ZodType<Prisma.AccountFindFirstArgs> = z.object({
  select: AccountSelectSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: AccountScalarFieldEnumSchema.array().optional(),
}).strict()

export const AccountFindManyArgsSchema: z.ZodType<Prisma.AccountFindManyArgs> = z.object({
  select: AccountSelectSchema.optional(),
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: AccountScalarFieldEnumSchema.array().optional(),
}).strict()

export const AccountAggregateArgsSchema: z.ZodType<Prisma.AccountAggregateArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithRelationInputSchema.array(),AccountOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AccountGroupByArgsSchema: z.ZodType<Prisma.AccountGroupByArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
  orderBy: z.union([ AccountOrderByWithAggregationInputSchema.array(),AccountOrderByWithAggregationInputSchema ]).optional(),
  by: AccountScalarFieldEnumSchema.array(),
  having: AccountScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AccountFindUniqueArgsSchema: z.ZodType<Prisma.AccountFindUniqueArgs> = z.object({
  select: AccountSelectSchema.optional(),
  where: AccountWhereUniqueInputSchema,
}).strict()

export const AccountEventFindFirstArgsSchema: z.ZodType<Prisma.AccountEventFindFirstArgs> = z.object({
  select: AccountEventSelectSchema.optional(),
  where: AccountEventWhereInputSchema.optional(),
  orderBy: z.union([ AccountEventOrderByWithRelationInputSchema.array(),AccountEventOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountEventWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: AccountEventScalarFieldEnumSchema.array().optional(),
}).strict()

export const AccountEventFindManyArgsSchema: z.ZodType<Prisma.AccountEventFindManyArgs> = z.object({
  select: AccountEventSelectSchema.optional(),
  where: AccountEventWhereInputSchema.optional(),
  orderBy: z.union([ AccountEventOrderByWithRelationInputSchema.array(),AccountEventOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountEventWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: AccountEventScalarFieldEnumSchema.array().optional(),
}).strict()

export const AccountEventAggregateArgsSchema: z.ZodType<Prisma.AccountEventAggregateArgs> = z.object({
  where: AccountEventWhereInputSchema.optional(),
  orderBy: z.union([ AccountEventOrderByWithRelationInputSchema.array(),AccountEventOrderByWithRelationInputSchema ]).optional(),
  cursor: AccountEventWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AccountEventGroupByArgsSchema: z.ZodType<Prisma.AccountEventGroupByArgs> = z.object({
  where: AccountEventWhereInputSchema.optional(),
  orderBy: z.union([ AccountEventOrderByWithAggregationInputSchema.array(),AccountEventOrderByWithAggregationInputSchema ]).optional(),
  by: AccountEventScalarFieldEnumSchema.array(),
  having: AccountEventScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AccountEventFindUniqueArgsSchema: z.ZodType<Prisma.AccountEventFindUniqueArgs> = z.object({
  select: AccountEventSelectSchema.optional(),
  where: AccountEventWhereUniqueInputSchema,
}).strict()

export const AppPhoneNumberFindFirstArgsSchema: z.ZodType<Prisma.AppPhoneNumberFindFirstArgs> = z.object({
  select: AppPhoneNumberSelectSchema.optional(),
  where: AppPhoneNumberWhereInputSchema.optional(),
  orderBy: z.union([ AppPhoneNumberOrderByWithRelationInputSchema.array(),AppPhoneNumberOrderByWithRelationInputSchema ]).optional(),
  cursor: AppPhoneNumberWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: AppPhoneNumberScalarFieldEnumSchema.array().optional(),
}).strict()

export const AppPhoneNumberFindManyArgsSchema: z.ZodType<Prisma.AppPhoneNumberFindManyArgs> = z.object({
  select: AppPhoneNumberSelectSchema.optional(),
  where: AppPhoneNumberWhereInputSchema.optional(),
  orderBy: z.union([ AppPhoneNumberOrderByWithRelationInputSchema.array(),AppPhoneNumberOrderByWithRelationInputSchema ]).optional(),
  cursor: AppPhoneNumberWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: AppPhoneNumberScalarFieldEnumSchema.array().optional(),
}).strict()

export const AppPhoneNumberAggregateArgsSchema: z.ZodType<Prisma.AppPhoneNumberAggregateArgs> = z.object({
  where: AppPhoneNumberWhereInputSchema.optional(),
  orderBy: z.union([ AppPhoneNumberOrderByWithRelationInputSchema.array(),AppPhoneNumberOrderByWithRelationInputSchema ]).optional(),
  cursor: AppPhoneNumberWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AppPhoneNumberGroupByArgsSchema: z.ZodType<Prisma.AppPhoneNumberGroupByArgs> = z.object({
  where: AppPhoneNumberWhereInputSchema.optional(),
  orderBy: z.union([ AppPhoneNumberOrderByWithAggregationInputSchema.array(),AppPhoneNumberOrderByWithAggregationInputSchema ]).optional(),
  by: AppPhoneNumberScalarFieldEnumSchema.array(),
  having: AppPhoneNumberScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AppPhoneNumberFindUniqueArgsSchema: z.ZodType<Prisma.AppPhoneNumberFindUniqueArgs> = z.object({
  select: AppPhoneNumberSelectSchema.optional(),
  where: AppPhoneNumberWhereUniqueInputSchema,
}).strict()

export const AudioEncodingFindFirstArgsSchema: z.ZodType<Prisma.AudioEncodingFindFirstArgs> = z.object({
  select: AudioEncodingSelectSchema.optional(),
  where: AudioEncodingWhereInputSchema.optional(),
  orderBy: z.union([ AudioEncodingOrderByWithRelationInputSchema.array(),AudioEncodingOrderByWithRelationInputSchema ]).optional(),
  cursor: AudioEncodingWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: AudioEncodingScalarFieldEnumSchema.array().optional(),
}).strict()

export const AudioEncodingFindManyArgsSchema: z.ZodType<Prisma.AudioEncodingFindManyArgs> = z.object({
  select: AudioEncodingSelectSchema.optional(),
  where: AudioEncodingWhereInputSchema.optional(),
  orderBy: z.union([ AudioEncodingOrderByWithRelationInputSchema.array(),AudioEncodingOrderByWithRelationInputSchema ]).optional(),
  cursor: AudioEncodingWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: AudioEncodingScalarFieldEnumSchema.array().optional(),
}).strict()

export const AudioEncodingAggregateArgsSchema: z.ZodType<Prisma.AudioEncodingAggregateArgs> = z.object({
  where: AudioEncodingWhereInputSchema.optional(),
  orderBy: z.union([ AudioEncodingOrderByWithRelationInputSchema.array(),AudioEncodingOrderByWithRelationInputSchema ]).optional(),
  cursor: AudioEncodingWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AudioEncodingGroupByArgsSchema: z.ZodType<Prisma.AudioEncodingGroupByArgs> = z.object({
  where: AudioEncodingWhereInputSchema.optional(),
  orderBy: z.union([ AudioEncodingOrderByWithAggregationInputSchema.array(),AudioEncodingOrderByWithAggregationInputSchema ]).optional(),
  by: AudioEncodingScalarFieldEnumSchema.array(),
  having: AudioEncodingScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AudioEncodingFindUniqueArgsSchema: z.ZodType<Prisma.AudioEncodingFindUniqueArgs> = z.object({
  select: AudioEncodingSelectSchema.optional(),
  where: AudioEncodingWhereUniqueInputSchema,
}).strict()

export const AudioQueueItemFindFirstArgsSchema: z.ZodType<Prisma.AudioQueueItemFindFirstArgs> = z.object({
  select: AudioQueueItemSelectSchema.optional(),
  where: AudioQueueItemWhereInputSchema.optional(),
  orderBy: z.union([ AudioQueueItemOrderByWithRelationInputSchema.array(),AudioQueueItemOrderByWithRelationInputSchema ]).optional(),
  cursor: AudioQueueItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: AudioQueueItemScalarFieldEnumSchema.array().optional(),
}).strict()

export const AudioQueueItemFindManyArgsSchema: z.ZodType<Prisma.AudioQueueItemFindManyArgs> = z.object({
  select: AudioQueueItemSelectSchema.optional(),
  where: AudioQueueItemWhereInputSchema.optional(),
  orderBy: z.union([ AudioQueueItemOrderByWithRelationInputSchema.array(),AudioQueueItemOrderByWithRelationInputSchema ]).optional(),
  cursor: AudioQueueItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: AudioQueueItemScalarFieldEnumSchema.array().optional(),
}).strict()

export const AudioQueueItemAggregateArgsSchema: z.ZodType<Prisma.AudioQueueItemAggregateArgs> = z.object({
  where: AudioQueueItemWhereInputSchema.optional(),
  orderBy: z.union([ AudioQueueItemOrderByWithRelationInputSchema.array(),AudioQueueItemOrderByWithRelationInputSchema ]).optional(),
  cursor: AudioQueueItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AudioQueueItemGroupByArgsSchema: z.ZodType<Prisma.AudioQueueItemGroupByArgs> = z.object({
  where: AudioQueueItemWhereInputSchema.optional(),
  orderBy: z.union([ AudioQueueItemOrderByWithAggregationInputSchema.array(),AudioQueueItemOrderByWithAggregationInputSchema ]).optional(),
  by: AudioQueueItemScalarFieldEnumSchema.array(),
  having: AudioQueueItemScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const AudioQueueItemFindUniqueArgsSchema: z.ZodType<Prisma.AudioQueueItemFindUniqueArgs> = z.object({
  select: AudioQueueItemSelectSchema.optional(),
  where: AudioQueueItemWhereUniqueInputSchema,
}).strict()

export const BroadcastFindFirstArgsSchema: z.ZodType<Prisma.BroadcastFindFirstArgs> = z.object({
  select: BroadcastSelectSchema.optional(),
  where: BroadcastWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastOrderByWithRelationInputSchema.array(),BroadcastOrderByWithRelationInputSchema ]).optional(),
  cursor: BroadcastWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: BroadcastScalarFieldEnumSchema.array().optional(),
}).strict()

export const BroadcastFindManyArgsSchema: z.ZodType<Prisma.BroadcastFindManyArgs> = z.object({
  select: BroadcastSelectSchema.optional(),
  where: BroadcastWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastOrderByWithRelationInputSchema.array(),BroadcastOrderByWithRelationInputSchema ]).optional(),
  cursor: BroadcastWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: BroadcastScalarFieldEnumSchema.array().optional(),
}).strict()

export const BroadcastAggregateArgsSchema: z.ZodType<Prisma.BroadcastAggregateArgs> = z.object({
  where: BroadcastWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastOrderByWithRelationInputSchema.array(),BroadcastOrderByWithRelationInputSchema ]).optional(),
  cursor: BroadcastWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const BroadcastGroupByArgsSchema: z.ZodType<Prisma.BroadcastGroupByArgs> = z.object({
  where: BroadcastWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastOrderByWithAggregationInputSchema.array(),BroadcastOrderByWithAggregationInputSchema ]).optional(),
  by: BroadcastScalarFieldEnumSchema.array(),
  having: BroadcastScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const BroadcastFindUniqueArgsSchema: z.ZodType<Prisma.BroadcastFindUniqueArgs> = z.object({
  select: BroadcastSelectSchema.optional(),
  where: BroadcastWhereUniqueInputSchema,
}).strict()

export const BroadcastActionFindFirstArgsSchema: z.ZodType<Prisma.BroadcastActionFindFirstArgs> = z.object({
  select: BroadcastActionSelectSchema.optional(),
  where: BroadcastActionWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastActionOrderByWithRelationInputSchema.array(),BroadcastActionOrderByWithRelationInputSchema ]).optional(),
  cursor: BroadcastActionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: BroadcastActionScalarFieldEnumSchema.array().optional(),
}).strict()

export const BroadcastActionFindManyArgsSchema: z.ZodType<Prisma.BroadcastActionFindManyArgs> = z.object({
  select: BroadcastActionSelectSchema.optional(),
  where: BroadcastActionWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastActionOrderByWithRelationInputSchema.array(),BroadcastActionOrderByWithRelationInputSchema ]).optional(),
  cursor: BroadcastActionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: BroadcastActionScalarFieldEnumSchema.array().optional(),
}).strict()

export const BroadcastActionAggregateArgsSchema: z.ZodType<Prisma.BroadcastActionAggregateArgs> = z.object({
  where: BroadcastActionWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastActionOrderByWithRelationInputSchema.array(),BroadcastActionOrderByWithRelationInputSchema ]).optional(),
  cursor: BroadcastActionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const BroadcastActionGroupByArgsSchema: z.ZodType<Prisma.BroadcastActionGroupByArgs> = z.object({
  where: BroadcastActionWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastActionOrderByWithAggregationInputSchema.array(),BroadcastActionOrderByWithAggregationInputSchema ]).optional(),
  by: BroadcastActionScalarFieldEnumSchema.array(),
  having: BroadcastActionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const BroadcastActionFindUniqueArgsSchema: z.ZodType<Prisma.BroadcastActionFindUniqueArgs> = z.object({
  select: BroadcastActionSelectSchema.optional(),
  where: BroadcastActionWhereUniqueInputSchema,
}).strict()

export const BroadcastRecipientFindFirstArgsSchema: z.ZodType<Prisma.BroadcastRecipientFindFirstArgs> = z.object({
  select: BroadcastRecipientSelectSchema.optional(),
  where: BroadcastRecipientWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastRecipientOrderByWithRelationInputSchema.array(),BroadcastRecipientOrderByWithRelationInputSchema ]).optional(),
  cursor: BroadcastRecipientWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: BroadcastRecipientScalarFieldEnumSchema.array().optional(),
}).strict()

export const BroadcastRecipientFindManyArgsSchema: z.ZodType<Prisma.BroadcastRecipientFindManyArgs> = z.object({
  select: BroadcastRecipientSelectSchema.optional(),
  where: BroadcastRecipientWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastRecipientOrderByWithRelationInputSchema.array(),BroadcastRecipientOrderByWithRelationInputSchema ]).optional(),
  cursor: BroadcastRecipientWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: BroadcastRecipientScalarFieldEnumSchema.array().optional(),
}).strict()

export const BroadcastRecipientAggregateArgsSchema: z.ZodType<Prisma.BroadcastRecipientAggregateArgs> = z.object({
  where: BroadcastRecipientWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastRecipientOrderByWithRelationInputSchema.array(),BroadcastRecipientOrderByWithRelationInputSchema ]).optional(),
  cursor: BroadcastRecipientWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const BroadcastRecipientGroupByArgsSchema: z.ZodType<Prisma.BroadcastRecipientGroupByArgs> = z.object({
  where: BroadcastRecipientWhereInputSchema.optional(),
  orderBy: z.union([ BroadcastRecipientOrderByWithAggregationInputSchema.array(),BroadcastRecipientOrderByWithAggregationInputSchema ]).optional(),
  by: BroadcastRecipientScalarFieldEnumSchema.array(),
  having: BroadcastRecipientScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const BroadcastRecipientFindUniqueArgsSchema: z.ZodType<Prisma.BroadcastRecipientFindUniqueArgs> = z.object({
  select: BroadcastRecipientSelectSchema.optional(),
  where: BroadcastRecipientWhereUniqueInputSchema,
}).strict()

export const CallRecordFindFirstArgsSchema: z.ZodType<Prisma.CallRecordFindFirstArgs> = z.object({
  select: CallRecordSelectSchema.optional(),
  where: CallRecordWhereInputSchema.optional(),
  orderBy: z.union([ CallRecordOrderByWithRelationInputSchema.array(),CallRecordOrderByWithRelationInputSchema ]).optional(),
  cursor: CallRecordWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: CallRecordScalarFieldEnumSchema.array().optional(),
}).strict()

export const CallRecordFindManyArgsSchema: z.ZodType<Prisma.CallRecordFindManyArgs> = z.object({
  select: CallRecordSelectSchema.optional(),
  where: CallRecordWhereInputSchema.optional(),
  orderBy: z.union([ CallRecordOrderByWithRelationInputSchema.array(),CallRecordOrderByWithRelationInputSchema ]).optional(),
  cursor: CallRecordWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: CallRecordScalarFieldEnumSchema.array().optional(),
}).strict()

export const CallRecordAggregateArgsSchema: z.ZodType<Prisma.CallRecordAggregateArgs> = z.object({
  where: CallRecordWhereInputSchema.optional(),
  orderBy: z.union([ CallRecordOrderByWithRelationInputSchema.array(),CallRecordOrderByWithRelationInputSchema ]).optional(),
  cursor: CallRecordWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const CallRecordGroupByArgsSchema: z.ZodType<Prisma.CallRecordGroupByArgs> = z.object({
  where: CallRecordWhereInputSchema.optional(),
  orderBy: z.union([ CallRecordOrderByWithAggregationInputSchema.array(),CallRecordOrderByWithAggregationInputSchema ]).optional(),
  by: CallRecordScalarFieldEnumSchema.array(),
  having: CallRecordScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const CallRecordFindUniqueArgsSchema: z.ZodType<Prisma.CallRecordFindUniqueArgs> = z.object({
  select: CallRecordSelectSchema.optional(),
  where: CallRecordWhereUniqueInputSchema,
}).strict()

export const DeviceRegistrationFindFirstArgsSchema: z.ZodType<Prisma.DeviceRegistrationFindFirstArgs> = z.object({
  select: DeviceRegistrationSelectSchema.optional(),
  where: DeviceRegistrationWhereInputSchema.optional(),
  orderBy: z.union([ DeviceRegistrationOrderByWithRelationInputSchema.array(),DeviceRegistrationOrderByWithRelationInputSchema ]).optional(),
  cursor: DeviceRegistrationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: DeviceRegistrationScalarFieldEnumSchema.array().optional(),
}).strict()

export const DeviceRegistrationFindManyArgsSchema: z.ZodType<Prisma.DeviceRegistrationFindManyArgs> = z.object({
  select: DeviceRegistrationSelectSchema.optional(),
  where: DeviceRegistrationWhereInputSchema.optional(),
  orderBy: z.union([ DeviceRegistrationOrderByWithRelationInputSchema.array(),DeviceRegistrationOrderByWithRelationInputSchema ]).optional(),
  cursor: DeviceRegistrationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: DeviceRegistrationScalarFieldEnumSchema.array().optional(),
}).strict()

export const DeviceRegistrationAggregateArgsSchema: z.ZodType<Prisma.DeviceRegistrationAggregateArgs> = z.object({
  where: DeviceRegistrationWhereInputSchema.optional(),
  orderBy: z.union([ DeviceRegistrationOrderByWithRelationInputSchema.array(),DeviceRegistrationOrderByWithRelationInputSchema ]).optional(),
  cursor: DeviceRegistrationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const DeviceRegistrationGroupByArgsSchema: z.ZodType<Prisma.DeviceRegistrationGroupByArgs> = z.object({
  where: DeviceRegistrationWhereInputSchema.optional(),
  orderBy: z.union([ DeviceRegistrationOrderByWithAggregationInputSchema.array(),DeviceRegistrationOrderByWithAggregationInputSchema ]).optional(),
  by: DeviceRegistrationScalarFieldEnumSchema.array(),
  having: DeviceRegistrationScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const DeviceRegistrationFindUniqueArgsSchema: z.ZodType<Prisma.DeviceRegistrationFindUniqueArgs> = z.object({
  select: DeviceRegistrationSelectSchema.optional(),
  where: DeviceRegistrationWhereUniqueInputSchema,
}).strict()

export const DirectWsInvitationFindFirstArgsSchema: z.ZodType<Prisma.DirectWsInvitationFindFirstArgs> = z.object({
  select: DirectWsInvitationSelectSchema.optional(),
  where: DirectWsInvitationWhereInputSchema.optional(),
  orderBy: z.union([ DirectWsInvitationOrderByWithRelationInputSchema.array(),DirectWsInvitationOrderByWithRelationInputSchema ]).optional(),
  cursor: DirectWsInvitationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: DirectWsInvitationScalarFieldEnumSchema.array().optional(),
}).strict()

export const DirectWsInvitationFindManyArgsSchema: z.ZodType<Prisma.DirectWsInvitationFindManyArgs> = z.object({
  select: DirectWsInvitationSelectSchema.optional(),
  where: DirectWsInvitationWhereInputSchema.optional(),
  orderBy: z.union([ DirectWsInvitationOrderByWithRelationInputSchema.array(),DirectWsInvitationOrderByWithRelationInputSchema ]).optional(),
  cursor: DirectWsInvitationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: DirectWsInvitationScalarFieldEnumSchema.array().optional(),
}).strict()

export const DirectWsInvitationAggregateArgsSchema: z.ZodType<Prisma.DirectWsInvitationAggregateArgs> = z.object({
  where: DirectWsInvitationWhereInputSchema.optional(),
  orderBy: z.union([ DirectWsInvitationOrderByWithRelationInputSchema.array(),DirectWsInvitationOrderByWithRelationInputSchema ]).optional(),
  cursor: DirectWsInvitationWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const DirectWsInvitationGroupByArgsSchema: z.ZodType<Prisma.DirectWsInvitationGroupByArgs> = z.object({
  where: DirectWsInvitationWhereInputSchema.optional(),
  orderBy: z.union([ DirectWsInvitationOrderByWithAggregationInputSchema.array(),DirectWsInvitationOrderByWithAggregationInputSchema ]).optional(),
  by: DirectWsInvitationScalarFieldEnumSchema.array(),
  having: DirectWsInvitationScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const DirectWsInvitationFindUniqueArgsSchema: z.ZodType<Prisma.DirectWsInvitationFindUniqueArgs> = z.object({
  select: DirectWsInvitationSelectSchema.optional(),
  where: DirectWsInvitationWhereUniqueInputSchema,
}).strict()

export const DisplayArtifactFindFirstArgsSchema: z.ZodType<Prisma.DisplayArtifactFindFirstArgs> = z.object({
  select: DisplayArtifactSelectSchema.optional(),
  where: DisplayArtifactWhereInputSchema.optional(),
  orderBy: z.union([ DisplayArtifactOrderByWithRelationInputSchema.array(),DisplayArtifactOrderByWithRelationInputSchema ]).optional(),
  cursor: DisplayArtifactWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: DisplayArtifactScalarFieldEnumSchema.array().optional(),
}).strict()

export const DisplayArtifactFindManyArgsSchema: z.ZodType<Prisma.DisplayArtifactFindManyArgs> = z.object({
  select: DisplayArtifactSelectSchema.optional(),
  where: DisplayArtifactWhereInputSchema.optional(),
  orderBy: z.union([ DisplayArtifactOrderByWithRelationInputSchema.array(),DisplayArtifactOrderByWithRelationInputSchema ]).optional(),
  cursor: DisplayArtifactWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: DisplayArtifactScalarFieldEnumSchema.array().optional(),
}).strict()

export const DisplayArtifactAggregateArgsSchema: z.ZodType<Prisma.DisplayArtifactAggregateArgs> = z.object({
  where: DisplayArtifactWhereInputSchema.optional(),
  orderBy: z.union([ DisplayArtifactOrderByWithRelationInputSchema.array(),DisplayArtifactOrderByWithRelationInputSchema ]).optional(),
  cursor: DisplayArtifactWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const DisplayArtifactGroupByArgsSchema: z.ZodType<Prisma.DisplayArtifactGroupByArgs> = z.object({
  where: DisplayArtifactWhereInputSchema.optional(),
  orderBy: z.union([ DisplayArtifactOrderByWithAggregationInputSchema.array(),DisplayArtifactOrderByWithAggregationInputSchema ]).optional(),
  by: DisplayArtifactScalarFieldEnumSchema.array(),
  having: DisplayArtifactScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const DisplayArtifactFindUniqueArgsSchema: z.ZodType<Prisma.DisplayArtifactFindUniqueArgs> = z.object({
  select: DisplayArtifactSelectSchema.optional(),
  where: DisplayArtifactWhereUniqueInputSchema,
}).strict()

export const FeedFindFirstArgsSchema: z.ZodType<Prisma.FeedFindFirstArgs> = z.object({
  select: FeedSelectSchema.optional(),
  where: FeedWhereInputSchema.optional(),
  orderBy: z.union([ FeedOrderByWithRelationInputSchema.array(),FeedOrderByWithRelationInputSchema ]).optional(),
  cursor: FeedWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: FeedScalarFieldEnumSchema.array().optional(),
}).strict()

export const FeedFindManyArgsSchema: z.ZodType<Prisma.FeedFindManyArgs> = z.object({
  select: FeedSelectSchema.optional(),
  where: FeedWhereInputSchema.optional(),
  orderBy: z.union([ FeedOrderByWithRelationInputSchema.array(),FeedOrderByWithRelationInputSchema ]).optional(),
  cursor: FeedWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: FeedScalarFieldEnumSchema.array().optional(),
}).strict()

export const FeedAggregateArgsSchema: z.ZodType<Prisma.FeedAggregateArgs> = z.object({
  where: FeedWhereInputSchema.optional(),
  orderBy: z.union([ FeedOrderByWithRelationInputSchema.array(),FeedOrderByWithRelationInputSchema ]).optional(),
  cursor: FeedWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const FeedGroupByArgsSchema: z.ZodType<Prisma.FeedGroupByArgs> = z.object({
  where: FeedWhereInputSchema.optional(),
  orderBy: z.union([ FeedOrderByWithAggregationInputSchema.array(),FeedOrderByWithAggregationInputSchema ]).optional(),
  by: FeedScalarFieldEnumSchema.array(),
  having: FeedScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const FeedFindUniqueArgsSchema: z.ZodType<Prisma.FeedFindUniqueArgs> = z.object({
  select: FeedSelectSchema.optional(),
  where: FeedWhereUniqueInputSchema,
}).strict()

export const FeedGroupFindFirstArgsSchema: z.ZodType<Prisma.FeedGroupFindFirstArgs> = z.object({
  select: FeedGroupSelectSchema.optional(),
  where: FeedGroupWhereInputSchema.optional(),
  orderBy: z.union([ FeedGroupOrderByWithRelationInputSchema.array(),FeedGroupOrderByWithRelationInputSchema ]).optional(),
  cursor: FeedGroupWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: FeedGroupScalarFieldEnumSchema.array().optional(),
}).strict()

export const FeedGroupFindManyArgsSchema: z.ZodType<Prisma.FeedGroupFindManyArgs> = z.object({
  select: FeedGroupSelectSchema.optional(),
  where: FeedGroupWhereInputSchema.optional(),
  orderBy: z.union([ FeedGroupOrderByWithRelationInputSchema.array(),FeedGroupOrderByWithRelationInputSchema ]).optional(),
  cursor: FeedGroupWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: FeedGroupScalarFieldEnumSchema.array().optional(),
}).strict()

export const FeedGroupAggregateArgsSchema: z.ZodType<Prisma.FeedGroupAggregateArgs> = z.object({
  where: FeedGroupWhereInputSchema.optional(),
  orderBy: z.union([ FeedGroupOrderByWithRelationInputSchema.array(),FeedGroupOrderByWithRelationInputSchema ]).optional(),
  cursor: FeedGroupWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const FeedGroupGroupByArgsSchema: z.ZodType<Prisma.FeedGroupGroupByArgs> = z.object({
  where: FeedGroupWhereInputSchema.optional(),
  orderBy: z.union([ FeedGroupOrderByWithAggregationInputSchema.array(),FeedGroupOrderByWithAggregationInputSchema ]).optional(),
  by: FeedGroupScalarFieldEnumSchema.array(),
  having: FeedGroupScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const FeedGroupFindUniqueArgsSchema: z.ZodType<Prisma.FeedGroupFindUniqueArgs> = z.object({
  select: FeedGroupSelectSchema.optional(),
  where: FeedGroupWhereUniqueInputSchema,
}).strict()

export const FeedGroupMembershipFindFirstArgsSchema: z.ZodType<Prisma.FeedGroupMembershipFindFirstArgs> = z.object({
  select: FeedGroupMembershipSelectSchema.optional(),
  where: FeedGroupMembershipWhereInputSchema.optional(),
  orderBy: z.union([ FeedGroupMembershipOrderByWithRelationInputSchema.array(),FeedGroupMembershipOrderByWithRelationInputSchema ]).optional(),
  cursor: FeedGroupMembershipWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: FeedGroupMembershipScalarFieldEnumSchema.array().optional(),
}).strict()

export const FeedGroupMembershipFindManyArgsSchema: z.ZodType<Prisma.FeedGroupMembershipFindManyArgs> = z.object({
  select: FeedGroupMembershipSelectSchema.optional(),
  where: FeedGroupMembershipWhereInputSchema.optional(),
  orderBy: z.union([ FeedGroupMembershipOrderByWithRelationInputSchema.array(),FeedGroupMembershipOrderByWithRelationInputSchema ]).optional(),
  cursor: FeedGroupMembershipWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: FeedGroupMembershipScalarFieldEnumSchema.array().optional(),
}).strict()

export const FeedGroupMembershipAggregateArgsSchema: z.ZodType<Prisma.FeedGroupMembershipAggregateArgs> = z.object({
  where: FeedGroupMembershipWhereInputSchema.optional(),
  orderBy: z.union([ FeedGroupMembershipOrderByWithRelationInputSchema.array(),FeedGroupMembershipOrderByWithRelationInputSchema ]).optional(),
  cursor: FeedGroupMembershipWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const FeedGroupMembershipGroupByArgsSchema: z.ZodType<Prisma.FeedGroupMembershipGroupByArgs> = z.object({
  where: FeedGroupMembershipWhereInputSchema.optional(),
  orderBy: z.union([ FeedGroupMembershipOrderByWithAggregationInputSchema.array(),FeedGroupMembershipOrderByWithAggregationInputSchema ]).optional(),
  by: FeedGroupMembershipScalarFieldEnumSchema.array(),
  having: FeedGroupMembershipScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const FeedGroupMembershipFindUniqueArgsSchema: z.ZodType<Prisma.FeedGroupMembershipFindUniqueArgs> = z.object({
  select: FeedGroupMembershipSelectSchema.optional(),
  where: FeedGroupMembershipWhereUniqueInputSchema,
}).strict()

export const FileFindFirstArgsSchema: z.ZodType<Prisma.FileFindFirstArgs> = z.object({
  select: FileSelectSchema.optional(),
  where: FileWhereInputSchema.optional(),
  orderBy: z.union([ FileOrderByWithRelationInputSchema.array(),FileOrderByWithRelationInputSchema ]).optional(),
  cursor: FileWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: FileScalarFieldEnumSchema.array().optional(),
}).strict()

export const FileFindManyArgsSchema: z.ZodType<Prisma.FileFindManyArgs> = z.object({
  select: FileSelectSchema.optional(),
  where: FileWhereInputSchema.optional(),
  orderBy: z.union([ FileOrderByWithRelationInputSchema.array(),FileOrderByWithRelationInputSchema ]).optional(),
  cursor: FileWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: FileScalarFieldEnumSchema.array().optional(),
}).strict()

export const FileAggregateArgsSchema: z.ZodType<Prisma.FileAggregateArgs> = z.object({
  where: FileWhereInputSchema.optional(),
  orderBy: z.union([ FileOrderByWithRelationInputSchema.array(),FileOrderByWithRelationInputSchema ]).optional(),
  cursor: FileWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const FileGroupByArgsSchema: z.ZodType<Prisma.FileGroupByArgs> = z.object({
  where: FileWhereInputSchema.optional(),
  orderBy: z.union([ FileOrderByWithAggregationInputSchema.array(),FileOrderByWithAggregationInputSchema ]).optional(),
  by: FileScalarFieldEnumSchema.array(),
  having: FileScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const FileFindUniqueArgsSchema: z.ZodType<Prisma.FileFindUniqueArgs> = z.object({
  select: FileSelectSchema.optional(),
  where: FileWhereUniqueInputSchema,
}).strict()

export const ItemFindFirstArgsSchema: z.ZodType<Prisma.ItemFindFirstArgs> = z.object({
  select: ItemSelectSchema.optional(),
  where: ItemWhereInputSchema.optional(),
  orderBy: z.union([ ItemOrderByWithRelationInputSchema.array(),ItemOrderByWithRelationInputSchema ]).optional(),
  cursor: ItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: ItemScalarFieldEnumSchema.array().optional(),
}).strict()

export const ItemFindManyArgsSchema: z.ZodType<Prisma.ItemFindManyArgs> = z.object({
  select: ItemSelectSchema.optional(),
  where: ItemWhereInputSchema.optional(),
  orderBy: z.union([ ItemOrderByWithRelationInputSchema.array(),ItemOrderByWithRelationInputSchema ]).optional(),
  cursor: ItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: ItemScalarFieldEnumSchema.array().optional(),
}).strict()

export const ItemAggregateArgsSchema: z.ZodType<Prisma.ItemAggregateArgs> = z.object({
  where: ItemWhereInputSchema.optional(),
  orderBy: z.union([ ItemOrderByWithRelationInputSchema.array(),ItemOrderByWithRelationInputSchema ]).optional(),
  cursor: ItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const ItemGroupByArgsSchema: z.ZodType<Prisma.ItemGroupByArgs> = z.object({
  where: ItemWhereInputSchema.optional(),
  orderBy: z.union([ ItemOrderByWithAggregationInputSchema.array(),ItemOrderByWithAggregationInputSchema ]).optional(),
  by: ItemScalarFieldEnumSchema.array(),
  having: ItemScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const ItemFindUniqueArgsSchema: z.ZodType<Prisma.ItemFindUniqueArgs> = z.object({
  select: ItemSelectSchema.optional(),
  where: ItemWhereUniqueInputSchema,
}).strict()

export const LinkFindFirstArgsSchema: z.ZodType<Prisma.LinkFindFirstArgs> = z.object({
  select: LinkSelectSchema.optional(),
  where: LinkWhereInputSchema.optional(),
  orderBy: z.union([ LinkOrderByWithRelationInputSchema.array(),LinkOrderByWithRelationInputSchema ]).optional(),
  cursor: LinkWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: LinkScalarFieldEnumSchema.array().optional(),
}).strict()

export const LinkFindManyArgsSchema: z.ZodType<Prisma.LinkFindManyArgs> = z.object({
  select: LinkSelectSchema.optional(),
  where: LinkWhereInputSchema.optional(),
  orderBy: z.union([ LinkOrderByWithRelationInputSchema.array(),LinkOrderByWithRelationInputSchema ]).optional(),
  cursor: LinkWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: LinkScalarFieldEnumSchema.array().optional(),
}).strict()

export const LinkAggregateArgsSchema: z.ZodType<Prisma.LinkAggregateArgs> = z.object({
  where: LinkWhereInputSchema.optional(),
  orderBy: z.union([ LinkOrderByWithRelationInputSchema.array(),LinkOrderByWithRelationInputSchema ]).optional(),
  cursor: LinkWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const LinkGroupByArgsSchema: z.ZodType<Prisma.LinkGroupByArgs> = z.object({
  where: LinkWhereInputSchema.optional(),
  orderBy: z.union([ LinkOrderByWithAggregationInputSchema.array(),LinkOrderByWithAggregationInputSchema ]).optional(),
  by: LinkScalarFieldEnumSchema.array(),
  having: LinkScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const LinkFindUniqueArgsSchema: z.ZodType<Prisma.LinkFindUniqueArgs> = z.object({
  select: LinkSelectSchema.optional(),
  where: LinkWhereUniqueInputSchema,
}).strict()

export const PermissionFindFirstArgsSchema: z.ZodType<Prisma.PermissionFindFirstArgs> = z.object({
  select: PermissionSelectSchema.optional(),
  where: PermissionWhereInputSchema.optional(),
  orderBy: z.union([ PermissionOrderByWithRelationInputSchema.array(),PermissionOrderByWithRelationInputSchema ]).optional(),
  cursor: PermissionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: PermissionScalarFieldEnumSchema.array().optional(),
}).strict()

export const PermissionFindManyArgsSchema: z.ZodType<Prisma.PermissionFindManyArgs> = z.object({
  select: PermissionSelectSchema.optional(),
  where: PermissionWhereInputSchema.optional(),
  orderBy: z.union([ PermissionOrderByWithRelationInputSchema.array(),PermissionOrderByWithRelationInputSchema ]).optional(),
  cursor: PermissionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: PermissionScalarFieldEnumSchema.array().optional(),
}).strict()

export const PermissionAggregateArgsSchema: z.ZodType<Prisma.PermissionAggregateArgs> = z.object({
  where: PermissionWhereInputSchema.optional(),
  orderBy: z.union([ PermissionOrderByWithRelationInputSchema.array(),PermissionOrderByWithRelationInputSchema ]).optional(),
  cursor: PermissionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const PermissionGroupByArgsSchema: z.ZodType<Prisma.PermissionGroupByArgs> = z.object({
  where: PermissionWhereInputSchema.optional(),
  orderBy: z.union([ PermissionOrderByWithAggregationInputSchema.array(),PermissionOrderByWithAggregationInputSchema ]).optional(),
  by: PermissionScalarFieldEnumSchema.array(),
  having: PermissionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const PermissionFindUniqueArgsSchema: z.ZodType<Prisma.PermissionFindUniqueArgs> = z.object({
  select: PermissionSelectSchema.optional(),
  where: PermissionWhereUniqueInputSchema,
}).strict()

export const PipelineArtifactMetadataFindFirstArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataFindFirstArgs> = z.object({
  select: PipelineArtifactMetadataSelectSchema.optional(),
  where: PipelineArtifactMetadataWhereInputSchema.optional(),
  orderBy: z.union([ PipelineArtifactMetadataOrderByWithRelationInputSchema.array(),PipelineArtifactMetadataOrderByWithRelationInputSchema ]).optional(),
  cursor: PipelineArtifactMetadataWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: PipelineArtifactMetadataScalarFieldEnumSchema.array().optional(),
}).strict()

export const PipelineArtifactMetadataFindManyArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataFindManyArgs> = z.object({
  select: PipelineArtifactMetadataSelectSchema.optional(),
  where: PipelineArtifactMetadataWhereInputSchema.optional(),
  orderBy: z.union([ PipelineArtifactMetadataOrderByWithRelationInputSchema.array(),PipelineArtifactMetadataOrderByWithRelationInputSchema ]).optional(),
  cursor: PipelineArtifactMetadataWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: PipelineArtifactMetadataScalarFieldEnumSchema.array().optional(),
}).strict()

export const PipelineArtifactMetadataAggregateArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataAggregateArgs> = z.object({
  where: PipelineArtifactMetadataWhereInputSchema.optional(),
  orderBy: z.union([ PipelineArtifactMetadataOrderByWithRelationInputSchema.array(),PipelineArtifactMetadataOrderByWithRelationInputSchema ]).optional(),
  cursor: PipelineArtifactMetadataWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const PipelineArtifactMetadataGroupByArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataGroupByArgs> = z.object({
  where: PipelineArtifactMetadataWhereInputSchema.optional(),
  orderBy: z.union([ PipelineArtifactMetadataOrderByWithAggregationInputSchema.array(),PipelineArtifactMetadataOrderByWithAggregationInputSchema ]).optional(),
  by: PipelineArtifactMetadataScalarFieldEnumSchema.array(),
  having: PipelineArtifactMetadataScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const PipelineArtifactMetadataFindUniqueArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataFindUniqueArgs> = z.object({
  select: PipelineArtifactMetadataSelectSchema.optional(),
  where: PipelineArtifactMetadataWhereUniqueInputSchema,
}).strict()

export const PublishedWorkflowItemFindFirstArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemFindFirstArgs> = z.object({
  select: PublishedWorkflowItemSelectSchema.optional(),
  where: PublishedWorkflowItemWhereInputSchema.optional(),
  orderBy: z.union([ PublishedWorkflowItemOrderByWithRelationInputSchema.array(),PublishedWorkflowItemOrderByWithRelationInputSchema ]).optional(),
  cursor: PublishedWorkflowItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: PublishedWorkflowItemScalarFieldEnumSchema.array().optional(),
}).strict()

export const PublishedWorkflowItemFindManyArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemFindManyArgs> = z.object({
  select: PublishedWorkflowItemSelectSchema.optional(),
  where: PublishedWorkflowItemWhereInputSchema.optional(),
  orderBy: z.union([ PublishedWorkflowItemOrderByWithRelationInputSchema.array(),PublishedWorkflowItemOrderByWithRelationInputSchema ]).optional(),
  cursor: PublishedWorkflowItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: PublishedWorkflowItemScalarFieldEnumSchema.array().optional(),
}).strict()

export const PublishedWorkflowItemAggregateArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemAggregateArgs> = z.object({
  where: PublishedWorkflowItemWhereInputSchema.optional(),
  orderBy: z.union([ PublishedWorkflowItemOrderByWithRelationInputSchema.array(),PublishedWorkflowItemOrderByWithRelationInputSchema ]).optional(),
  cursor: PublishedWorkflowItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const PublishedWorkflowItemGroupByArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemGroupByArgs> = z.object({
  where: PublishedWorkflowItemWhereInputSchema.optional(),
  orderBy: z.union([ PublishedWorkflowItemOrderByWithAggregationInputSchema.array(),PublishedWorkflowItemOrderByWithAggregationInputSchema ]).optional(),
  by: PublishedWorkflowItemScalarFieldEnumSchema.array(),
  having: PublishedWorkflowItemScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const PublishedWorkflowItemFindUniqueArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemFindUniqueArgs> = z.object({
  select: PublishedWorkflowItemSelectSchema.optional(),
  where: PublishedWorkflowItemWhereUniqueInputSchema,
}).strict()

export const ScheduleTriggerFindFirstArgsSchema: z.ZodType<Prisma.ScheduleTriggerFindFirstArgs> = z.object({
  select: ScheduleTriggerSelectSchema.optional(),
  where: ScheduleTriggerWhereInputSchema.optional(),
  orderBy: z.union([ ScheduleTriggerOrderByWithRelationInputSchema.array(),ScheduleTriggerOrderByWithRelationInputSchema ]).optional(),
  cursor: ScheduleTriggerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: ScheduleTriggerScalarFieldEnumSchema.array().optional(),
}).strict()

export const ScheduleTriggerFindManyArgsSchema: z.ZodType<Prisma.ScheduleTriggerFindManyArgs> = z.object({
  select: ScheduleTriggerSelectSchema.optional(),
  where: ScheduleTriggerWhereInputSchema.optional(),
  orderBy: z.union([ ScheduleTriggerOrderByWithRelationInputSchema.array(),ScheduleTriggerOrderByWithRelationInputSchema ]).optional(),
  cursor: ScheduleTriggerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: ScheduleTriggerScalarFieldEnumSchema.array().optional(),
}).strict()

export const ScheduleTriggerAggregateArgsSchema: z.ZodType<Prisma.ScheduleTriggerAggregateArgs> = z.object({
  where: ScheduleTriggerWhereInputSchema.optional(),
  orderBy: z.union([ ScheduleTriggerOrderByWithRelationInputSchema.array(),ScheduleTriggerOrderByWithRelationInputSchema ]).optional(),
  cursor: ScheduleTriggerWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const ScheduleTriggerGroupByArgsSchema: z.ZodType<Prisma.ScheduleTriggerGroupByArgs> = z.object({
  where: ScheduleTriggerWhereInputSchema.optional(),
  orderBy: z.union([ ScheduleTriggerOrderByWithAggregationInputSchema.array(),ScheduleTriggerOrderByWithAggregationInputSchema ]).optional(),
  by: ScheduleTriggerScalarFieldEnumSchema.array(),
  having: ScheduleTriggerScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const ScheduleTriggerFindUniqueArgsSchema: z.ZodType<Prisma.ScheduleTriggerFindUniqueArgs> = z.object({
  select: ScheduleTriggerSelectSchema.optional(),
  where: ScheduleTriggerWhereUniqueInputSchema,
}).strict()

export const TemplateFindFirstArgsSchema: z.ZodType<Prisma.TemplateFindFirstArgs> = z.object({
  select: TemplateSelectSchema.optional(),
  where: TemplateWhereInputSchema.optional(),
  orderBy: z.union([ TemplateOrderByWithRelationInputSchema.array(),TemplateOrderByWithRelationInputSchema ]).optional(),
  cursor: TemplateWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: TemplateScalarFieldEnumSchema.array().optional(),
}).strict()

export const TemplateFindManyArgsSchema: z.ZodType<Prisma.TemplateFindManyArgs> = z.object({
  select: TemplateSelectSchema.optional(),
  where: TemplateWhereInputSchema.optional(),
  orderBy: z.union([ TemplateOrderByWithRelationInputSchema.array(),TemplateOrderByWithRelationInputSchema ]).optional(),
  cursor: TemplateWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: TemplateScalarFieldEnumSchema.array().optional(),
}).strict()

export const TemplateAggregateArgsSchema: z.ZodType<Prisma.TemplateAggregateArgs> = z.object({
  where: TemplateWhereInputSchema.optional(),
  orderBy: z.union([ TemplateOrderByWithRelationInputSchema.array(),TemplateOrderByWithRelationInputSchema ]).optional(),
  cursor: TemplateWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const TemplateGroupByArgsSchema: z.ZodType<Prisma.TemplateGroupByArgs> = z.object({
  where: TemplateWhereInputSchema.optional(),
  orderBy: z.union([ TemplateOrderByWithAggregationInputSchema.array(),TemplateOrderByWithAggregationInputSchema ]).optional(),
  by: TemplateScalarFieldEnumSchema.array(),
  having: TemplateScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const TemplateFindUniqueArgsSchema: z.ZodType<Prisma.TemplateFindUniqueArgs> = z.object({
  select: TemplateSelectSchema.optional(),
  where: TemplateWhereUniqueInputSchema,
}).strict()

export const TranscriptionFindFirstArgsSchema: z.ZodType<Prisma.TranscriptionFindFirstArgs> = z.object({
  select: TranscriptionSelectSchema.optional(),
  where: TranscriptionWhereInputSchema.optional(),
  orderBy: z.union([ TranscriptionOrderByWithRelationInputSchema.array(),TranscriptionOrderByWithRelationInputSchema ]).optional(),
  cursor: TranscriptionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: TranscriptionScalarFieldEnumSchema.array().optional(),
}).strict()

export const TranscriptionFindManyArgsSchema: z.ZodType<Prisma.TranscriptionFindManyArgs> = z.object({
  select: TranscriptionSelectSchema.optional(),
  where: TranscriptionWhereInputSchema.optional(),
  orderBy: z.union([ TranscriptionOrderByWithRelationInputSchema.array(),TranscriptionOrderByWithRelationInputSchema ]).optional(),
  cursor: TranscriptionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: TranscriptionScalarFieldEnumSchema.array().optional(),
}).strict()

export const TranscriptionAggregateArgsSchema: z.ZodType<Prisma.TranscriptionAggregateArgs> = z.object({
  where: TranscriptionWhereInputSchema.optional(),
  orderBy: z.union([ TranscriptionOrderByWithRelationInputSchema.array(),TranscriptionOrderByWithRelationInputSchema ]).optional(),
  cursor: TranscriptionWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const TranscriptionGroupByArgsSchema: z.ZodType<Prisma.TranscriptionGroupByArgs> = z.object({
  where: TranscriptionWhereInputSchema.optional(),
  orderBy: z.union([ TranscriptionOrderByWithAggregationInputSchema.array(),TranscriptionOrderByWithAggregationInputSchema ]).optional(),
  by: TranscriptionScalarFieldEnumSchema.array(),
  having: TranscriptionScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const TranscriptionFindUniqueArgsSchema: z.ZodType<Prisma.TranscriptionFindUniqueArgs> = z.object({
  select: TranscriptionSelectSchema.optional(),
  where: TranscriptionWhereUniqueInputSchema,
}).strict()

export const WorkflowItemFindFirstArgsSchema: z.ZodType<Prisma.WorkflowItemFindFirstArgs> = z.object({
  select: WorkflowItemSelectSchema.optional(),
  where: WorkflowItemWhereInputSchema.optional(),
  orderBy: z.union([ WorkflowItemOrderByWithRelationInputSchema.array(),WorkflowItemOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkflowItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: WorkflowItemScalarFieldEnumSchema.array().optional(),
}).strict()

export const WorkflowItemFindManyArgsSchema: z.ZodType<Prisma.WorkflowItemFindManyArgs> = z.object({
  select: WorkflowItemSelectSchema.optional(),
  where: WorkflowItemWhereInputSchema.optional(),
  orderBy: z.union([ WorkflowItemOrderByWithRelationInputSchema.array(),WorkflowItemOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkflowItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: WorkflowItemScalarFieldEnumSchema.array().optional(),
}).strict()

export const WorkflowItemAggregateArgsSchema: z.ZodType<Prisma.WorkflowItemAggregateArgs> = z.object({
  where: WorkflowItemWhereInputSchema.optional(),
  orderBy: z.union([ WorkflowItemOrderByWithRelationInputSchema.array(),WorkflowItemOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkflowItemWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const WorkflowItemGroupByArgsSchema: z.ZodType<Prisma.WorkflowItemGroupByArgs> = z.object({
  where: WorkflowItemWhereInputSchema.optional(),
  orderBy: z.union([ WorkflowItemOrderByWithAggregationInputSchema.array(),WorkflowItemOrderByWithAggregationInputSchema ]).optional(),
  by: WorkflowItemScalarFieldEnumSchema.array(),
  having: WorkflowItemScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const WorkflowItemFindUniqueArgsSchema: z.ZodType<Prisma.WorkflowItemFindUniqueArgs> = z.object({
  select: WorkflowItemSelectSchema.optional(),
  where: WorkflowItemWhereUniqueInputSchema,
}).strict()

export const WorkspaceFindFirstArgsSchema: z.ZodType<Prisma.WorkspaceFindFirstArgs> = z.object({
  select: WorkspaceSelectSchema.optional(),
  where: WorkspaceWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceOrderByWithRelationInputSchema.array(),WorkspaceOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkspaceWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: WorkspaceScalarFieldEnumSchema.array().optional(),
}).strict()

export const WorkspaceFindManyArgsSchema: z.ZodType<Prisma.WorkspaceFindManyArgs> = z.object({
  select: WorkspaceSelectSchema.optional(),
  where: WorkspaceWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceOrderByWithRelationInputSchema.array(),WorkspaceOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkspaceWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: WorkspaceScalarFieldEnumSchema.array().optional(),
}).strict()

export const WorkspaceAggregateArgsSchema: z.ZodType<Prisma.WorkspaceAggregateArgs> = z.object({
  where: WorkspaceWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceOrderByWithRelationInputSchema.array(),WorkspaceOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkspaceWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const WorkspaceGroupByArgsSchema: z.ZodType<Prisma.WorkspaceGroupByArgs> = z.object({
  where: WorkspaceWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceOrderByWithAggregationInputSchema.array(),WorkspaceOrderByWithAggregationInputSchema ]).optional(),
  by: WorkspaceScalarFieldEnumSchema.array(),
  having: WorkspaceScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const WorkspaceFindUniqueArgsSchema: z.ZodType<Prisma.WorkspaceFindUniqueArgs> = z.object({
  select: WorkspaceSelectSchema.optional(),
  where: WorkspaceWhereUniqueInputSchema,
}).strict()

export const WorkspaceCommandAliasFindFirstArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasFindFirstArgs> = z.object({
  select: WorkspaceCommandAliasSelectSchema.optional(),
  where: WorkspaceCommandAliasWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceCommandAliasOrderByWithRelationInputSchema.array(),WorkspaceCommandAliasOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkspaceCommandAliasWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: WorkspaceCommandAliasScalarFieldEnumSchema.array().optional(),
}).strict()

export const WorkspaceCommandAliasFindManyArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasFindManyArgs> = z.object({
  select: WorkspaceCommandAliasSelectSchema.optional(),
  where: WorkspaceCommandAliasWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceCommandAliasOrderByWithRelationInputSchema.array(),WorkspaceCommandAliasOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkspaceCommandAliasWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: WorkspaceCommandAliasScalarFieldEnumSchema.array().optional(),
}).strict()

export const WorkspaceCommandAliasAggregateArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasAggregateArgs> = z.object({
  where: WorkspaceCommandAliasWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceCommandAliasOrderByWithRelationInputSchema.array(),WorkspaceCommandAliasOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkspaceCommandAliasWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const WorkspaceCommandAliasGroupByArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasGroupByArgs> = z.object({
  where: WorkspaceCommandAliasWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceCommandAliasOrderByWithAggregationInputSchema.array(),WorkspaceCommandAliasOrderByWithAggregationInputSchema ]).optional(),
  by: WorkspaceCommandAliasScalarFieldEnumSchema.array(),
  having: WorkspaceCommandAliasScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const WorkspaceCommandAliasFindUniqueArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasFindUniqueArgs> = z.object({
  select: WorkspaceCommandAliasSelectSchema.optional(),
  where: WorkspaceCommandAliasWhereUniqueInputSchema,
}).strict()

export const WorkspaceMembershipFindFirstArgsSchema: z.ZodType<Prisma.WorkspaceMembershipFindFirstArgs> = z.object({
  select: WorkspaceMembershipSelectSchema.optional(),
  where: WorkspaceMembershipWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceMembershipOrderByWithRelationInputSchema.array(),WorkspaceMembershipOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkspaceMembershipWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: WorkspaceMembershipScalarFieldEnumSchema.array().optional(),
}).strict()

export const WorkspaceMembershipFindManyArgsSchema: z.ZodType<Prisma.WorkspaceMembershipFindManyArgs> = z.object({
  select: WorkspaceMembershipSelectSchema.optional(),
  where: WorkspaceMembershipWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceMembershipOrderByWithRelationInputSchema.array(),WorkspaceMembershipOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkspaceMembershipWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: WorkspaceMembershipScalarFieldEnumSchema.array().optional(),
}).strict()

export const WorkspaceMembershipAggregateArgsSchema: z.ZodType<Prisma.WorkspaceMembershipAggregateArgs> = z.object({
  where: WorkspaceMembershipWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceMembershipOrderByWithRelationInputSchema.array(),WorkspaceMembershipOrderByWithRelationInputSchema ]).optional(),
  cursor: WorkspaceMembershipWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const WorkspaceMembershipGroupByArgsSchema: z.ZodType<Prisma.WorkspaceMembershipGroupByArgs> = z.object({
  where: WorkspaceMembershipWhereInputSchema.optional(),
  orderBy: z.union([ WorkspaceMembershipOrderByWithAggregationInputSchema.array(),WorkspaceMembershipOrderByWithAggregationInputSchema ]).optional(),
  by: WorkspaceMembershipScalarFieldEnumSchema.array(),
  having: WorkspaceMembershipScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const WorkspaceMembershipFindUniqueArgsSchema: z.ZodType<Prisma.WorkspaceMembershipFindUniqueArgs> = z.object({
  select: WorkspaceMembershipSelectSchema.optional(),
  where: WorkspaceMembershipWhereUniqueInputSchema,
}).strict()

export const WsHandsFreeStatusFindFirstArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusFindFirstArgs> = z.object({
  select: WsHandsFreeStatusSelectSchema.optional(),
  where: WsHandsFreeStatusWhereInputSchema.optional(),
  orderBy: z.union([ WsHandsFreeStatusOrderByWithRelationInputSchema.array(),WsHandsFreeStatusOrderByWithRelationInputSchema ]).optional(),
  cursor: WsHandsFreeStatusWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: WsHandsFreeStatusScalarFieldEnumSchema.array().optional(),
}).strict()

export const WsHandsFreeStatusFindManyArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusFindManyArgs> = z.object({
  select: WsHandsFreeStatusSelectSchema.optional(),
  where: WsHandsFreeStatusWhereInputSchema.optional(),
  orderBy: z.union([ WsHandsFreeStatusOrderByWithRelationInputSchema.array(),WsHandsFreeStatusOrderByWithRelationInputSchema ]).optional(),
  cursor: WsHandsFreeStatusWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: WsHandsFreeStatusScalarFieldEnumSchema.array().optional(),
}).strict()

export const WsHandsFreeStatusAggregateArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusAggregateArgs> = z.object({
  where: WsHandsFreeStatusWhereInputSchema.optional(),
  orderBy: z.union([ WsHandsFreeStatusOrderByWithRelationInputSchema.array(),WsHandsFreeStatusOrderByWithRelationInputSchema ]).optional(),
  cursor: WsHandsFreeStatusWhereUniqueInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const WsHandsFreeStatusGroupByArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusGroupByArgs> = z.object({
  where: WsHandsFreeStatusWhereInputSchema.optional(),
  orderBy: z.union([ WsHandsFreeStatusOrderByWithAggregationInputSchema.array(),WsHandsFreeStatusOrderByWithAggregationInputSchema ]).optional(),
  by: WsHandsFreeStatusScalarFieldEnumSchema.array(),
  having: WsHandsFreeStatusScalarWhereWithAggregatesInputSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
}).strict()

export const WsHandsFreeStatusFindUniqueArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusFindUniqueArgs> = z.object({
  select: WsHandsFreeStatusSelectSchema.optional(),
  where: WsHandsFreeStatusWhereUniqueInputSchema,
}).strict()

export const AccountCreateArgsSchema: z.ZodType<Prisma.AccountCreateArgs> = z.object({
  select: AccountSelectSchema.optional(),
  data: z.union([ AccountCreateInputSchema,AccountUncheckedCreateInputSchema ]),
}).strict()

export const AccountUpsertArgsSchema: z.ZodType<Prisma.AccountUpsertArgs> = z.object({
  select: AccountSelectSchema.optional(),
  where: AccountWhereUniqueInputSchema,
  create: z.union([ AccountCreateInputSchema,AccountUncheckedCreateInputSchema ]),
  update: z.union([ AccountUpdateInputSchema,AccountUncheckedUpdateInputSchema ]),
}).strict()

export const AccountCreateManyArgsSchema: z.ZodType<Prisma.AccountCreateManyArgs> = z.object({
  data: AccountCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const AccountDeleteArgsSchema: z.ZodType<Prisma.AccountDeleteArgs> = z.object({
  select: AccountSelectSchema.optional(),
  where: AccountWhereUniqueInputSchema,
}).strict()

export const AccountUpdateArgsSchema: z.ZodType<Prisma.AccountUpdateArgs> = z.object({
  select: AccountSelectSchema.optional(),
  data: z.union([ AccountUpdateInputSchema,AccountUncheckedUpdateInputSchema ]),
  where: AccountWhereUniqueInputSchema,
}).strict()

export const AccountUpdateManyArgsSchema: z.ZodType<Prisma.AccountUpdateManyArgs> = z.object({
  data: z.union([ AccountUpdateManyMutationInputSchema,AccountUncheckedUpdateManyInputSchema ]),
  where: AccountWhereInputSchema.optional(),
}).strict()

export const AccountDeleteManyArgsSchema: z.ZodType<Prisma.AccountDeleteManyArgs> = z.object({
  where: AccountWhereInputSchema.optional(),
}).strict()

export const AccountEventCreateArgsSchema: z.ZodType<Prisma.AccountEventCreateArgs> = z.object({
  select: AccountEventSelectSchema.optional(),
  data: z.union([ AccountEventCreateInputSchema,AccountEventUncheckedCreateInputSchema ]),
}).strict()

export const AccountEventUpsertArgsSchema: z.ZodType<Prisma.AccountEventUpsertArgs> = z.object({
  select: AccountEventSelectSchema.optional(),
  where: AccountEventWhereUniqueInputSchema,
  create: z.union([ AccountEventCreateInputSchema,AccountEventUncheckedCreateInputSchema ]),
  update: z.union([ AccountEventUpdateInputSchema,AccountEventUncheckedUpdateInputSchema ]),
}).strict()

export const AccountEventCreateManyArgsSchema: z.ZodType<Prisma.AccountEventCreateManyArgs> = z.object({
  data: AccountEventCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const AccountEventDeleteArgsSchema: z.ZodType<Prisma.AccountEventDeleteArgs> = z.object({
  select: AccountEventSelectSchema.optional(),
  where: AccountEventWhereUniqueInputSchema,
}).strict()

export const AccountEventUpdateArgsSchema: z.ZodType<Prisma.AccountEventUpdateArgs> = z.object({
  select: AccountEventSelectSchema.optional(),
  data: z.union([ AccountEventUpdateInputSchema,AccountEventUncheckedUpdateInputSchema ]),
  where: AccountEventWhereUniqueInputSchema,
}).strict()

export const AccountEventUpdateManyArgsSchema: z.ZodType<Prisma.AccountEventUpdateManyArgs> = z.object({
  data: z.union([ AccountEventUpdateManyMutationInputSchema,AccountEventUncheckedUpdateManyInputSchema ]),
  where: AccountEventWhereInputSchema.optional(),
}).strict()

export const AccountEventDeleteManyArgsSchema: z.ZodType<Prisma.AccountEventDeleteManyArgs> = z.object({
  where: AccountEventWhereInputSchema.optional(),
}).strict()

export const AppPhoneNumberCreateArgsSchema: z.ZodType<Prisma.AppPhoneNumberCreateArgs> = z.object({
  select: AppPhoneNumberSelectSchema.optional(),
  data: z.union([ AppPhoneNumberCreateInputSchema,AppPhoneNumberUncheckedCreateInputSchema ]),
}).strict()

export const AppPhoneNumberUpsertArgsSchema: z.ZodType<Prisma.AppPhoneNumberUpsertArgs> = z.object({
  select: AppPhoneNumberSelectSchema.optional(),
  where: AppPhoneNumberWhereUniqueInputSchema,
  create: z.union([ AppPhoneNumberCreateInputSchema,AppPhoneNumberUncheckedCreateInputSchema ]),
  update: z.union([ AppPhoneNumberUpdateInputSchema,AppPhoneNumberUncheckedUpdateInputSchema ]),
}).strict()

export const AppPhoneNumberCreateManyArgsSchema: z.ZodType<Prisma.AppPhoneNumberCreateManyArgs> = z.object({
  data: AppPhoneNumberCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const AppPhoneNumberDeleteArgsSchema: z.ZodType<Prisma.AppPhoneNumberDeleteArgs> = z.object({
  select: AppPhoneNumberSelectSchema.optional(),
  where: AppPhoneNumberWhereUniqueInputSchema,
}).strict()

export const AppPhoneNumberUpdateArgsSchema: z.ZodType<Prisma.AppPhoneNumberUpdateArgs> = z.object({
  select: AppPhoneNumberSelectSchema.optional(),
  data: z.union([ AppPhoneNumberUpdateInputSchema,AppPhoneNumberUncheckedUpdateInputSchema ]),
  where: AppPhoneNumberWhereUniqueInputSchema,
}).strict()

export const AppPhoneNumberUpdateManyArgsSchema: z.ZodType<Prisma.AppPhoneNumberUpdateManyArgs> = z.object({
  data: z.union([ AppPhoneNumberUpdateManyMutationInputSchema,AppPhoneNumberUncheckedUpdateManyInputSchema ]),
  where: AppPhoneNumberWhereInputSchema.optional(),
}).strict()

export const AppPhoneNumberDeleteManyArgsSchema: z.ZodType<Prisma.AppPhoneNumberDeleteManyArgs> = z.object({
  where: AppPhoneNumberWhereInputSchema.optional(),
}).strict()

export const AudioEncodingCreateArgsSchema: z.ZodType<Prisma.AudioEncodingCreateArgs> = z.object({
  select: AudioEncodingSelectSchema.optional(),
  data: z.union([ AudioEncodingCreateInputSchema,AudioEncodingUncheckedCreateInputSchema ]),
}).strict()

export const AudioEncodingUpsertArgsSchema: z.ZodType<Prisma.AudioEncodingUpsertArgs> = z.object({
  select: AudioEncodingSelectSchema.optional(),
  where: AudioEncodingWhereUniqueInputSchema,
  create: z.union([ AudioEncodingCreateInputSchema,AudioEncodingUncheckedCreateInputSchema ]),
  update: z.union([ AudioEncodingUpdateInputSchema,AudioEncodingUncheckedUpdateInputSchema ]),
}).strict()

export const AudioEncodingCreateManyArgsSchema: z.ZodType<Prisma.AudioEncodingCreateManyArgs> = z.object({
  data: AudioEncodingCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const AudioEncodingDeleteArgsSchema: z.ZodType<Prisma.AudioEncodingDeleteArgs> = z.object({
  select: AudioEncodingSelectSchema.optional(),
  where: AudioEncodingWhereUniqueInputSchema,
}).strict()

export const AudioEncodingUpdateArgsSchema: z.ZodType<Prisma.AudioEncodingUpdateArgs> = z.object({
  select: AudioEncodingSelectSchema.optional(),
  data: z.union([ AudioEncodingUpdateInputSchema,AudioEncodingUncheckedUpdateInputSchema ]),
  where: AudioEncodingWhereUniqueInputSchema,
}).strict()

export const AudioEncodingUpdateManyArgsSchema: z.ZodType<Prisma.AudioEncodingUpdateManyArgs> = z.object({
  data: z.union([ AudioEncodingUpdateManyMutationInputSchema,AudioEncodingUncheckedUpdateManyInputSchema ]),
  where: AudioEncodingWhereInputSchema.optional(),
}).strict()

export const AudioEncodingDeleteManyArgsSchema: z.ZodType<Prisma.AudioEncodingDeleteManyArgs> = z.object({
  where: AudioEncodingWhereInputSchema.optional(),
}).strict()

export const AudioQueueItemCreateArgsSchema: z.ZodType<Prisma.AudioQueueItemCreateArgs> = z.object({
  select: AudioQueueItemSelectSchema.optional(),
  data: z.union([ AudioQueueItemCreateInputSchema,AudioQueueItemUncheckedCreateInputSchema ]),
}).strict()

export const AudioQueueItemUpsertArgsSchema: z.ZodType<Prisma.AudioQueueItemUpsertArgs> = z.object({
  select: AudioQueueItemSelectSchema.optional(),
  where: AudioQueueItemWhereUniqueInputSchema,
  create: z.union([ AudioQueueItemCreateInputSchema,AudioQueueItemUncheckedCreateInputSchema ]),
  update: z.union([ AudioQueueItemUpdateInputSchema,AudioQueueItemUncheckedUpdateInputSchema ]),
}).strict()

export const AudioQueueItemCreateManyArgsSchema: z.ZodType<Prisma.AudioQueueItemCreateManyArgs> = z.object({
  data: AudioQueueItemCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const AudioQueueItemDeleteArgsSchema: z.ZodType<Prisma.AudioQueueItemDeleteArgs> = z.object({
  select: AudioQueueItemSelectSchema.optional(),
  where: AudioQueueItemWhereUniqueInputSchema,
}).strict()

export const AudioQueueItemUpdateArgsSchema: z.ZodType<Prisma.AudioQueueItemUpdateArgs> = z.object({
  select: AudioQueueItemSelectSchema.optional(),
  data: z.union([ AudioQueueItemUpdateInputSchema,AudioQueueItemUncheckedUpdateInputSchema ]),
  where: AudioQueueItemWhereUniqueInputSchema,
}).strict()

export const AudioQueueItemUpdateManyArgsSchema: z.ZodType<Prisma.AudioQueueItemUpdateManyArgs> = z.object({
  data: z.union([ AudioQueueItemUpdateManyMutationInputSchema,AudioQueueItemUncheckedUpdateManyInputSchema ]),
  where: AudioQueueItemWhereInputSchema.optional(),
}).strict()

export const AudioQueueItemDeleteManyArgsSchema: z.ZodType<Prisma.AudioQueueItemDeleteManyArgs> = z.object({
  where: AudioQueueItemWhereInputSchema.optional(),
}).strict()

export const BroadcastCreateArgsSchema: z.ZodType<Prisma.BroadcastCreateArgs> = z.object({
  select: BroadcastSelectSchema.optional(),
  data: z.union([ BroadcastCreateInputSchema,BroadcastUncheckedCreateInputSchema ]),
}).strict()

export const BroadcastUpsertArgsSchema: z.ZodType<Prisma.BroadcastUpsertArgs> = z.object({
  select: BroadcastSelectSchema.optional(),
  where: BroadcastWhereUniqueInputSchema,
  create: z.union([ BroadcastCreateInputSchema,BroadcastUncheckedCreateInputSchema ]),
  update: z.union([ BroadcastUpdateInputSchema,BroadcastUncheckedUpdateInputSchema ]),
}).strict()

export const BroadcastCreateManyArgsSchema: z.ZodType<Prisma.BroadcastCreateManyArgs> = z.object({
  data: BroadcastCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const BroadcastDeleteArgsSchema: z.ZodType<Prisma.BroadcastDeleteArgs> = z.object({
  select: BroadcastSelectSchema.optional(),
  where: BroadcastWhereUniqueInputSchema,
}).strict()

export const BroadcastUpdateArgsSchema: z.ZodType<Prisma.BroadcastUpdateArgs> = z.object({
  select: BroadcastSelectSchema.optional(),
  data: z.union([ BroadcastUpdateInputSchema,BroadcastUncheckedUpdateInputSchema ]),
  where: BroadcastWhereUniqueInputSchema,
}).strict()

export const BroadcastUpdateManyArgsSchema: z.ZodType<Prisma.BroadcastUpdateManyArgs> = z.object({
  data: z.union([ BroadcastUpdateManyMutationInputSchema,BroadcastUncheckedUpdateManyInputSchema ]),
  where: BroadcastWhereInputSchema.optional(),
}).strict()

export const BroadcastDeleteManyArgsSchema: z.ZodType<Prisma.BroadcastDeleteManyArgs> = z.object({
  where: BroadcastWhereInputSchema.optional(),
}).strict()

export const BroadcastActionCreateArgsSchema: z.ZodType<Prisma.BroadcastActionCreateArgs> = z.object({
  select: BroadcastActionSelectSchema.optional(),
  data: z.union([ BroadcastActionCreateInputSchema,BroadcastActionUncheckedCreateInputSchema ]),
}).strict()

export const BroadcastActionUpsertArgsSchema: z.ZodType<Prisma.BroadcastActionUpsertArgs> = z.object({
  select: BroadcastActionSelectSchema.optional(),
  where: BroadcastActionWhereUniqueInputSchema,
  create: z.union([ BroadcastActionCreateInputSchema,BroadcastActionUncheckedCreateInputSchema ]),
  update: z.union([ BroadcastActionUpdateInputSchema,BroadcastActionUncheckedUpdateInputSchema ]),
}).strict()

export const BroadcastActionCreateManyArgsSchema: z.ZodType<Prisma.BroadcastActionCreateManyArgs> = z.object({
  data: BroadcastActionCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const BroadcastActionDeleteArgsSchema: z.ZodType<Prisma.BroadcastActionDeleteArgs> = z.object({
  select: BroadcastActionSelectSchema.optional(),
  where: BroadcastActionWhereUniqueInputSchema,
}).strict()

export const BroadcastActionUpdateArgsSchema: z.ZodType<Prisma.BroadcastActionUpdateArgs> = z.object({
  select: BroadcastActionSelectSchema.optional(),
  data: z.union([ BroadcastActionUpdateInputSchema,BroadcastActionUncheckedUpdateInputSchema ]),
  where: BroadcastActionWhereUniqueInputSchema,
}).strict()

export const BroadcastActionUpdateManyArgsSchema: z.ZodType<Prisma.BroadcastActionUpdateManyArgs> = z.object({
  data: z.union([ BroadcastActionUpdateManyMutationInputSchema,BroadcastActionUncheckedUpdateManyInputSchema ]),
  where: BroadcastActionWhereInputSchema.optional(),
}).strict()

export const BroadcastActionDeleteManyArgsSchema: z.ZodType<Prisma.BroadcastActionDeleteManyArgs> = z.object({
  where: BroadcastActionWhereInputSchema.optional(),
}).strict()

export const BroadcastRecipientCreateArgsSchema: z.ZodType<Prisma.BroadcastRecipientCreateArgs> = z.object({
  select: BroadcastRecipientSelectSchema.optional(),
  data: z.union([ BroadcastRecipientCreateInputSchema,BroadcastRecipientUncheckedCreateInputSchema ]),
}).strict()

export const BroadcastRecipientUpsertArgsSchema: z.ZodType<Prisma.BroadcastRecipientUpsertArgs> = z.object({
  select: BroadcastRecipientSelectSchema.optional(),
  where: BroadcastRecipientWhereUniqueInputSchema,
  create: z.union([ BroadcastRecipientCreateInputSchema,BroadcastRecipientUncheckedCreateInputSchema ]),
  update: z.union([ BroadcastRecipientUpdateInputSchema,BroadcastRecipientUncheckedUpdateInputSchema ]),
}).strict()

export const BroadcastRecipientCreateManyArgsSchema: z.ZodType<Prisma.BroadcastRecipientCreateManyArgs> = z.object({
  data: BroadcastRecipientCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const BroadcastRecipientDeleteArgsSchema: z.ZodType<Prisma.BroadcastRecipientDeleteArgs> = z.object({
  select: BroadcastRecipientSelectSchema.optional(),
  where: BroadcastRecipientWhereUniqueInputSchema,
}).strict()

export const BroadcastRecipientUpdateArgsSchema: z.ZodType<Prisma.BroadcastRecipientUpdateArgs> = z.object({
  select: BroadcastRecipientSelectSchema.optional(),
  data: z.union([ BroadcastRecipientUpdateInputSchema,BroadcastRecipientUncheckedUpdateInputSchema ]),
  where: BroadcastRecipientWhereUniqueInputSchema,
}).strict()

export const BroadcastRecipientUpdateManyArgsSchema: z.ZodType<Prisma.BroadcastRecipientUpdateManyArgs> = z.object({
  data: z.union([ BroadcastRecipientUpdateManyMutationInputSchema,BroadcastRecipientUncheckedUpdateManyInputSchema ]),
  where: BroadcastRecipientWhereInputSchema.optional(),
}).strict()

export const BroadcastRecipientDeleteManyArgsSchema: z.ZodType<Prisma.BroadcastRecipientDeleteManyArgs> = z.object({
  where: BroadcastRecipientWhereInputSchema.optional(),
}).strict()

export const CallRecordCreateArgsSchema: z.ZodType<Prisma.CallRecordCreateArgs> = z.object({
  select: CallRecordSelectSchema.optional(),
  data: z.union([ CallRecordCreateInputSchema,CallRecordUncheckedCreateInputSchema ]),
}).strict()

export const CallRecordUpsertArgsSchema: z.ZodType<Prisma.CallRecordUpsertArgs> = z.object({
  select: CallRecordSelectSchema.optional(),
  where: CallRecordWhereUniqueInputSchema,
  create: z.union([ CallRecordCreateInputSchema,CallRecordUncheckedCreateInputSchema ]),
  update: z.union([ CallRecordUpdateInputSchema,CallRecordUncheckedUpdateInputSchema ]),
}).strict()

export const CallRecordCreateManyArgsSchema: z.ZodType<Prisma.CallRecordCreateManyArgs> = z.object({
  data: CallRecordCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const CallRecordDeleteArgsSchema: z.ZodType<Prisma.CallRecordDeleteArgs> = z.object({
  select: CallRecordSelectSchema.optional(),
  where: CallRecordWhereUniqueInputSchema,
}).strict()

export const CallRecordUpdateArgsSchema: z.ZodType<Prisma.CallRecordUpdateArgs> = z.object({
  select: CallRecordSelectSchema.optional(),
  data: z.union([ CallRecordUpdateInputSchema,CallRecordUncheckedUpdateInputSchema ]),
  where: CallRecordWhereUniqueInputSchema,
}).strict()

export const CallRecordUpdateManyArgsSchema: z.ZodType<Prisma.CallRecordUpdateManyArgs> = z.object({
  data: z.union([ CallRecordUpdateManyMutationInputSchema,CallRecordUncheckedUpdateManyInputSchema ]),
  where: CallRecordWhereInputSchema.optional(),
}).strict()

export const CallRecordDeleteManyArgsSchema: z.ZodType<Prisma.CallRecordDeleteManyArgs> = z.object({
  where: CallRecordWhereInputSchema.optional(),
}).strict()

export const DeviceRegistrationCreateArgsSchema: z.ZodType<Prisma.DeviceRegistrationCreateArgs> = z.object({
  select: DeviceRegistrationSelectSchema.optional(),
  data: z.union([ DeviceRegistrationCreateInputSchema,DeviceRegistrationUncheckedCreateInputSchema ]),
}).strict()

export const DeviceRegistrationUpsertArgsSchema: z.ZodType<Prisma.DeviceRegistrationUpsertArgs> = z.object({
  select: DeviceRegistrationSelectSchema.optional(),
  where: DeviceRegistrationWhereUniqueInputSchema,
  create: z.union([ DeviceRegistrationCreateInputSchema,DeviceRegistrationUncheckedCreateInputSchema ]),
  update: z.union([ DeviceRegistrationUpdateInputSchema,DeviceRegistrationUncheckedUpdateInputSchema ]),
}).strict()

export const DeviceRegistrationCreateManyArgsSchema: z.ZodType<Prisma.DeviceRegistrationCreateManyArgs> = z.object({
  data: DeviceRegistrationCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const DeviceRegistrationDeleteArgsSchema: z.ZodType<Prisma.DeviceRegistrationDeleteArgs> = z.object({
  select: DeviceRegistrationSelectSchema.optional(),
  where: DeviceRegistrationWhereUniqueInputSchema,
}).strict()

export const DeviceRegistrationUpdateArgsSchema: z.ZodType<Prisma.DeviceRegistrationUpdateArgs> = z.object({
  select: DeviceRegistrationSelectSchema.optional(),
  data: z.union([ DeviceRegistrationUpdateInputSchema,DeviceRegistrationUncheckedUpdateInputSchema ]),
  where: DeviceRegistrationWhereUniqueInputSchema,
}).strict()

export const DeviceRegistrationUpdateManyArgsSchema: z.ZodType<Prisma.DeviceRegistrationUpdateManyArgs> = z.object({
  data: z.union([ DeviceRegistrationUpdateManyMutationInputSchema,DeviceRegistrationUncheckedUpdateManyInputSchema ]),
  where: DeviceRegistrationWhereInputSchema.optional(),
}).strict()

export const DeviceRegistrationDeleteManyArgsSchema: z.ZodType<Prisma.DeviceRegistrationDeleteManyArgs> = z.object({
  where: DeviceRegistrationWhereInputSchema.optional(),
}).strict()

export const DirectWsInvitationCreateArgsSchema: z.ZodType<Prisma.DirectWsInvitationCreateArgs> = z.object({
  select: DirectWsInvitationSelectSchema.optional(),
  data: z.union([ DirectWsInvitationCreateInputSchema,DirectWsInvitationUncheckedCreateInputSchema ]),
}).strict()

export const DirectWsInvitationUpsertArgsSchema: z.ZodType<Prisma.DirectWsInvitationUpsertArgs> = z.object({
  select: DirectWsInvitationSelectSchema.optional(),
  where: DirectWsInvitationWhereUniqueInputSchema,
  create: z.union([ DirectWsInvitationCreateInputSchema,DirectWsInvitationUncheckedCreateInputSchema ]),
  update: z.union([ DirectWsInvitationUpdateInputSchema,DirectWsInvitationUncheckedUpdateInputSchema ]),
}).strict()

export const DirectWsInvitationCreateManyArgsSchema: z.ZodType<Prisma.DirectWsInvitationCreateManyArgs> = z.object({
  data: DirectWsInvitationCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const DirectWsInvitationDeleteArgsSchema: z.ZodType<Prisma.DirectWsInvitationDeleteArgs> = z.object({
  select: DirectWsInvitationSelectSchema.optional(),
  where: DirectWsInvitationWhereUniqueInputSchema,
}).strict()

export const DirectWsInvitationUpdateArgsSchema: z.ZodType<Prisma.DirectWsInvitationUpdateArgs> = z.object({
  select: DirectWsInvitationSelectSchema.optional(),
  data: z.union([ DirectWsInvitationUpdateInputSchema,DirectWsInvitationUncheckedUpdateInputSchema ]),
  where: DirectWsInvitationWhereUniqueInputSchema,
}).strict()

export const DirectWsInvitationUpdateManyArgsSchema: z.ZodType<Prisma.DirectWsInvitationUpdateManyArgs> = z.object({
  data: z.union([ DirectWsInvitationUpdateManyMutationInputSchema,DirectWsInvitationUncheckedUpdateManyInputSchema ]),
  where: DirectWsInvitationWhereInputSchema.optional(),
}).strict()

export const DirectWsInvitationDeleteManyArgsSchema: z.ZodType<Prisma.DirectWsInvitationDeleteManyArgs> = z.object({
  where: DirectWsInvitationWhereInputSchema.optional(),
}).strict()

export const DisplayArtifactCreateArgsSchema: z.ZodType<Prisma.DisplayArtifactCreateArgs> = z.object({
  select: DisplayArtifactSelectSchema.optional(),
  data: z.union([ DisplayArtifactCreateInputSchema,DisplayArtifactUncheckedCreateInputSchema ]),
}).strict()

export const DisplayArtifactUpsertArgsSchema: z.ZodType<Prisma.DisplayArtifactUpsertArgs> = z.object({
  select: DisplayArtifactSelectSchema.optional(),
  where: DisplayArtifactWhereUniqueInputSchema,
  create: z.union([ DisplayArtifactCreateInputSchema,DisplayArtifactUncheckedCreateInputSchema ]),
  update: z.union([ DisplayArtifactUpdateInputSchema,DisplayArtifactUncheckedUpdateInputSchema ]),
}).strict()

export const DisplayArtifactCreateManyArgsSchema: z.ZodType<Prisma.DisplayArtifactCreateManyArgs> = z.object({
  data: DisplayArtifactCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const DisplayArtifactDeleteArgsSchema: z.ZodType<Prisma.DisplayArtifactDeleteArgs> = z.object({
  select: DisplayArtifactSelectSchema.optional(),
  where: DisplayArtifactWhereUniqueInputSchema,
}).strict()

export const DisplayArtifactUpdateArgsSchema: z.ZodType<Prisma.DisplayArtifactUpdateArgs> = z.object({
  select: DisplayArtifactSelectSchema.optional(),
  data: z.union([ DisplayArtifactUpdateInputSchema,DisplayArtifactUncheckedUpdateInputSchema ]),
  where: DisplayArtifactWhereUniqueInputSchema,
}).strict()

export const DisplayArtifactUpdateManyArgsSchema: z.ZodType<Prisma.DisplayArtifactUpdateManyArgs> = z.object({
  data: z.union([ DisplayArtifactUpdateManyMutationInputSchema,DisplayArtifactUncheckedUpdateManyInputSchema ]),
  where: DisplayArtifactWhereInputSchema.optional(),
}).strict()

export const DisplayArtifactDeleteManyArgsSchema: z.ZodType<Prisma.DisplayArtifactDeleteManyArgs> = z.object({
  where: DisplayArtifactWhereInputSchema.optional(),
}).strict()

export const FeedCreateArgsSchema: z.ZodType<Prisma.FeedCreateArgs> = z.object({
  select: FeedSelectSchema.optional(),
  data: z.union([ FeedCreateInputSchema,FeedUncheckedCreateInputSchema ]),
}).strict()

export const FeedUpsertArgsSchema: z.ZodType<Prisma.FeedUpsertArgs> = z.object({
  select: FeedSelectSchema.optional(),
  where: FeedWhereUniqueInputSchema,
  create: z.union([ FeedCreateInputSchema,FeedUncheckedCreateInputSchema ]),
  update: z.union([ FeedUpdateInputSchema,FeedUncheckedUpdateInputSchema ]),
}).strict()

export const FeedCreateManyArgsSchema: z.ZodType<Prisma.FeedCreateManyArgs> = z.object({
  data: FeedCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const FeedDeleteArgsSchema: z.ZodType<Prisma.FeedDeleteArgs> = z.object({
  select: FeedSelectSchema.optional(),
  where: FeedWhereUniqueInputSchema,
}).strict()

export const FeedUpdateArgsSchema: z.ZodType<Prisma.FeedUpdateArgs> = z.object({
  select: FeedSelectSchema.optional(),
  data: z.union([ FeedUpdateInputSchema,FeedUncheckedUpdateInputSchema ]),
  where: FeedWhereUniqueInputSchema,
}).strict()

export const FeedUpdateManyArgsSchema: z.ZodType<Prisma.FeedUpdateManyArgs> = z.object({
  data: z.union([ FeedUpdateManyMutationInputSchema,FeedUncheckedUpdateManyInputSchema ]),
  where: FeedWhereInputSchema.optional(),
}).strict()

export const FeedDeleteManyArgsSchema: z.ZodType<Prisma.FeedDeleteManyArgs> = z.object({
  where: FeedWhereInputSchema.optional(),
}).strict()

export const FeedGroupCreateArgsSchema: z.ZodType<Prisma.FeedGroupCreateArgs> = z.object({
  select: FeedGroupSelectSchema.optional(),
  data: z.union([ FeedGroupCreateInputSchema,FeedGroupUncheckedCreateInputSchema ]),
}).strict()

export const FeedGroupUpsertArgsSchema: z.ZodType<Prisma.FeedGroupUpsertArgs> = z.object({
  select: FeedGroupSelectSchema.optional(),
  where: FeedGroupWhereUniqueInputSchema,
  create: z.union([ FeedGroupCreateInputSchema,FeedGroupUncheckedCreateInputSchema ]),
  update: z.union([ FeedGroupUpdateInputSchema,FeedGroupUncheckedUpdateInputSchema ]),
}).strict()

export const FeedGroupCreateManyArgsSchema: z.ZodType<Prisma.FeedGroupCreateManyArgs> = z.object({
  data: FeedGroupCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const FeedGroupDeleteArgsSchema: z.ZodType<Prisma.FeedGroupDeleteArgs> = z.object({
  select: FeedGroupSelectSchema.optional(),
  where: FeedGroupWhereUniqueInputSchema,
}).strict()

export const FeedGroupUpdateArgsSchema: z.ZodType<Prisma.FeedGroupUpdateArgs> = z.object({
  select: FeedGroupSelectSchema.optional(),
  data: z.union([ FeedGroupUpdateInputSchema,FeedGroupUncheckedUpdateInputSchema ]),
  where: FeedGroupWhereUniqueInputSchema,
}).strict()

export const FeedGroupUpdateManyArgsSchema: z.ZodType<Prisma.FeedGroupUpdateManyArgs> = z.object({
  data: z.union([ FeedGroupUpdateManyMutationInputSchema,FeedGroupUncheckedUpdateManyInputSchema ]),
  where: FeedGroupWhereInputSchema.optional(),
}).strict()

export const FeedGroupDeleteManyArgsSchema: z.ZodType<Prisma.FeedGroupDeleteManyArgs> = z.object({
  where: FeedGroupWhereInputSchema.optional(),
}).strict()

export const FeedGroupMembershipCreateArgsSchema: z.ZodType<Prisma.FeedGroupMembershipCreateArgs> = z.object({
  select: FeedGroupMembershipSelectSchema.optional(),
  data: z.union([ FeedGroupMembershipCreateInputSchema,FeedGroupMembershipUncheckedCreateInputSchema ]),
}).strict()

export const FeedGroupMembershipUpsertArgsSchema: z.ZodType<Prisma.FeedGroupMembershipUpsertArgs> = z.object({
  select: FeedGroupMembershipSelectSchema.optional(),
  where: FeedGroupMembershipWhereUniqueInputSchema,
  create: z.union([ FeedGroupMembershipCreateInputSchema,FeedGroupMembershipUncheckedCreateInputSchema ]),
  update: z.union([ FeedGroupMembershipUpdateInputSchema,FeedGroupMembershipUncheckedUpdateInputSchema ]),
}).strict()

export const FeedGroupMembershipCreateManyArgsSchema: z.ZodType<Prisma.FeedGroupMembershipCreateManyArgs> = z.object({
  data: FeedGroupMembershipCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const FeedGroupMembershipDeleteArgsSchema: z.ZodType<Prisma.FeedGroupMembershipDeleteArgs> = z.object({
  select: FeedGroupMembershipSelectSchema.optional(),
  where: FeedGroupMembershipWhereUniqueInputSchema,
}).strict()

export const FeedGroupMembershipUpdateArgsSchema: z.ZodType<Prisma.FeedGroupMembershipUpdateArgs> = z.object({
  select: FeedGroupMembershipSelectSchema.optional(),
  data: z.union([ FeedGroupMembershipUpdateInputSchema,FeedGroupMembershipUncheckedUpdateInputSchema ]),
  where: FeedGroupMembershipWhereUniqueInputSchema,
}).strict()

export const FeedGroupMembershipUpdateManyArgsSchema: z.ZodType<Prisma.FeedGroupMembershipUpdateManyArgs> = z.object({
  data: z.union([ FeedGroupMembershipUpdateManyMutationInputSchema,FeedGroupMembershipUncheckedUpdateManyInputSchema ]),
  where: FeedGroupMembershipWhereInputSchema.optional(),
}).strict()

export const FeedGroupMembershipDeleteManyArgsSchema: z.ZodType<Prisma.FeedGroupMembershipDeleteManyArgs> = z.object({
  where: FeedGroupMembershipWhereInputSchema.optional(),
}).strict()

export const FileCreateArgsSchema: z.ZodType<Prisma.FileCreateArgs> = z.object({
  select: FileSelectSchema.optional(),
  data: z.union([ FileCreateInputSchema,FileUncheckedCreateInputSchema ]),
}).strict()

export const FileUpsertArgsSchema: z.ZodType<Prisma.FileUpsertArgs> = z.object({
  select: FileSelectSchema.optional(),
  where: FileWhereUniqueInputSchema,
  create: z.union([ FileCreateInputSchema,FileUncheckedCreateInputSchema ]),
  update: z.union([ FileUpdateInputSchema,FileUncheckedUpdateInputSchema ]),
}).strict()

export const FileCreateManyArgsSchema: z.ZodType<Prisma.FileCreateManyArgs> = z.object({
  data: FileCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const FileDeleteArgsSchema: z.ZodType<Prisma.FileDeleteArgs> = z.object({
  select: FileSelectSchema.optional(),
  where: FileWhereUniqueInputSchema,
}).strict()

export const FileUpdateArgsSchema: z.ZodType<Prisma.FileUpdateArgs> = z.object({
  select: FileSelectSchema.optional(),
  data: z.union([ FileUpdateInputSchema,FileUncheckedUpdateInputSchema ]),
  where: FileWhereUniqueInputSchema,
}).strict()

export const FileUpdateManyArgsSchema: z.ZodType<Prisma.FileUpdateManyArgs> = z.object({
  data: z.union([ FileUpdateManyMutationInputSchema,FileUncheckedUpdateManyInputSchema ]),
  where: FileWhereInputSchema.optional(),
}).strict()

export const FileDeleteManyArgsSchema: z.ZodType<Prisma.FileDeleteManyArgs> = z.object({
  where: FileWhereInputSchema.optional(),
}).strict()

export const ItemCreateArgsSchema: z.ZodType<Prisma.ItemCreateArgs> = z.object({
  select: ItemSelectSchema.optional(),
  data: z.union([ ItemCreateInputSchema,ItemUncheckedCreateInputSchema ]),
}).strict()

export const ItemUpsertArgsSchema: z.ZodType<Prisma.ItemUpsertArgs> = z.object({
  select: ItemSelectSchema.optional(),
  where: ItemWhereUniqueInputSchema,
  create: z.union([ ItemCreateInputSchema,ItemUncheckedCreateInputSchema ]),
  update: z.union([ ItemUpdateInputSchema,ItemUncheckedUpdateInputSchema ]),
}).strict()

export const ItemCreateManyArgsSchema: z.ZodType<Prisma.ItemCreateManyArgs> = z.object({
  data: ItemCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const ItemDeleteArgsSchema: z.ZodType<Prisma.ItemDeleteArgs> = z.object({
  select: ItemSelectSchema.optional(),
  where: ItemWhereUniqueInputSchema,
}).strict()

export const ItemUpdateArgsSchema: z.ZodType<Prisma.ItemUpdateArgs> = z.object({
  select: ItemSelectSchema.optional(),
  data: z.union([ ItemUpdateInputSchema,ItemUncheckedUpdateInputSchema ]),
  where: ItemWhereUniqueInputSchema,
}).strict()

export const ItemUpdateManyArgsSchema: z.ZodType<Prisma.ItemUpdateManyArgs> = z.object({
  data: z.union([ ItemUpdateManyMutationInputSchema,ItemUncheckedUpdateManyInputSchema ]),
  where: ItemWhereInputSchema.optional(),
}).strict()

export const ItemDeleteManyArgsSchema: z.ZodType<Prisma.ItemDeleteManyArgs> = z.object({
  where: ItemWhereInputSchema.optional(),
}).strict()

export const LinkCreateArgsSchema: z.ZodType<Prisma.LinkCreateArgs> = z.object({
  select: LinkSelectSchema.optional(),
  data: z.union([ LinkCreateInputSchema,LinkUncheckedCreateInputSchema ]),
}).strict()

export const LinkUpsertArgsSchema: z.ZodType<Prisma.LinkUpsertArgs> = z.object({
  select: LinkSelectSchema.optional(),
  where: LinkWhereUniqueInputSchema,
  create: z.union([ LinkCreateInputSchema,LinkUncheckedCreateInputSchema ]),
  update: z.union([ LinkUpdateInputSchema,LinkUncheckedUpdateInputSchema ]),
}).strict()

export const LinkCreateManyArgsSchema: z.ZodType<Prisma.LinkCreateManyArgs> = z.object({
  data: LinkCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const LinkDeleteArgsSchema: z.ZodType<Prisma.LinkDeleteArgs> = z.object({
  select: LinkSelectSchema.optional(),
  where: LinkWhereUniqueInputSchema,
}).strict()

export const LinkUpdateArgsSchema: z.ZodType<Prisma.LinkUpdateArgs> = z.object({
  select: LinkSelectSchema.optional(),
  data: z.union([ LinkUpdateInputSchema,LinkUncheckedUpdateInputSchema ]),
  where: LinkWhereUniqueInputSchema,
}).strict()

export const LinkUpdateManyArgsSchema: z.ZodType<Prisma.LinkUpdateManyArgs> = z.object({
  data: z.union([ LinkUpdateManyMutationInputSchema,LinkUncheckedUpdateManyInputSchema ]),
  where: LinkWhereInputSchema.optional(),
}).strict()

export const LinkDeleteManyArgsSchema: z.ZodType<Prisma.LinkDeleteManyArgs> = z.object({
  where: LinkWhereInputSchema.optional(),
}).strict()

export const PermissionCreateArgsSchema: z.ZodType<Prisma.PermissionCreateArgs> = z.object({
  select: PermissionSelectSchema.optional(),
  data: z.union([ PermissionCreateInputSchema,PermissionUncheckedCreateInputSchema ]),
}).strict()

export const PermissionUpsertArgsSchema: z.ZodType<Prisma.PermissionUpsertArgs> = z.object({
  select: PermissionSelectSchema.optional(),
  where: PermissionWhereUniqueInputSchema,
  create: z.union([ PermissionCreateInputSchema,PermissionUncheckedCreateInputSchema ]),
  update: z.union([ PermissionUpdateInputSchema,PermissionUncheckedUpdateInputSchema ]),
}).strict()

export const PermissionCreateManyArgsSchema: z.ZodType<Prisma.PermissionCreateManyArgs> = z.object({
  data: PermissionCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const PermissionDeleteArgsSchema: z.ZodType<Prisma.PermissionDeleteArgs> = z.object({
  select: PermissionSelectSchema.optional(),
  where: PermissionWhereUniqueInputSchema,
}).strict()

export const PermissionUpdateArgsSchema: z.ZodType<Prisma.PermissionUpdateArgs> = z.object({
  select: PermissionSelectSchema.optional(),
  data: z.union([ PermissionUpdateInputSchema,PermissionUncheckedUpdateInputSchema ]),
  where: PermissionWhereUniqueInputSchema,
}).strict()

export const PermissionUpdateManyArgsSchema: z.ZodType<Prisma.PermissionUpdateManyArgs> = z.object({
  data: z.union([ PermissionUpdateManyMutationInputSchema,PermissionUncheckedUpdateManyInputSchema ]),
  where: PermissionWhereInputSchema.optional(),
}).strict()

export const PermissionDeleteManyArgsSchema: z.ZodType<Prisma.PermissionDeleteManyArgs> = z.object({
  where: PermissionWhereInputSchema.optional(),
}).strict()

export const PipelineArtifactMetadataCreateArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataCreateArgs> = z.object({
  select: PipelineArtifactMetadataSelectSchema.optional(),
  data: z.union([ PipelineArtifactMetadataCreateInputSchema,PipelineArtifactMetadataUncheckedCreateInputSchema ]),
}).strict()

export const PipelineArtifactMetadataUpsertArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataUpsertArgs> = z.object({
  select: PipelineArtifactMetadataSelectSchema.optional(),
  where: PipelineArtifactMetadataWhereUniqueInputSchema,
  create: z.union([ PipelineArtifactMetadataCreateInputSchema,PipelineArtifactMetadataUncheckedCreateInputSchema ]),
  update: z.union([ PipelineArtifactMetadataUpdateInputSchema,PipelineArtifactMetadataUncheckedUpdateInputSchema ]),
}).strict()

export const PipelineArtifactMetadataCreateManyArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataCreateManyArgs> = z.object({
  data: PipelineArtifactMetadataCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const PipelineArtifactMetadataDeleteArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataDeleteArgs> = z.object({
  select: PipelineArtifactMetadataSelectSchema.optional(),
  where: PipelineArtifactMetadataWhereUniqueInputSchema,
}).strict()

export const PipelineArtifactMetadataUpdateArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataUpdateArgs> = z.object({
  select: PipelineArtifactMetadataSelectSchema.optional(),
  data: z.union([ PipelineArtifactMetadataUpdateInputSchema,PipelineArtifactMetadataUncheckedUpdateInputSchema ]),
  where: PipelineArtifactMetadataWhereUniqueInputSchema,
}).strict()

export const PipelineArtifactMetadataUpdateManyArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataUpdateManyArgs> = z.object({
  data: z.union([ PipelineArtifactMetadataUpdateManyMutationInputSchema,PipelineArtifactMetadataUncheckedUpdateManyInputSchema ]),
  where: PipelineArtifactMetadataWhereInputSchema.optional(),
}).strict()

export const PipelineArtifactMetadataDeleteManyArgsSchema: z.ZodType<Prisma.PipelineArtifactMetadataDeleteManyArgs> = z.object({
  where: PipelineArtifactMetadataWhereInputSchema.optional(),
}).strict()

export const PublishedWorkflowItemCreateArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemCreateArgs> = z.object({
  select: PublishedWorkflowItemSelectSchema.optional(),
  data: z.union([ PublishedWorkflowItemCreateInputSchema,PublishedWorkflowItemUncheckedCreateInputSchema ]),
}).strict()

export const PublishedWorkflowItemUpsertArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemUpsertArgs> = z.object({
  select: PublishedWorkflowItemSelectSchema.optional(),
  where: PublishedWorkflowItemWhereUniqueInputSchema,
  create: z.union([ PublishedWorkflowItemCreateInputSchema,PublishedWorkflowItemUncheckedCreateInputSchema ]),
  update: z.union([ PublishedWorkflowItemUpdateInputSchema,PublishedWorkflowItemUncheckedUpdateInputSchema ]),
}).strict()

export const PublishedWorkflowItemCreateManyArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemCreateManyArgs> = z.object({
  data: PublishedWorkflowItemCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const PublishedWorkflowItemDeleteArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemDeleteArgs> = z.object({
  select: PublishedWorkflowItemSelectSchema.optional(),
  where: PublishedWorkflowItemWhereUniqueInputSchema,
}).strict()

export const PublishedWorkflowItemUpdateArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemUpdateArgs> = z.object({
  select: PublishedWorkflowItemSelectSchema.optional(),
  data: z.union([ PublishedWorkflowItemUpdateInputSchema,PublishedWorkflowItemUncheckedUpdateInputSchema ]),
  where: PublishedWorkflowItemWhereUniqueInputSchema,
}).strict()

export const PublishedWorkflowItemUpdateManyArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemUpdateManyArgs> = z.object({
  data: z.union([ PublishedWorkflowItemUpdateManyMutationInputSchema,PublishedWorkflowItemUncheckedUpdateManyInputSchema ]),
  where: PublishedWorkflowItemWhereInputSchema.optional(),
}).strict()

export const PublishedWorkflowItemDeleteManyArgsSchema: z.ZodType<Prisma.PublishedWorkflowItemDeleteManyArgs> = z.object({
  where: PublishedWorkflowItemWhereInputSchema.optional(),
}).strict()

export const ScheduleTriggerCreateArgsSchema: z.ZodType<Prisma.ScheduleTriggerCreateArgs> = z.object({
  select: ScheduleTriggerSelectSchema.optional(),
  data: z.union([ ScheduleTriggerCreateInputSchema,ScheduleTriggerUncheckedCreateInputSchema ]),
}).strict()

export const ScheduleTriggerUpsertArgsSchema: z.ZodType<Prisma.ScheduleTriggerUpsertArgs> = z.object({
  select: ScheduleTriggerSelectSchema.optional(),
  where: ScheduleTriggerWhereUniqueInputSchema,
  create: z.union([ ScheduleTriggerCreateInputSchema,ScheduleTriggerUncheckedCreateInputSchema ]),
  update: z.union([ ScheduleTriggerUpdateInputSchema,ScheduleTriggerUncheckedUpdateInputSchema ]),
}).strict()

export const ScheduleTriggerCreateManyArgsSchema: z.ZodType<Prisma.ScheduleTriggerCreateManyArgs> = z.object({
  data: ScheduleTriggerCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const ScheduleTriggerDeleteArgsSchema: z.ZodType<Prisma.ScheduleTriggerDeleteArgs> = z.object({
  select: ScheduleTriggerSelectSchema.optional(),
  where: ScheduleTriggerWhereUniqueInputSchema,
}).strict()

export const ScheduleTriggerUpdateArgsSchema: z.ZodType<Prisma.ScheduleTriggerUpdateArgs> = z.object({
  select: ScheduleTriggerSelectSchema.optional(),
  data: z.union([ ScheduleTriggerUpdateInputSchema,ScheduleTriggerUncheckedUpdateInputSchema ]),
  where: ScheduleTriggerWhereUniqueInputSchema,
}).strict()

export const ScheduleTriggerUpdateManyArgsSchema: z.ZodType<Prisma.ScheduleTriggerUpdateManyArgs> = z.object({
  data: z.union([ ScheduleTriggerUpdateManyMutationInputSchema,ScheduleTriggerUncheckedUpdateManyInputSchema ]),
  where: ScheduleTriggerWhereInputSchema.optional(),
}).strict()

export const ScheduleTriggerDeleteManyArgsSchema: z.ZodType<Prisma.ScheduleTriggerDeleteManyArgs> = z.object({
  where: ScheduleTriggerWhereInputSchema.optional(),
}).strict()

export const TemplateCreateArgsSchema: z.ZodType<Prisma.TemplateCreateArgs> = z.object({
  select: TemplateSelectSchema.optional(),
  data: z.union([ TemplateCreateInputSchema,TemplateUncheckedCreateInputSchema ]),
}).strict()

export const TemplateUpsertArgsSchema: z.ZodType<Prisma.TemplateUpsertArgs> = z.object({
  select: TemplateSelectSchema.optional(),
  where: TemplateWhereUniqueInputSchema,
  create: z.union([ TemplateCreateInputSchema,TemplateUncheckedCreateInputSchema ]),
  update: z.union([ TemplateUpdateInputSchema,TemplateUncheckedUpdateInputSchema ]),
}).strict()

export const TemplateCreateManyArgsSchema: z.ZodType<Prisma.TemplateCreateManyArgs> = z.object({
  data: TemplateCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const TemplateDeleteArgsSchema: z.ZodType<Prisma.TemplateDeleteArgs> = z.object({
  select: TemplateSelectSchema.optional(),
  where: TemplateWhereUniqueInputSchema,
}).strict()

export const TemplateUpdateArgsSchema: z.ZodType<Prisma.TemplateUpdateArgs> = z.object({
  select: TemplateSelectSchema.optional(),
  data: z.union([ TemplateUpdateInputSchema,TemplateUncheckedUpdateInputSchema ]),
  where: TemplateWhereUniqueInputSchema,
}).strict()

export const TemplateUpdateManyArgsSchema: z.ZodType<Prisma.TemplateUpdateManyArgs> = z.object({
  data: z.union([ TemplateUpdateManyMutationInputSchema,TemplateUncheckedUpdateManyInputSchema ]),
  where: TemplateWhereInputSchema.optional(),
}).strict()

export const TemplateDeleteManyArgsSchema: z.ZodType<Prisma.TemplateDeleteManyArgs> = z.object({
  where: TemplateWhereInputSchema.optional(),
}).strict()

export const TranscriptionCreateArgsSchema: z.ZodType<Prisma.TranscriptionCreateArgs> = z.object({
  select: TranscriptionSelectSchema.optional(),
  data: z.union([ TranscriptionCreateInputSchema,TranscriptionUncheckedCreateInputSchema ]),
}).strict()

export const TranscriptionUpsertArgsSchema: z.ZodType<Prisma.TranscriptionUpsertArgs> = z.object({
  select: TranscriptionSelectSchema.optional(),
  where: TranscriptionWhereUniqueInputSchema,
  create: z.union([ TranscriptionCreateInputSchema,TranscriptionUncheckedCreateInputSchema ]),
  update: z.union([ TranscriptionUpdateInputSchema,TranscriptionUncheckedUpdateInputSchema ]),
}).strict()

export const TranscriptionCreateManyArgsSchema: z.ZodType<Prisma.TranscriptionCreateManyArgs> = z.object({
  data: TranscriptionCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const TranscriptionDeleteArgsSchema: z.ZodType<Prisma.TranscriptionDeleteArgs> = z.object({
  select: TranscriptionSelectSchema.optional(),
  where: TranscriptionWhereUniqueInputSchema,
}).strict()

export const TranscriptionUpdateArgsSchema: z.ZodType<Prisma.TranscriptionUpdateArgs> = z.object({
  select: TranscriptionSelectSchema.optional(),
  data: z.union([ TranscriptionUpdateInputSchema,TranscriptionUncheckedUpdateInputSchema ]),
  where: TranscriptionWhereUniqueInputSchema,
}).strict()

export const TranscriptionUpdateManyArgsSchema: z.ZodType<Prisma.TranscriptionUpdateManyArgs> = z.object({
  data: z.union([ TranscriptionUpdateManyMutationInputSchema,TranscriptionUncheckedUpdateManyInputSchema ]),
  where: TranscriptionWhereInputSchema.optional(),
}).strict()

export const TranscriptionDeleteManyArgsSchema: z.ZodType<Prisma.TranscriptionDeleteManyArgs> = z.object({
  where: TranscriptionWhereInputSchema.optional(),
}).strict()

export const WorkflowItemCreateArgsSchema: z.ZodType<Prisma.WorkflowItemCreateArgs> = z.object({
  select: WorkflowItemSelectSchema.optional(),
  data: z.union([ WorkflowItemCreateInputSchema,WorkflowItemUncheckedCreateInputSchema ]),
}).strict()

export const WorkflowItemUpsertArgsSchema: z.ZodType<Prisma.WorkflowItemUpsertArgs> = z.object({
  select: WorkflowItemSelectSchema.optional(),
  where: WorkflowItemWhereUniqueInputSchema,
  create: z.union([ WorkflowItemCreateInputSchema,WorkflowItemUncheckedCreateInputSchema ]),
  update: z.union([ WorkflowItemUpdateInputSchema,WorkflowItemUncheckedUpdateInputSchema ]),
}).strict()

export const WorkflowItemCreateManyArgsSchema: z.ZodType<Prisma.WorkflowItemCreateManyArgs> = z.object({
  data: WorkflowItemCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const WorkflowItemDeleteArgsSchema: z.ZodType<Prisma.WorkflowItemDeleteArgs> = z.object({
  select: WorkflowItemSelectSchema.optional(),
  where: WorkflowItemWhereUniqueInputSchema,
}).strict()

export const WorkflowItemUpdateArgsSchema: z.ZodType<Prisma.WorkflowItemUpdateArgs> = z.object({
  select: WorkflowItemSelectSchema.optional(),
  data: z.union([ WorkflowItemUpdateInputSchema,WorkflowItemUncheckedUpdateInputSchema ]),
  where: WorkflowItemWhereUniqueInputSchema,
}).strict()

export const WorkflowItemUpdateManyArgsSchema: z.ZodType<Prisma.WorkflowItemUpdateManyArgs> = z.object({
  data: z.union([ WorkflowItemUpdateManyMutationInputSchema,WorkflowItemUncheckedUpdateManyInputSchema ]),
  where: WorkflowItemWhereInputSchema.optional(),
}).strict()

export const WorkflowItemDeleteManyArgsSchema: z.ZodType<Prisma.WorkflowItemDeleteManyArgs> = z.object({
  where: WorkflowItemWhereInputSchema.optional(),
}).strict()

export const WorkspaceCreateArgsSchema: z.ZodType<Prisma.WorkspaceCreateArgs> = z.object({
  select: WorkspaceSelectSchema.optional(),
  data: z.union([ WorkspaceCreateInputSchema,WorkspaceUncheckedCreateInputSchema ]),
}).strict()

export const WorkspaceUpsertArgsSchema: z.ZodType<Prisma.WorkspaceUpsertArgs> = z.object({
  select: WorkspaceSelectSchema.optional(),
  where: WorkspaceWhereUniqueInputSchema,
  create: z.union([ WorkspaceCreateInputSchema,WorkspaceUncheckedCreateInputSchema ]),
  update: z.union([ WorkspaceUpdateInputSchema,WorkspaceUncheckedUpdateInputSchema ]),
}).strict()

export const WorkspaceCreateManyArgsSchema: z.ZodType<Prisma.WorkspaceCreateManyArgs> = z.object({
  data: WorkspaceCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const WorkspaceDeleteArgsSchema: z.ZodType<Prisma.WorkspaceDeleteArgs> = z.object({
  select: WorkspaceSelectSchema.optional(),
  where: WorkspaceWhereUniqueInputSchema,
}).strict()

export const WorkspaceUpdateArgsSchema: z.ZodType<Prisma.WorkspaceUpdateArgs> = z.object({
  select: WorkspaceSelectSchema.optional(),
  data: z.union([ WorkspaceUpdateInputSchema,WorkspaceUncheckedUpdateInputSchema ]),
  where: WorkspaceWhereUniqueInputSchema,
}).strict()

export const WorkspaceUpdateManyArgsSchema: z.ZodType<Prisma.WorkspaceUpdateManyArgs> = z.object({
  data: z.union([ WorkspaceUpdateManyMutationInputSchema,WorkspaceUncheckedUpdateManyInputSchema ]),
  where: WorkspaceWhereInputSchema.optional(),
}).strict()

export const WorkspaceDeleteManyArgsSchema: z.ZodType<Prisma.WorkspaceDeleteManyArgs> = z.object({
  where: WorkspaceWhereInputSchema.optional(),
}).strict()

export const WorkspaceCommandAliasCreateArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasCreateArgs> = z.object({
  select: WorkspaceCommandAliasSelectSchema.optional(),
  data: z.union([ WorkspaceCommandAliasCreateInputSchema,WorkspaceCommandAliasUncheckedCreateInputSchema ]),
}).strict()

export const WorkspaceCommandAliasUpsertArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasUpsertArgs> = z.object({
  select: WorkspaceCommandAliasSelectSchema.optional(),
  where: WorkspaceCommandAliasWhereUniqueInputSchema,
  create: z.union([ WorkspaceCommandAliasCreateInputSchema,WorkspaceCommandAliasUncheckedCreateInputSchema ]),
  update: z.union([ WorkspaceCommandAliasUpdateInputSchema,WorkspaceCommandAliasUncheckedUpdateInputSchema ]),
}).strict()

export const WorkspaceCommandAliasCreateManyArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasCreateManyArgs> = z.object({
  data: WorkspaceCommandAliasCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const WorkspaceCommandAliasDeleteArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasDeleteArgs> = z.object({
  select: WorkspaceCommandAliasSelectSchema.optional(),
  where: WorkspaceCommandAliasWhereUniqueInputSchema,
}).strict()

export const WorkspaceCommandAliasUpdateArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasUpdateArgs> = z.object({
  select: WorkspaceCommandAliasSelectSchema.optional(),
  data: z.union([ WorkspaceCommandAliasUpdateInputSchema,WorkspaceCommandAliasUncheckedUpdateInputSchema ]),
  where: WorkspaceCommandAliasWhereUniqueInputSchema,
}).strict()

export const WorkspaceCommandAliasUpdateManyArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasUpdateManyArgs> = z.object({
  data: z.union([ WorkspaceCommandAliasUpdateManyMutationInputSchema,WorkspaceCommandAliasUncheckedUpdateManyInputSchema ]),
  where: WorkspaceCommandAliasWhereInputSchema.optional(),
}).strict()

export const WorkspaceCommandAliasDeleteManyArgsSchema: z.ZodType<Prisma.WorkspaceCommandAliasDeleteManyArgs> = z.object({
  where: WorkspaceCommandAliasWhereInputSchema.optional(),
}).strict()

export const WorkspaceMembershipCreateArgsSchema: z.ZodType<Prisma.WorkspaceMembershipCreateArgs> = z.object({
  select: WorkspaceMembershipSelectSchema.optional(),
  data: z.union([ WorkspaceMembershipCreateInputSchema,WorkspaceMembershipUncheckedCreateInputSchema ]),
}).strict()

export const WorkspaceMembershipUpsertArgsSchema: z.ZodType<Prisma.WorkspaceMembershipUpsertArgs> = z.object({
  select: WorkspaceMembershipSelectSchema.optional(),
  where: WorkspaceMembershipWhereUniqueInputSchema,
  create: z.union([ WorkspaceMembershipCreateInputSchema,WorkspaceMembershipUncheckedCreateInputSchema ]),
  update: z.union([ WorkspaceMembershipUpdateInputSchema,WorkspaceMembershipUncheckedUpdateInputSchema ]),
}).strict()

export const WorkspaceMembershipCreateManyArgsSchema: z.ZodType<Prisma.WorkspaceMembershipCreateManyArgs> = z.object({
  data: WorkspaceMembershipCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const WorkspaceMembershipDeleteArgsSchema: z.ZodType<Prisma.WorkspaceMembershipDeleteArgs> = z.object({
  select: WorkspaceMembershipSelectSchema.optional(),
  where: WorkspaceMembershipWhereUniqueInputSchema,
}).strict()

export const WorkspaceMembershipUpdateArgsSchema: z.ZodType<Prisma.WorkspaceMembershipUpdateArgs> = z.object({
  select: WorkspaceMembershipSelectSchema.optional(),
  data: z.union([ WorkspaceMembershipUpdateInputSchema,WorkspaceMembershipUncheckedUpdateInputSchema ]),
  where: WorkspaceMembershipWhereUniqueInputSchema,
}).strict()

export const WorkspaceMembershipUpdateManyArgsSchema: z.ZodType<Prisma.WorkspaceMembershipUpdateManyArgs> = z.object({
  data: z.union([ WorkspaceMembershipUpdateManyMutationInputSchema,WorkspaceMembershipUncheckedUpdateManyInputSchema ]),
  where: WorkspaceMembershipWhereInputSchema.optional(),
}).strict()

export const WorkspaceMembershipDeleteManyArgsSchema: z.ZodType<Prisma.WorkspaceMembershipDeleteManyArgs> = z.object({
  where: WorkspaceMembershipWhereInputSchema.optional(),
}).strict()

export const WsHandsFreeStatusCreateArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusCreateArgs> = z.object({
  select: WsHandsFreeStatusSelectSchema.optional(),
  data: z.union([ WsHandsFreeStatusCreateInputSchema,WsHandsFreeStatusUncheckedCreateInputSchema ]),
}).strict()

export const WsHandsFreeStatusUpsertArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusUpsertArgs> = z.object({
  select: WsHandsFreeStatusSelectSchema.optional(),
  where: WsHandsFreeStatusWhereUniqueInputSchema,
  create: z.union([ WsHandsFreeStatusCreateInputSchema,WsHandsFreeStatusUncheckedCreateInputSchema ]),
  update: z.union([ WsHandsFreeStatusUpdateInputSchema,WsHandsFreeStatusUncheckedUpdateInputSchema ]),
}).strict()

export const WsHandsFreeStatusCreateManyArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusCreateManyArgs> = z.object({
  data: WsHandsFreeStatusCreateManyInputSchema.array(),
  skipDuplicates: z.boolean().optional(),
}).strict()

export const WsHandsFreeStatusDeleteArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusDeleteArgs> = z.object({
  select: WsHandsFreeStatusSelectSchema.optional(),
  where: WsHandsFreeStatusWhereUniqueInputSchema,
}).strict()

export const WsHandsFreeStatusUpdateArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusUpdateArgs> = z.object({
  select: WsHandsFreeStatusSelectSchema.optional(),
  data: z.union([ WsHandsFreeStatusUpdateInputSchema,WsHandsFreeStatusUncheckedUpdateInputSchema ]),
  where: WsHandsFreeStatusWhereUniqueInputSchema,
}).strict()

export const WsHandsFreeStatusUpdateManyArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusUpdateManyArgs> = z.object({
  data: z.union([ WsHandsFreeStatusUpdateManyMutationInputSchema,WsHandsFreeStatusUncheckedUpdateManyInputSchema ]),
  where: WsHandsFreeStatusWhereInputSchema.optional(),
}).strict()

export const WsHandsFreeStatusDeleteManyArgsSchema: z.ZodType<Prisma.WsHandsFreeStatusDeleteManyArgs> = z.object({
  where: WsHandsFreeStatusWhereInputSchema.optional(),
}).strict()

interface AccountGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.AccountArgs
  readonly type: Prisma.AccountGetPayload<this['_A']>
}

interface AccountEventGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.AccountEventArgs
  readonly type: Prisma.AccountEventGetPayload<this['_A']>
}

interface AppPhoneNumberGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.AppPhoneNumberArgs
  readonly type: Prisma.AppPhoneNumberGetPayload<this['_A']>
}

interface AudioEncodingGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.AudioEncodingArgs
  readonly type: Prisma.AudioEncodingGetPayload<this['_A']>
}

interface AudioQueueItemGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.AudioQueueItemArgs
  readonly type: Prisma.AudioQueueItemGetPayload<this['_A']>
}

interface BroadcastGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.BroadcastArgs
  readonly type: Prisma.BroadcastGetPayload<this['_A']>
}

interface BroadcastActionGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.BroadcastActionArgs
  readonly type: Prisma.BroadcastActionGetPayload<this['_A']>
}

interface BroadcastRecipientGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.BroadcastRecipientArgs
  readonly type: Prisma.BroadcastRecipientGetPayload<this['_A']>
}

interface CallRecordGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.CallRecordArgs
  readonly type: Prisma.CallRecordGetPayload<this['_A']>
}

interface DeviceRegistrationGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.DeviceRegistrationArgs
  readonly type: Prisma.DeviceRegistrationGetPayload<this['_A']>
}

interface DirectWsInvitationGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.DirectWsInvitationArgs
  readonly type: Prisma.DirectWsInvitationGetPayload<this['_A']>
}

interface DisplayArtifactGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.DisplayArtifactArgs
  readonly type: Prisma.DisplayArtifactGetPayload<this['_A']>
}

interface FeedGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.FeedArgs
  readonly type: Prisma.FeedGetPayload<this['_A']>
}

interface FeedGroupGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.FeedGroupArgs
  readonly type: Prisma.FeedGroupGetPayload<this['_A']>
}

interface FeedGroupMembershipGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.FeedGroupMembershipArgs
  readonly type: Prisma.FeedGroupMembershipGetPayload<this['_A']>
}

interface FileGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.FileArgs
  readonly type: Prisma.FileGetPayload<this['_A']>
}

interface ItemGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.ItemArgs
  readonly type: Prisma.ItemGetPayload<this['_A']>
}

interface LinkGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.LinkArgs
  readonly type: Prisma.LinkGetPayload<this['_A']>
}

interface PermissionGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.PermissionArgs
  readonly type: Prisma.PermissionGetPayload<this['_A']>
}

interface PipelineArtifactMetadataGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.PipelineArtifactMetadataArgs
  readonly type: Prisma.PipelineArtifactMetadataGetPayload<this['_A']>
}

interface PublishedWorkflowItemGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.PublishedWorkflowItemArgs
  readonly type: Prisma.PublishedWorkflowItemGetPayload<this['_A']>
}

interface ScheduleTriggerGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.ScheduleTriggerArgs
  readonly type: Prisma.ScheduleTriggerGetPayload<this['_A']>
}

interface TemplateGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.TemplateArgs
  readonly type: Prisma.TemplateGetPayload<this['_A']>
}

interface TranscriptionGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.TranscriptionArgs
  readonly type: Prisma.TranscriptionGetPayload<this['_A']>
}

interface WorkflowItemGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.WorkflowItemArgs
  readonly type: Prisma.WorkflowItemGetPayload<this['_A']>
}

interface WorkspaceGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.WorkspaceArgs
  readonly type: Prisma.WorkspaceGetPayload<this['_A']>
}

interface WorkspaceCommandAliasGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.WorkspaceCommandAliasArgs
  readonly type: Prisma.WorkspaceCommandAliasGetPayload<this['_A']>
}

interface WorkspaceMembershipGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.WorkspaceMembershipArgs
  readonly type: Prisma.WorkspaceMembershipGetPayload<this['_A']>
}

interface WsHandsFreeStatusGetPayload extends HKT {
  readonly _A?: boolean | null | undefined | Prisma.WsHandsFreeStatusArgs
  readonly type: Prisma.WsHandsFreeStatusGetPayload<this['_A']>
}

export const tableSchemas = {
  account: {
    fields: ["id","firstName","lastName","email","emailVerified","phoneNumber","phoneNumberVerified","name","avatarColor","accountType","mine","preferredLanguage"],
    relations: [
    ],
    modelSchema: (AccountCreateInputSchema as any)
      .partial()
      .or((AccountUncheckedCreateInputSchema as any).partial()),
    createSchema: AccountCreateArgsSchema,
    createManySchema: AccountCreateManyArgsSchema,
    findUniqueSchema: AccountFindUniqueArgsSchema,
    findSchema: AccountFindFirstArgsSchema,
    updateSchema: AccountUpdateArgsSchema,
    updateManySchema: AccountUpdateManyArgsSchema,
    upsertSchema: AccountUpsertArgsSchema,
    deleteSchema: AccountDeleteArgsSchema,
    deleteManySchema: AccountDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof AccountCreateInputSchema>,
    Prisma.AccountCreateArgs['data'],
    Prisma.AccountUpdateArgs['data'],
    Prisma.AccountFindFirstArgs['select'],
    Prisma.AccountFindFirstArgs['where'],
    Prisma.AccountFindUniqueArgs['where'],
    never,
    Prisma.AccountFindFirstArgs['orderBy'],
    Prisma.AccountScalarFieldEnum,
    AccountGetPayload
  >,
  account_event: {
    fields: ["id","accountId","name","createdAt","feedId","itemId","contentId"],
    relations: [
    ],
    modelSchema: (AccountEventCreateInputSchema as any)
      .partial()
      .or((AccountEventUncheckedCreateInputSchema as any).partial()),
    createSchema: AccountEventCreateArgsSchema,
    createManySchema: AccountEventCreateManyArgsSchema,
    findUniqueSchema: AccountEventFindUniqueArgsSchema,
    findSchema: AccountEventFindFirstArgsSchema,
    updateSchema: AccountEventUpdateArgsSchema,
    updateManySchema: AccountEventUpdateManyArgsSchema,
    upsertSchema: AccountEventUpsertArgsSchema,
    deleteSchema: AccountEventDeleteArgsSchema,
    deleteManySchema: AccountEventDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof AccountEventCreateInputSchema>,
    Prisma.AccountEventCreateArgs['data'],
    Prisma.AccountEventUpdateArgs['data'],
    Prisma.AccountEventFindFirstArgs['select'],
    Prisma.AccountEventFindFirstArgs['where'],
    Prisma.AccountEventFindUniqueArgs['where'],
    never,
    Prisma.AccountEventFindFirstArgs['orderBy'],
    Prisma.AccountEventScalarFieldEnum,
    AccountEventGetPayload
  >,
  app_phone_number: {
    fields: ["id","phoneNumber","forwardingNumber","feedId"],
    relations: [
    ],
    modelSchema: (AppPhoneNumberCreateInputSchema as any)
      .partial()
      .or((AppPhoneNumberUncheckedCreateInputSchema as any).partial()),
    createSchema: AppPhoneNumberCreateArgsSchema,
    createManySchema: AppPhoneNumberCreateManyArgsSchema,
    findUniqueSchema: AppPhoneNumberFindUniqueArgsSchema,
    findSchema: AppPhoneNumberFindFirstArgsSchema,
    updateSchema: AppPhoneNumberUpdateArgsSchema,
    updateManySchema: AppPhoneNumberUpdateManyArgsSchema,
    upsertSchema: AppPhoneNumberUpsertArgsSchema,
    deleteSchema: AppPhoneNumberDeleteArgsSchema,
    deleteManySchema: AppPhoneNumberDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof AppPhoneNumberCreateInputSchema>,
    Prisma.AppPhoneNumberCreateArgs['data'],
    Prisma.AppPhoneNumberUpdateArgs['data'],
    Prisma.AppPhoneNumberFindFirstArgs['select'],
    Prisma.AppPhoneNumberFindFirstArgs['where'],
    Prisma.AppPhoneNumberFindUniqueArgs['where'],
    never,
    Prisma.AppPhoneNumberFindFirstArgs['orderBy'],
    Prisma.AppPhoneNumberScalarFieldEnum,
    AppPhoneNumberGetPayload
  >,
  audio_encoding: {
    fields: ["id","contentId","codec","mimeType","duration","url","transcriptionId","transcriptionType","language","translatedFrom","priority","generatedVoice","generatedService","createdAt"],
    relations: [
    ],
    modelSchema: (AudioEncodingCreateInputSchema as any)
      .partial()
      .or((AudioEncodingUncheckedCreateInputSchema as any).partial()),
    createSchema: AudioEncodingCreateArgsSchema,
    createManySchema: AudioEncodingCreateManyArgsSchema,
    findUniqueSchema: AudioEncodingFindUniqueArgsSchema,
    findSchema: AudioEncodingFindFirstArgsSchema,
    updateSchema: AudioEncodingUpdateArgsSchema,
    updateManySchema: AudioEncodingUpdateManyArgsSchema,
    upsertSchema: AudioEncodingUpsertArgsSchema,
    deleteSchema: AudioEncodingDeleteArgsSchema,
    deleteManySchema: AudioEncodingDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof AudioEncodingCreateInputSchema>,
    Prisma.AudioEncodingCreateArgs['data'],
    Prisma.AudioEncodingUpdateArgs['data'],
    Prisma.AudioEncodingFindFirstArgs['select'],
    Prisma.AudioEncodingFindFirstArgs['where'],
    Prisma.AudioEncodingFindUniqueArgs['where'],
    never,
    Prisma.AudioEncodingFindFirstArgs['orderBy'],
    Prisma.AudioEncodingScalarFieldEnum,
    AudioEncodingGetPayload
  >,
  audio_queue_item: {
    fields: ["id","itemId","createdAt"],
    relations: [
    ],
    modelSchema: (AudioQueueItemCreateInputSchema as any)
      .partial()
      .or((AudioQueueItemUncheckedCreateInputSchema as any).partial()),
    createSchema: AudioQueueItemCreateArgsSchema,
    createManySchema: AudioQueueItemCreateManyArgsSchema,
    findUniqueSchema: AudioQueueItemFindUniqueArgsSchema,
    findSchema: AudioQueueItemFindFirstArgsSchema,
    updateSchema: AudioQueueItemUpdateArgsSchema,
    updateManySchema: AudioQueueItemUpdateManyArgsSchema,
    upsertSchema: AudioQueueItemUpsertArgsSchema,
    deleteSchema: AudioQueueItemDeleteArgsSchema,
    deleteManySchema: AudioQueueItemDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof AudioQueueItemCreateInputSchema>,
    Prisma.AudioQueueItemCreateArgs['data'],
    Prisma.AudioQueueItemUpdateArgs['data'],
    Prisma.AudioQueueItemFindFirstArgs['select'],
    Prisma.AudioQueueItemFindFirstArgs['where'],
    Prisma.AudioQueueItemFindUniqueArgs['where'],
    never,
    Prisma.AudioQueueItemFindFirstArgs['orderBy'],
    Prisma.AudioQueueItemScalarFieldEnum,
    AudioQueueItemGetPayload
  >,
  broadcast: {
    fields: ["id","workspaceId","createdAt"],
    relations: [
    ],
    modelSchema: (BroadcastCreateInputSchema as any)
      .partial()
      .or((BroadcastUncheckedCreateInputSchema as any).partial()),
    createSchema: BroadcastCreateArgsSchema,
    createManySchema: BroadcastCreateManyArgsSchema,
    findUniqueSchema: BroadcastFindUniqueArgsSchema,
    findSchema: BroadcastFindFirstArgsSchema,
    updateSchema: BroadcastUpdateArgsSchema,
    updateManySchema: BroadcastUpdateManyArgsSchema,
    upsertSchema: BroadcastUpsertArgsSchema,
    deleteSchema: BroadcastDeleteArgsSchema,
    deleteManySchema: BroadcastDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof BroadcastCreateInputSchema>,
    Prisma.BroadcastCreateArgs['data'],
    Prisma.BroadcastUpdateArgs['data'],
    Prisma.BroadcastFindFirstArgs['select'],
    Prisma.BroadcastFindFirstArgs['where'],
    Prisma.BroadcastFindUniqueArgs['where'],
    never,
    Prisma.BroadcastFindFirstArgs['orderBy'],
    Prisma.BroadcastScalarFieldEnum,
    BroadcastGetPayload
  >,
  broadcast_action: {
    fields: ["id","workspaceId","workflowItemId","broadcastId","createdAt","updatedAt"],
    relations: [
    ],
    modelSchema: (BroadcastActionCreateInputSchema as any)
      .partial()
      .or((BroadcastActionUncheckedCreateInputSchema as any).partial()),
    createSchema: BroadcastActionCreateArgsSchema,
    createManySchema: BroadcastActionCreateManyArgsSchema,
    findUniqueSchema: BroadcastActionFindUniqueArgsSchema,
    findSchema: BroadcastActionFindFirstArgsSchema,
    updateSchema: BroadcastActionUpdateArgsSchema,
    updateManySchema: BroadcastActionUpdateManyArgsSchema,
    upsertSchema: BroadcastActionUpsertArgsSchema,
    deleteSchema: BroadcastActionDeleteArgsSchema,
    deleteManySchema: BroadcastActionDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof BroadcastActionCreateInputSchema>,
    Prisma.BroadcastActionCreateArgs['data'],
    Prisma.BroadcastActionUpdateArgs['data'],
    Prisma.BroadcastActionFindFirstArgs['select'],
    Prisma.BroadcastActionFindFirstArgs['where'],
    Prisma.BroadcastActionFindUniqueArgs['where'],
    never,
    Prisma.BroadcastActionFindFirstArgs['orderBy'],
    Prisma.BroadcastActionScalarFieldEnum,
    BroadcastActionGetPayload
  >,
  broadcast_recipient: {
    fields: ["id","broadcastId","workspaceId","feedId","workspaceMembershipId","createdAt"],
    relations: [
    ],
    modelSchema: (BroadcastRecipientCreateInputSchema as any)
      .partial()
      .or((BroadcastRecipientUncheckedCreateInputSchema as any).partial()),
    createSchema: BroadcastRecipientCreateArgsSchema,
    createManySchema: BroadcastRecipientCreateManyArgsSchema,
    findUniqueSchema: BroadcastRecipientFindUniqueArgsSchema,
    findSchema: BroadcastRecipientFindFirstArgsSchema,
    updateSchema: BroadcastRecipientUpdateArgsSchema,
    updateManySchema: BroadcastRecipientUpdateManyArgsSchema,
    upsertSchema: BroadcastRecipientUpsertArgsSchema,
    deleteSchema: BroadcastRecipientDeleteArgsSchema,
    deleteManySchema: BroadcastRecipientDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof BroadcastRecipientCreateInputSchema>,
    Prisma.BroadcastRecipientCreateArgs['data'],
    Prisma.BroadcastRecipientUpdateArgs['data'],
    Prisma.BroadcastRecipientFindFirstArgs['select'],
    Prisma.BroadcastRecipientFindFirstArgs['where'],
    Prisma.BroadcastRecipientFindUniqueArgs['where'],
    never,
    Prisma.BroadcastRecipientFindFirstArgs['orderBy'],
    Prisma.BroadcastRecipientScalarFieldEnum,
    BroadcastRecipientGetPayload
  >,
  call_record: {
    fields: ["id","contentId","appPhoneNumberId","toNumber","fromNumber","direction"],
    relations: [
    ],
    modelSchema: (CallRecordCreateInputSchema as any)
      .partial()
      .or((CallRecordUncheckedCreateInputSchema as any).partial()),
    createSchema: CallRecordCreateArgsSchema,
    createManySchema: CallRecordCreateManyArgsSchema,
    findUniqueSchema: CallRecordFindUniqueArgsSchema,
    findSchema: CallRecordFindFirstArgsSchema,
    updateSchema: CallRecordUpdateArgsSchema,
    updateManySchema: CallRecordUpdateManyArgsSchema,
    upsertSchema: CallRecordUpsertArgsSchema,
    deleteSchema: CallRecordDeleteArgsSchema,
    deleteManySchema: CallRecordDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof CallRecordCreateInputSchema>,
    Prisma.CallRecordCreateArgs['data'],
    Prisma.CallRecordUpdateArgs['data'],
    Prisma.CallRecordFindFirstArgs['select'],
    Prisma.CallRecordFindFirstArgs['where'],
    Prisma.CallRecordFindUniqueArgs['where'],
    never,
    Prisma.CallRecordFindFirstArgs['orderBy'],
    Prisma.CallRecordScalarFieldEnum,
    CallRecordGetPayload
  >,
  device_registration: {
    fields: ["id","createdAt","updatedAt","surface","deviceOsName","deviceOsVersion","deviceModel","friendlyName","drivingDetectionEnabled","wakeWordSensitivity","commandSensitivity","silenceDetectionInitialThreshold","silenceDetectionFinalThreshold","commandTimingWindowMs","recordingTimeoutWindowMs"],
    relations: [
    ],
    modelSchema: (DeviceRegistrationCreateInputSchema as any)
      .partial()
      .or((DeviceRegistrationUncheckedCreateInputSchema as any).partial()),
    createSchema: DeviceRegistrationCreateArgsSchema,
    createManySchema: DeviceRegistrationCreateManyArgsSchema,
    findUniqueSchema: DeviceRegistrationFindUniqueArgsSchema,
    findSchema: DeviceRegistrationFindFirstArgsSchema,
    updateSchema: DeviceRegistrationUpdateArgsSchema,
    updateManySchema: DeviceRegistrationUpdateManyArgsSchema,
    upsertSchema: DeviceRegistrationUpsertArgsSchema,
    deleteSchema: DeviceRegistrationDeleteArgsSchema,
    deleteManySchema: DeviceRegistrationDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof DeviceRegistrationCreateInputSchema>,
    Prisma.DeviceRegistrationCreateArgs['data'],
    Prisma.DeviceRegistrationUpdateArgs['data'],
    Prisma.DeviceRegistrationFindFirstArgs['select'],
    Prisma.DeviceRegistrationFindFirstArgs['where'],
    Prisma.DeviceRegistrationFindUniqueArgs['where'],
    never,
    Prisma.DeviceRegistrationFindFirstArgs['orderBy'],
    Prisma.DeviceRegistrationScalarFieldEnum,
    DeviceRegistrationGetPayload
  >,
  direct_ws_invitation: {
    fields: ["id","workspaceMembershipId","email","phoneNumber","name","claimedAt","claimedBy","createdAt","updatedAt"],
    relations: [
    ],
    modelSchema: (DirectWsInvitationCreateInputSchema as any)
      .partial()
      .or((DirectWsInvitationUncheckedCreateInputSchema as any).partial()),
    createSchema: DirectWsInvitationCreateArgsSchema,
    createManySchema: DirectWsInvitationCreateManyArgsSchema,
    findUniqueSchema: DirectWsInvitationFindUniqueArgsSchema,
    findSchema: DirectWsInvitationFindFirstArgsSchema,
    updateSchema: DirectWsInvitationUpdateArgsSchema,
    updateManySchema: DirectWsInvitationUpdateManyArgsSchema,
    upsertSchema: DirectWsInvitationUpsertArgsSchema,
    deleteSchema: DirectWsInvitationDeleteArgsSchema,
    deleteManySchema: DirectWsInvitationDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof DirectWsInvitationCreateInputSchema>,
    Prisma.DirectWsInvitationCreateArgs['data'],
    Prisma.DirectWsInvitationUpdateArgs['data'],
    Prisma.DirectWsInvitationFindFirstArgs['select'],
    Prisma.DirectWsInvitationFindFirstArgs['where'],
    Prisma.DirectWsInvitationFindUniqueArgs['where'],
    never,
    Prisma.DirectWsInvitationFindFirstArgs['orderBy'],
    Prisma.DirectWsInvitationScalarFieldEnum,
    DirectWsInvitationGetPayload
  >,
  display_artifact: {
    fields: ["id","title","description","contentId","deletedAt","createdAt"],
    relations: [
    ],
    modelSchema: (DisplayArtifactCreateInputSchema as any)
      .partial()
      .or((DisplayArtifactUncheckedCreateInputSchema as any).partial()),
    createSchema: DisplayArtifactCreateArgsSchema,
    createManySchema: DisplayArtifactCreateManyArgsSchema,
    findUniqueSchema: DisplayArtifactFindUniqueArgsSchema,
    findSchema: DisplayArtifactFindFirstArgsSchema,
    updateSchema: DisplayArtifactUpdateArgsSchema,
    updateManySchema: DisplayArtifactUpdateManyArgsSchema,
    upsertSchema: DisplayArtifactUpsertArgsSchema,
    deleteSchema: DisplayArtifactDeleteArgsSchema,
    deleteManySchema: DisplayArtifactDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof DisplayArtifactCreateInputSchema>,
    Prisma.DisplayArtifactCreateArgs['data'],
    Prisma.DisplayArtifactUpdateArgs['data'],
    Prisma.DisplayArtifactFindFirstArgs['select'],
    Prisma.DisplayArtifactFindFirstArgs['where'],
    Prisma.DisplayArtifactFindUniqueArgs['where'],
    never,
    Prisma.DisplayArtifactFindFirstArgs['orderBy'],
    Prisma.DisplayArtifactScalarFieldEnum,
    DisplayArtifactGetPayload
  >,
  feed: {
    fields: ["id","title","workspaceId","feedType","readOnly","isPrivate","isDm","isSilent","loadedFirstPage","loadedLastPage","loadedEvents","loadedPermissions","createdAt","updatedAt","latestActivity","lastOpened"],
    relations: [
    ],
    modelSchema: (FeedCreateInputSchema as any)
      .partial()
      .or((FeedUncheckedCreateInputSchema as any).partial()),
    createSchema: FeedCreateArgsSchema,
    createManySchema: FeedCreateManyArgsSchema,
    findUniqueSchema: FeedFindUniqueArgsSchema,
    findSchema: FeedFindFirstArgsSchema,
    updateSchema: FeedUpdateArgsSchema,
    updateManySchema: FeedUpdateManyArgsSchema,
    upsertSchema: FeedUpsertArgsSchema,
    deleteSchema: FeedDeleteArgsSchema,
    deleteManySchema: FeedDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof FeedCreateInputSchema>,
    Prisma.FeedCreateArgs['data'],
    Prisma.FeedUpdateArgs['data'],
    Prisma.FeedFindFirstArgs['select'],
    Prisma.FeedFindFirstArgs['where'],
    Prisma.FeedFindUniqueArgs['where'],
    never,
    Prisma.FeedFindFirstArgs['orderBy'],
    Prisma.FeedScalarFieldEnum,
    FeedGetPayload
  >,
  feed_group: {
    fields: ["id","name","accountId","workspaceId","createdAt","updatedAt","enabled"],
    relations: [
    ],
    modelSchema: (FeedGroupCreateInputSchema as any)
      .partial()
      .or((FeedGroupUncheckedCreateInputSchema as any).partial()),
    createSchema: FeedGroupCreateArgsSchema,
    createManySchema: FeedGroupCreateManyArgsSchema,
    findUniqueSchema: FeedGroupFindUniqueArgsSchema,
    findSchema: FeedGroupFindFirstArgsSchema,
    updateSchema: FeedGroupUpdateArgsSchema,
    updateManySchema: FeedGroupUpdateManyArgsSchema,
    upsertSchema: FeedGroupUpsertArgsSchema,
    deleteSchema: FeedGroupDeleteArgsSchema,
    deleteManySchema: FeedGroupDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof FeedGroupCreateInputSchema>,
    Prisma.FeedGroupCreateArgs['data'],
    Prisma.FeedGroupUpdateArgs['data'],
    Prisma.FeedGroupFindFirstArgs['select'],
    Prisma.FeedGroupFindFirstArgs['where'],
    Prisma.FeedGroupFindUniqueArgs['where'],
    never,
    Prisma.FeedGroupFindFirstArgs['orderBy'],
    Prisma.FeedGroupScalarFieldEnum,
    FeedGroupGetPayload
  >,
  feed_group_membership: {
    fields: ["id","workspaceId","feedId","groupId","createdAt","updatedAt","enabled"],
    relations: [
    ],
    modelSchema: (FeedGroupMembershipCreateInputSchema as any)
      .partial()
      .or((FeedGroupMembershipUncheckedCreateInputSchema as any).partial()),
    createSchema: FeedGroupMembershipCreateArgsSchema,
    createManySchema: FeedGroupMembershipCreateManyArgsSchema,
    findUniqueSchema: FeedGroupMembershipFindUniqueArgsSchema,
    findSchema: FeedGroupMembershipFindFirstArgsSchema,
    updateSchema: FeedGroupMembershipUpdateArgsSchema,
    updateManySchema: FeedGroupMembershipUpdateManyArgsSchema,
    upsertSchema: FeedGroupMembershipUpsertArgsSchema,
    deleteSchema: FeedGroupMembershipDeleteArgsSchema,
    deleteManySchema: FeedGroupMembershipDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof FeedGroupMembershipCreateInputSchema>,
    Prisma.FeedGroupMembershipCreateArgs['data'],
    Prisma.FeedGroupMembershipUpdateArgs['data'],
    Prisma.FeedGroupMembershipFindFirstArgs['select'],
    Prisma.FeedGroupMembershipFindFirstArgs['where'],
    Prisma.FeedGroupMembershipFindUniqueArgs['where'],
    never,
    Prisma.FeedGroupMembershipFindFirstArgs['orderBy'],
    Prisma.FeedGroupMembershipScalarFieldEnum,
    FeedGroupMembershipGetPayload
  >,
  file: {
    fields: ["id","contentId","url","mimeType","name"],
    relations: [
    ],
    modelSchema: (FileCreateInputSchema as any)
      .partial()
      .or((FileUncheckedCreateInputSchema as any).partial()),
    createSchema: FileCreateArgsSchema,
    createManySchema: FileCreateManyArgsSchema,
    findUniqueSchema: FileFindUniqueArgsSchema,
    findSchema: FileFindFirstArgsSchema,
    updateSchema: FileUpdateArgsSchema,
    updateManySchema: FileUpdateManyArgsSchema,
    upsertSchema: FileUpsertArgsSchema,
    deleteSchema: FileDeleteArgsSchema,
    deleteManySchema: FileDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof FileCreateInputSchema>,
    Prisma.FileCreateArgs['data'],
    Prisma.FileUpdateArgs['data'],
    Prisma.FileFindFirstArgs['select'],
    Prisma.FileFindFirstArgs['where'],
    Prisma.FileFindUniqueArgs['where'],
    never,
    Prisma.FileFindFirstArgs['orderBy'],
    Prisma.FileScalarFieldEnum,
    FileGetPayload
  >,
  item: {
    fields: ["id","feedId","contentId","groupId","deletedAt","accountId","loadedContent","isSilent","unread","isDriverMessage","isOrganizerMessage","isAdminMessage","createdAt","status"],
    relations: [
    ],
    modelSchema: (ItemCreateInputSchema as any)
      .partial()
      .or((ItemUncheckedCreateInputSchema as any).partial()),
    createSchema: ItemCreateArgsSchema,
    createManySchema: ItemCreateManyArgsSchema,
    findUniqueSchema: ItemFindUniqueArgsSchema,
    findSchema: ItemFindFirstArgsSchema,
    updateSchema: ItemUpdateArgsSchema,
    updateManySchema: ItemUpdateManyArgsSchema,
    upsertSchema: ItemUpsertArgsSchema,
    deleteSchema: ItemDeleteArgsSchema,
    deleteManySchema: ItemDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof ItemCreateInputSchema>,
    Prisma.ItemCreateArgs['data'],
    Prisma.ItemUpdateArgs['data'],
    Prisma.ItemFindFirstArgs['select'],
    Prisma.ItemFindFirstArgs['where'],
    Prisma.ItemFindUniqueArgs['where'],
    never,
    Prisma.ItemFindFirstArgs['orderBy'],
    Prisma.ItemScalarFieldEnum,
    ItemGetPayload
  >,
  link: {
    fields: ["id","contentId","url","description","title","image","linkOrder"],
    relations: [
    ],
    modelSchema: (LinkCreateInputSchema as any)
      .partial()
      .or((LinkUncheckedCreateInputSchema as any).partial()),
    createSchema: LinkCreateArgsSchema,
    createManySchema: LinkCreateManyArgsSchema,
    findUniqueSchema: LinkFindUniqueArgsSchema,
    findSchema: LinkFindFirstArgsSchema,
    updateSchema: LinkUpdateArgsSchema,
    updateManySchema: LinkUpdateManyArgsSchema,
    upsertSchema: LinkUpsertArgsSchema,
    deleteSchema: LinkDeleteArgsSchema,
    deleteManySchema: LinkDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof LinkCreateInputSchema>,
    Prisma.LinkCreateArgs['data'],
    Prisma.LinkUpdateArgs['data'],
    Prisma.LinkFindFirstArgs['select'],
    Prisma.LinkFindFirstArgs['where'],
    Prisma.LinkFindUniqueArgs['where'],
    never,
    Prisma.LinkFindFirstArgs['orderBy'],
    Prisma.LinkScalarFieldEnum,
    LinkGetPayload
  >,
  permission: {
    fields: ["id","name","accountId","workspace_membershipId","feedId","workflowItemId","enabled","expiresAt","createdAt","updatedAt"],
    relations: [
    ],
    modelSchema: (PermissionCreateInputSchema as any)
      .partial()
      .or((PermissionUncheckedCreateInputSchema as any).partial()),
    createSchema: PermissionCreateArgsSchema,
    createManySchema: PermissionCreateManyArgsSchema,
    findUniqueSchema: PermissionFindUniqueArgsSchema,
    findSchema: PermissionFindFirstArgsSchema,
    updateSchema: PermissionUpdateArgsSchema,
    updateManySchema: PermissionUpdateManyArgsSchema,
    upsertSchema: PermissionUpsertArgsSchema,
    deleteSchema: PermissionDeleteArgsSchema,
    deleteManySchema: PermissionDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof PermissionCreateInputSchema>,
    Prisma.PermissionCreateArgs['data'],
    Prisma.PermissionUpdateArgs['data'],
    Prisma.PermissionFindFirstArgs['select'],
    Prisma.PermissionFindFirstArgs['where'],
    Prisma.PermissionFindUniqueArgs['where'],
    never,
    Prisma.PermissionFindFirstArgs['orderBy'],
    Prisma.PermissionScalarFieldEnum,
    PermissionGetPayload
  >,
  pipeline_artifact_metadata: {
    fields: ["id","contentId","vadResult","createdAt","updatedAt"],
    relations: [
    ],
    modelSchema: (PipelineArtifactMetadataCreateInputSchema as any)
      .partial()
      .or((PipelineArtifactMetadataUncheckedCreateInputSchema as any).partial()),
    createSchema: PipelineArtifactMetadataCreateArgsSchema,
    createManySchema: PipelineArtifactMetadataCreateManyArgsSchema,
    findUniqueSchema: PipelineArtifactMetadataFindUniqueArgsSchema,
    findSchema: PipelineArtifactMetadataFindFirstArgsSchema,
    updateSchema: PipelineArtifactMetadataUpdateArgsSchema,
    updateManySchema: PipelineArtifactMetadataUpdateManyArgsSchema,
    upsertSchema: PipelineArtifactMetadataUpsertArgsSchema,
    deleteSchema: PipelineArtifactMetadataDeleteArgsSchema,
    deleteManySchema: PipelineArtifactMetadataDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof PipelineArtifactMetadataCreateInputSchema>,
    Prisma.PipelineArtifactMetadataCreateArgs['data'],
    Prisma.PipelineArtifactMetadataUpdateArgs['data'],
    Prisma.PipelineArtifactMetadataFindFirstArgs['select'],
    Prisma.PipelineArtifactMetadataFindFirstArgs['where'],
    Prisma.PipelineArtifactMetadataFindUniqueArgs['where'],
    never,
    Prisma.PipelineArtifactMetadataFindFirstArgs['orderBy'],
    Prisma.PipelineArtifactMetadataScalarFieldEnum,
    PipelineArtifactMetadataGetPayload
  >,
  published_workflow_item: {
    fields: ["id","workflowItemId","broadcastId","accountId","workspaceId","contentId","createdAt"],
    relations: [
    ],
    modelSchema: (PublishedWorkflowItemCreateInputSchema as any)
      .partial()
      .or((PublishedWorkflowItemUncheckedCreateInputSchema as any).partial()),
    createSchema: PublishedWorkflowItemCreateArgsSchema,
    createManySchema: PublishedWorkflowItemCreateManyArgsSchema,
    findUniqueSchema: PublishedWorkflowItemFindUniqueArgsSchema,
    findSchema: PublishedWorkflowItemFindFirstArgsSchema,
    updateSchema: PublishedWorkflowItemUpdateArgsSchema,
    updateManySchema: PublishedWorkflowItemUpdateManyArgsSchema,
    upsertSchema: PublishedWorkflowItemUpsertArgsSchema,
    deleteSchema: PublishedWorkflowItemDeleteArgsSchema,
    deleteManySchema: PublishedWorkflowItemDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof PublishedWorkflowItemCreateInputSchema>,
    Prisma.PublishedWorkflowItemCreateArgs['data'],
    Prisma.PublishedWorkflowItemUpdateArgs['data'],
    Prisma.PublishedWorkflowItemFindFirstArgs['select'],
    Prisma.PublishedWorkflowItemFindFirstArgs['where'],
    Prisma.PublishedWorkflowItemFindUniqueArgs['where'],
    never,
    Prisma.PublishedWorkflowItemFindFirstArgs['orderBy'],
    Prisma.PublishedWorkflowItemScalarFieldEnum,
    PublishedWorkflowItemGetPayload
  >,
  schedule_trigger: {
    fields: ["id","oneTimeSchedule","cronSchedule","workspaceId","broadcastActionId","createdAt","updatedAt","timezone","enabled"],
    relations: [
    ],
    modelSchema: (ScheduleTriggerCreateInputSchema as any)
      .partial()
      .or((ScheduleTriggerUncheckedCreateInputSchema as any).partial()),
    createSchema: ScheduleTriggerCreateArgsSchema,
    createManySchema: ScheduleTriggerCreateManyArgsSchema,
    findUniqueSchema: ScheduleTriggerFindUniqueArgsSchema,
    findSchema: ScheduleTriggerFindFirstArgsSchema,
    updateSchema: ScheduleTriggerUpdateArgsSchema,
    updateManySchema: ScheduleTriggerUpdateManyArgsSchema,
    upsertSchema: ScheduleTriggerUpsertArgsSchema,
    deleteSchema: ScheduleTriggerDeleteArgsSchema,
    deleteManySchema: ScheduleTriggerDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof ScheduleTriggerCreateInputSchema>,
    Prisma.ScheduleTriggerCreateArgs['data'],
    Prisma.ScheduleTriggerUpdateArgs['data'],
    Prisma.ScheduleTriggerFindFirstArgs['select'],
    Prisma.ScheduleTriggerFindFirstArgs['where'],
    Prisma.ScheduleTriggerFindUniqueArgs['where'],
    never,
    Prisma.ScheduleTriggerFindFirstArgs['orderBy'],
    Prisma.ScheduleTriggerScalarFieldEnum,
    ScheduleTriggerGetPayload
  >,
  template: {
    fields: ["id","name","template","workspaceId","authorId","createdAt","updatedAt","deletedAt"],
    relations: [
    ],
    modelSchema: (TemplateCreateInputSchema as any)
      .partial()
      .or((TemplateUncheckedCreateInputSchema as any).partial()),
    createSchema: TemplateCreateArgsSchema,
    createManySchema: TemplateCreateManyArgsSchema,
    findUniqueSchema: TemplateFindUniqueArgsSchema,
    findSchema: TemplateFindFirstArgsSchema,
    updateSchema: TemplateUpdateArgsSchema,
    updateManySchema: TemplateUpdateManyArgsSchema,
    upsertSchema: TemplateUpsertArgsSchema,
    deleteSchema: TemplateDeleteArgsSchema,
    deleteManySchema: TemplateDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof TemplateCreateInputSchema>,
    Prisma.TemplateCreateArgs['data'],
    Prisma.TemplateUpdateArgs['data'],
    Prisma.TemplateFindFirstArgs['select'],
    Prisma.TemplateFindFirstArgs['where'],
    Prisma.TemplateFindUniqueArgs['where'],
    never,
    Prisma.TemplateFindFirstArgs['orderBy'],
    Prisma.TemplateScalarFieldEnum,
    TemplateGetPayload
  >,
  transcription: {
    fields: ["id","contentId","transcriptionType","transcriptionContent","language","translatedFrom","backendModel","priority","confidence","executionTime","format","pipeline","bucket","key","url","createdAt"],
    relations: [
    ],
    modelSchema: (TranscriptionCreateInputSchema as any)
      .partial()
      .or((TranscriptionUncheckedCreateInputSchema as any).partial()),
    createSchema: TranscriptionCreateArgsSchema,
    createManySchema: TranscriptionCreateManyArgsSchema,
    findUniqueSchema: TranscriptionFindUniqueArgsSchema,
    findSchema: TranscriptionFindFirstArgsSchema,
    updateSchema: TranscriptionUpdateArgsSchema,
    updateManySchema: TranscriptionUpdateManyArgsSchema,
    upsertSchema: TranscriptionUpsertArgsSchema,
    deleteSchema: TranscriptionDeleteArgsSchema,
    deleteManySchema: TranscriptionDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof TranscriptionCreateInputSchema>,
    Prisma.TranscriptionCreateArgs['data'],
    Prisma.TranscriptionUpdateArgs['data'],
    Prisma.TranscriptionFindFirstArgs['select'],
    Prisma.TranscriptionFindFirstArgs['where'],
    Prisma.TranscriptionFindUniqueArgs['where'],
    never,
    Prisma.TranscriptionFindFirstArgs['orderBy'],
    Prisma.TranscriptionScalarFieldEnum,
    TranscriptionGetPayload
  >,
  workflow_item: {
    fields: ["id","workspaceId","contentId","createdAt","deletedAt","displayName"],
    relations: [
    ],
    modelSchema: (WorkflowItemCreateInputSchema as any)
      .partial()
      .or((WorkflowItemUncheckedCreateInputSchema as any).partial()),
    createSchema: WorkflowItemCreateArgsSchema,
    createManySchema: WorkflowItemCreateManyArgsSchema,
    findUniqueSchema: WorkflowItemFindUniqueArgsSchema,
    findSchema: WorkflowItemFindFirstArgsSchema,
    updateSchema: WorkflowItemUpdateArgsSchema,
    updateManySchema: WorkflowItemUpdateManyArgsSchema,
    upsertSchema: WorkflowItemUpsertArgsSchema,
    deleteSchema: WorkflowItemDeleteArgsSchema,
    deleteManySchema: WorkflowItemDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof WorkflowItemCreateInputSchema>,
    Prisma.WorkflowItemCreateArgs['data'],
    Prisma.WorkflowItemUpdateArgs['data'],
    Prisma.WorkflowItemFindFirstArgs['select'],
    Prisma.WorkflowItemFindFirstArgs['where'],
    Prisma.WorkflowItemFindUniqueArgs['where'],
    never,
    Prisma.WorkflowItemFindFirstArgs['orderBy'],
    Prisma.WorkflowItemScalarFieldEnum,
    WorkflowItemGetPayload
  >,
  workspace: {
    fields: ["id","name"],
    relations: [
    ],
    modelSchema: (WorkspaceCreateInputSchema as any)
      .partial()
      .or((WorkspaceUncheckedCreateInputSchema as any).partial()),
    createSchema: WorkspaceCreateArgsSchema,
    createManySchema: WorkspaceCreateManyArgsSchema,
    findUniqueSchema: WorkspaceFindUniqueArgsSchema,
    findSchema: WorkspaceFindFirstArgsSchema,
    updateSchema: WorkspaceUpdateArgsSchema,
    updateManySchema: WorkspaceUpdateManyArgsSchema,
    upsertSchema: WorkspaceUpsertArgsSchema,
    deleteSchema: WorkspaceDeleteArgsSchema,
    deleteManySchema: WorkspaceDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof WorkspaceCreateInputSchema>,
    Prisma.WorkspaceCreateArgs['data'],
    Prisma.WorkspaceUpdateArgs['data'],
    Prisma.WorkspaceFindFirstArgs['select'],
    Prisma.WorkspaceFindFirstArgs['where'],
    Prisma.WorkspaceFindUniqueArgs['where'],
    never,
    Prisma.WorkspaceFindFirstArgs['orderBy'],
    Prisma.WorkspaceScalarFieldEnum,
    WorkspaceGetPayload
  >,
  workspace_command_alias: {
    fields: ["id","alias","workspaceId","feedId","workspaceMembershipId","createdAt"],
    relations: [
    ],
    modelSchema: (WorkspaceCommandAliasCreateInputSchema as any)
      .partial()
      .or((WorkspaceCommandAliasUncheckedCreateInputSchema as any).partial()),
    createSchema: WorkspaceCommandAliasCreateArgsSchema,
    createManySchema: WorkspaceCommandAliasCreateManyArgsSchema,
    findUniqueSchema: WorkspaceCommandAliasFindUniqueArgsSchema,
    findSchema: WorkspaceCommandAliasFindFirstArgsSchema,
    updateSchema: WorkspaceCommandAliasUpdateArgsSchema,
    updateManySchema: WorkspaceCommandAliasUpdateManyArgsSchema,
    upsertSchema: WorkspaceCommandAliasUpsertArgsSchema,
    deleteSchema: WorkspaceCommandAliasDeleteArgsSchema,
    deleteManySchema: WorkspaceCommandAliasDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof WorkspaceCommandAliasCreateInputSchema>,
    Prisma.WorkspaceCommandAliasCreateArgs['data'],
    Prisma.WorkspaceCommandAliasUpdateArgs['data'],
    Prisma.WorkspaceCommandAliasFindFirstArgs['select'],
    Prisma.WorkspaceCommandAliasFindFirstArgs['where'],
    Prisma.WorkspaceCommandAliasFindUniqueArgs['where'],
    never,
    Prisma.WorkspaceCommandAliasFindFirstArgs['orderBy'],
    Prisma.WorkspaceCommandAliasScalarFieldEnum,
    WorkspaceCommandAliasGetPayload
  >,
  workspace_membership: {
    fields: ["id","workspaceId","accountId","status","role"],
    relations: [
    ],
    modelSchema: (WorkspaceMembershipCreateInputSchema as any)
      .partial()
      .or((WorkspaceMembershipUncheckedCreateInputSchema as any).partial()),
    createSchema: WorkspaceMembershipCreateArgsSchema,
    createManySchema: WorkspaceMembershipCreateManyArgsSchema,
    findUniqueSchema: WorkspaceMembershipFindUniqueArgsSchema,
    findSchema: WorkspaceMembershipFindFirstArgsSchema,
    updateSchema: WorkspaceMembershipUpdateArgsSchema,
    updateManySchema: WorkspaceMembershipUpdateManyArgsSchema,
    upsertSchema: WorkspaceMembershipUpsertArgsSchema,
    deleteSchema: WorkspaceMembershipDeleteArgsSchema,
    deleteManySchema: WorkspaceMembershipDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof WorkspaceMembershipCreateInputSchema>,
    Prisma.WorkspaceMembershipCreateArgs['data'],
    Prisma.WorkspaceMembershipUpdateArgs['data'],
    Prisma.WorkspaceMembershipFindFirstArgs['select'],
    Prisma.WorkspaceMembershipFindFirstArgs['where'],
    Prisma.WorkspaceMembershipFindUniqueArgs['where'],
    never,
    Prisma.WorkspaceMembershipFindFirstArgs['orderBy'],
    Prisma.WorkspaceMembershipScalarFieldEnum,
    WorkspaceMembershipGetPayload
  >,
  ws_hands_free_status: {
    fields: ["id","enabled","timestamp"],
    relations: [
    ],
    modelSchema: (WsHandsFreeStatusCreateInputSchema as any)
      .partial()
      .or((WsHandsFreeStatusUncheckedCreateInputSchema as any).partial()),
    createSchema: WsHandsFreeStatusCreateArgsSchema,
    createManySchema: WsHandsFreeStatusCreateManyArgsSchema,
    findUniqueSchema: WsHandsFreeStatusFindUniqueArgsSchema,
    findSchema: WsHandsFreeStatusFindFirstArgsSchema,
    updateSchema: WsHandsFreeStatusUpdateArgsSchema,
    updateManySchema: WsHandsFreeStatusUpdateManyArgsSchema,
    upsertSchema: WsHandsFreeStatusUpsertArgsSchema,
    deleteSchema: WsHandsFreeStatusDeleteArgsSchema,
    deleteManySchema: WsHandsFreeStatusDeleteManyArgsSchema
  } as TableSchema<
    z.infer<typeof WsHandsFreeStatusCreateInputSchema>,
    Prisma.WsHandsFreeStatusCreateArgs['data'],
    Prisma.WsHandsFreeStatusUpdateArgs['data'],
    Prisma.WsHandsFreeStatusFindFirstArgs['select'],
    Prisma.WsHandsFreeStatusFindFirstArgs['where'],
    Prisma.WsHandsFreeStatusFindUniqueArgs['where'],
    never,
    Prisma.WsHandsFreeStatusFindFirstArgs['orderBy'],
    Prisma.WsHandsFreeStatusScalarFieldEnum,
    WsHandsFreeStatusGetPayload
  >,
}

export const schema = new DbSchema(tableSchemas, migrations)
export type Electric = ElectricClient<typeof schema>
