import UseContextMenu from "@/components/ContextMenus/UseContextMenu";
import type { Feed } from "@/generated/client";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { UxContext } from "@/models/UxStateProvider";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import type { SxProps } from "@mui/system";
import { useContext, useState } from "react";
import type * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ListIcon from "../Icons/ListIcon";

export default function ChannelContextMenu({
  joined,
  children,
  sx,
  feed,
  changeJoined,
  membershipId,
  markAllChannelsAsRead,
  joiningHandler,
}: {
  joined: boolean;
  children: React.JSX.Element;
  sx?: SxProps;
  feed: Feed;
  changeJoined: (value: boolean) => void;
  membershipId: string;
  markAllChannelsAsRead?: () => void;
  joiningHandler?: Function;
}) {
  const { fetchNextActiveChannel } = useContext(LiveQueryContext);
  const { userReadOnlyMode } = useContext(UxContext);
  const params = useParams();
  const navigate = useNavigate();
  const {
    joinPublicChannel,
    leavePublicChannel,
    myAccount,
    isWorkspaceLimitedMember,
  } = useContext(DataContext);
  const limitedMember = isWorkspaceLimitedMember(
    feed?.workspaceId,
    myAccount?.id,
  );
  const { accountEvent } = useContext(ActionContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { handleContextMenu, handleClose, defaultStyles, contextMenuItem } =
    UseContextMenu();
  if (!feed?.id) {
    return;
  }
  const { id, workspaceId } = feed;

  const joinOrLeaveChannel = async () => {
    setDisabled(() => true);
    if (joined) {
      await leavePublicChannel(workspaceId, id, membershipId);
      handleClose();

      if (id === params.feedId) {
        const redirectUrl = await fetchNextActiveChannel();
        navigate(redirectUrl);
      }
      changeJoined(false);
      setDisabled(() => false);
    } else {
      handleClose();
      joiningHandler && joiningHandler(true);
      await joinPublicChannel(workspaceId, id);
      changeJoined(true);
      joiningHandler && joiningHandler(false);
    }

    setDisabled(() => false);
  };
  const markChannelAsRead = async () => {
    // setDisabled(() => true);
    accountEvent("Marked Feed as Read", {
      feedId: feed.id,
    });
    // setDisabled(() => false);
    handleClose();
  };

  const handleMarkAllChannelsAsRead = async () => {
    setDisabled(() => true);
    markAllChannelsAsRead();
    setDisabled(() => false);
    handleClose();
  };

  const ariaLabelJoin = joined
    ? Locator.workspaceNav.channels.list.leave
    : Locator.workspaceNav.channels.list.join;

  return (
    <Box
      sx={defaultStyles(sx)}
      onContextMenu={handleContextMenu}
      style={{ cursor: "context-menu" }}
    >
      {children}

      {contextMenuItem([
        !userReadOnlyMode ? (
          <MenuItem
            aria-label={Locator.workspaceNav.channels.list.markAsRead}
            key={Locator.workspaceNav.channels.list.markAsRead}
            disabled={disabled}
            onClick={markChannelAsRead}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                mr: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <DraftsOutlinedIcon />
            </Box>
            <Box>Mark as Read</Box>
          </MenuItem>
        ) : null,
        markAllChannelsAsRead && !userReadOnlyMode ? (
          <MenuItem
            aria-label={Locator.workspaceNav.channels.list.markAllAsRead}
            key={Locator.workspaceNav.channels.list.markAllAsRead}
            disabled={disabled}
            onClick={handleMarkAllChannelsAsRead}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                mr: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ListIcon />
            </Box>
            <Box>Mark all as Read</Box>
          </MenuItem>
        ) : null,
        !limitedMember ? (
          <MenuItem
            aria-label={ariaLabelJoin}
            key={ariaLabelJoin}
            disabled={disabled}
            onClick={joinOrLeaveChannel}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                mr: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              {joined ? <LogoutIcon /> : <LoginIcon />}
            </Box>
            <Box>{joined ? "Leave" : "Join"} Channel</Box>
          </MenuItem>
        ) : null,
      ])}
    </Box>
  );
}
