import { Electric } from "@/generated/client";
import { unreadClearEvents } from "@/models/UnreadsContextProvider";
import { DatabaseAdapter } from "electric-sql/dist/electric/adapter";
import {
  DirectWorkspaceInvitation,
  DeviceRegistration,
  Workspace,
  WorkspaceMembership,
  WsAccount,
  WsAudioEncoding,
  WsAudioQueueItem,
  WsBroadcastAction,
  WsBroadcastRecipient,
  WsCallRecord,
  WsCommandAlias,
  WsDisplayArtifact,
  WsEvent,
  WsFeed,
  WsFeedGroup,
  WsFeedGroupMembership,
  WsFile,
  WsItem,
  WsLink,
  WsPAM,
  WsPermission,
  WsPublishedWorkflowItem,
  WsScheduleTrigger,
  WsTemplate,
  WsTranscription,
  WsWorkflowItem,
} from "web-client/api/data-contracts";
import { updateWorkspaceConfig } from "./workspaceConfig";
import { chunkWithMinSize } from '@/utils';
import { useLocalStorage } from '@uidotdev/usehooks';

export async function createManyWorkspace(
  db: Electric["db"],
  records: Workspace[],
) {
  const data = records.map((workspace) => ({
    id: workspace.id,
    name: workspace.name,
  }));
  if (data.length === 0) return;

  for (const workspace of records) {
    updateWorkspaceConfig(workspace);
  }

  return db.workspace.createMany({
    data,
    skipDuplicates: true,
  });
}

export async function upsertWorkspace(
  db: Electric["db"],
  workspace: Workspace,
) {
  const newWorkspace = db.workspace.upsert({
    where: {
      id: workspace.id,
    },
    create: {
      id: workspace.id,
      name: workspace.name,
    },
    update: {
      name: workspace.name,
    },
  });

  updateWorkspaceConfig(workspace);

  return newWorkspace;
}

export async function createManyWsFeed(db: Electric["db"], feeds: WsFeed[]) {
  const data = feeds.map((feed) => ({
    id: feed.id,
    createdAt: feed.createdAt,
    updatedAt: feed.updatedAt,
    workspaceId: feed.workspaceId,
    readOnly: feed.readOnly ? 1 : 0,
    lastOpened: feed.lastOpened || null,
    latestActivity: feed.latestActivity || null,

    loadedFirstPage: 0,
    loadedLastPage: -1,
    loadedEvents: 0,
    loadedPermissions: 0,

    feedType: feed.feedType,
    title: feed.title || null,
    isPrivate: feed.isPrivate ? 1 : 0,

    isDm: feed.isDm ? 1 : 0,
    isSilent: feed?.isSilent ? 1 : 0,
  }));
  if (data.length === 0) return;
  return db.feed.createMany({
    data,
    skipDuplicates: true,
  });
}
export async function upsertWsFeed(db: Electric["db"], feed: WsFeed) {
  return db.feed.upsert({
    where: {
      id: feed.id,
    },
    create: {
      id: feed.id,
      createdAt: feed.createdAt,
      updatedAt: feed.updatedAt,
      workspaceId: feed.workspaceId,
      readOnly: feed.readOnly ? 1 : 0,
      lastOpened: feed.lastOpened,
      latestActivity: feed.latestActivity,

      loadedFirstPage: 0,
      loadedLastPage: -1,
      loadedEvents: 0,
      loadedPermissions: 0,

      feedType: feed.feedType,
      title: feed.title,
      isPrivate: feed.isPrivate ? 1 : 0,

      isDm: feed.isDm ? 1 : 0,
      isSilent: feed.isSilent ? 1 : 0,
    },
    update: {
      createdAt: feed.createdAt,
      updatedAt: feed.updatedAt,
      workspaceId: feed.workspaceId,
      readOnly: feed.readOnly ? 1 : 0,
      lastOpened: feed.lastOpened,
      latestActivity: feed.latestActivity,

      feedType: feed.feedType,
      title: feed.title,
      isPrivate: feed.isPrivate ? 1 : 0,

      isDm: feed.isDm ? 1 : 0,
      isSilent: feed.isSilent ? 1 : 0,
    },
  });
}

export async function createManyWsFeedGroup(
  db: Electric["db"],
  feedGroups: WsFeedGroup[],
) {
  const data = feedGroups.map((feedGroup) => ({
    id: feedGroup.id,
    createdAt: feedGroup.createdAt,
    updatedAt: feedGroup?.updatedAt || "",
    workspaceId: feedGroup.workspaceId,
    name: feedGroup.name,
  }));
  if (data.length === 0) return;
  return db.feed_group.createMany({
    data,
    skipDuplicates: true,
  });
}

export async function upsertWsFeedGroup(
  db: Electric["db"],
  feedGroup: WsFeedGroup,
) {
  return db.feed_group.upsert({
    where: {
      id: feedGroup.id,
    },
    create: {
      id: feedGroup.id,
      createdAt: feedGroup.createdAt,
      updatedAt: feedGroup?.updatedAt || "",
      workspaceId: feedGroup.workspaceId,
      name: feedGroup.name,
    },
    update: {
      createdAt: feedGroup.createdAt,
      updatedAt: feedGroup?.updatedAt || "",
      workspaceId: feedGroup.workspaceId,
      name: feedGroup.name,
    },
  });
}

export async function createManyWsFeedGroupMembership(
  db: Electric["db"],
  feedGroupMemberships: WsFeedGroupMembership[],
) {
  const data = feedGroupMemberships.map((feedGroupMembership) => ({
    id: feedGroupMembership.id,
    createdAt: feedGroupMembership.createdAt,
    updatedAt: feedGroupMembership?.updatedAt || "",
    groupId: feedGroupMembership.groupId,
    feedId: feedGroupMembership.feedId,
    workspaceId: feedGroupMembership.workspaceId,
  }));
  console.log("Feed group memberships", data);
  if (data.length === 0) return;
  return db.feed_group_membership.createMany({
    data,
    skipDuplicates: true,
  });
}

export async function upsertWsFeedGroupMembership(
  db: Electric["db"],
  feedGroupMembership: WsFeedGroupMembership,
) {
  return db.feed_group_membership.upsert({
    where: {
      id: feedGroupMembership.id,
    },
    create: {
      id: feedGroupMembership.id,
      createdAt: feedGroupMembership.createdAt,
      updatedAt: feedGroupMembership?.updatedAt || "",
      groupId: feedGroupMembership.groupId,
      feedId: feedGroupMembership.feedId,
      workspaceId: feedGroupMembership.workspaceId,
    },
    update: {
      createdAt: feedGroupMembership.createdAt,
      updatedAt: feedGroupMembership?.updatedAt || "",
      groupId: feedGroupMembership.groupId,
      feedId: feedGroupMembership.feedId,
      workspaceId: feedGroupMembership.workspaceId,
    },
  });
}

export async function createManyWorkspaceMembership(
  db: Electric["db"],
  memberships: WorkspaceMembership[],
) {
  const data = memberships.map((workspaceMembership) => ({
    id: workspaceMembership.id,
    workspaceId: workspaceMembership.workspaceId,
    accountId: workspaceMembership.accountId,
    status: workspaceMembership.status,
    role: workspaceMembership.role,
  }));
  if (data.length === 0) return;
  const wmcount = await db.workspace_membership.createMany({
    data,
    skipDuplicates: true,
  });
  return wmcount;
}
export async function upsertWorkspaceMembership(
  db: Electric["db"],
  workspaceMembership: WorkspaceMembership,
) {
  return db.workspace_membership.upsert({
    where: {
      id: workspaceMembership.id,
    },
    create: {
      id: workspaceMembership.id,
      workspaceId: workspaceMembership.workspaceId,
      accountId: workspaceMembership.accountId,
      status: workspaceMembership.status,
      role: workspaceMembership.role,
    },
    update: {
      workspaceId: workspaceMembership.workspaceId,
      accountId: workspaceMembership.accountId,
      status: workspaceMembership.status,
      role: workspaceMembership.role,
    },
  });
}

export async function createManyWsPermission(
  db: Electric["db"],
  records: WsPermission[],
) {
  const data = records.map((p) => ({
    id: p.id,
    workspace_membershipId: p.workspaceMembershipId,

    feedId: p.feedId,
    workflowItemId: p.workflowItemId,

    name: p.name,
    enabled: p.enabled ? 1 : 0,
    createdAt: p?.createdAt || '',
    updatedAt: p?.updatedAt || '',
  }));
  if (data.length === 0) return;

  const size = 100;
  const results: Awaited<ReturnType<typeof db.permission.createMany>>[] = [];
  for (let i = 0; i < data.length; i += size) {
    const slice = data.slice(i, i + size);
    if (slice.length > 0) {
      results.push(
        await db.permission.createMany({ data: slice, skipDuplicates: true }),
      );
    }
  }

  return results;
}
export async function upsertWsPermission(db: Electric["db"], p: WsPermission) {
  return db.permission.upsert({
    where: {
      id: p.id,
    },
    create: {
      id: p.id,
      workspace_membershipId: p.workspaceMembershipId,

      feedId: p.feedId,
      workflowItemId: p.workflowItemId,

      name: p.name,
      enabled: p.enabled ? 1 : 0,
      createdAt: p.createdAt,
      updatedAt: p.updatedAt,
    },
    update: {
      workspace_membershipId: p.workspaceMembershipId,

      feedId: p.feedId,
      workflowItemId: p.workflowItemId,

      name: p.name,
      enabled: p.enabled ? 1 : 0,
      updatedAt: p.updatedAt,
    },
  });
}

export async function upsertManyWsPermission(
  db: Electric["db"],
  ps: WsPermission[],
  adapter: DatabaseAdapter,
) {
  // return adapter.transaction(async (tx) => {
  for (const p of ps) {
    upsertWsPermission(db, p);
  }
  // });
}

export async function createManyWsAccount(
  db: Electric["db"],
  records: WsAccount[],
) {
  const data = records.map((account) => ({
    id: account.id,
    name: account.name || null,
    accountType: account.accountType || null,
    avatarColor: account.avatarColor || null,
    firstName: account.firstName || null,
    lastName: account.lastName || null,
    email: account.email || null,
    emailVerified: account.emailVerified ? 1 : 0,
    phoneNumber: account.phoneNumber || null,
    phoneNumberVerified: account.phoneNumberVerified ? 1 : 0,
  }));

  if (data.length === 0) return;
  const accounts = await db.account.createMany({ data, skipDuplicates: true });
  // console.log("Creating many accounts", accounts);
  return accounts;
}
export async function upsertWsAccount(db: Electric["db"], account: WsAccount) {
  const newAccount = db.account.upsert({
    where: {
      id: account.id,
    },
    create: {
      id: account.id,
      name: account.name,
      accountType: account.accountType,
      avatarColor: account.avatarColor,
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      emailVerified: account.emailVerified ? 1 : 0,
      phoneNumber: account.phoneNumber,
      phoneNumberVerified: account.phoneNumberVerified ? 1 : 0,
    },
    update: {
      name: account.name,
      accountType: account.accountType,
      avatarColor: account.avatarColor,
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      emailVerified: account.emailVerified ? 1 : 0,
      phoneNumber: account.phoneNumber,
      phoneNumberVerified: account.phoneNumberVerified ? 1 : 0,
    },
  });

  return newAccount;
}

export async function createManyWsItem(db: Electric["db"], records: WsItem[]) {
  const data = records.map((item) => ({
    id: item.id,
    createdAt: item.createdAt,
    feedId: item.feedId,
    accountId: item.accountId,
    contentId: item.contentId,
    deletedAt: item.deletedAt || null,
    groupId: item.groupId || null,
    loadedContent: 0,
    isSilent: item.isSilent ? 1 : 0,
    unread: null,
  }));
  if (data.length === 0) return;

  const size = 100;
  const results: Awaited<ReturnType<typeof db.item.createMany>>[] = [];

  for (let i = 0; i < data.length; i += size) {
    const slice = data.slice(i, i + size)?.filter((item) => item.contentId);
    if (slice.length > 0) {
      try {
        results.push(
          await db.item.createMany({ data: slice, skipDuplicates: true }),
        );
      } catch (e) {
        console.error({ e });
      }
    }
  }

  return results;
}
export async function upsertWsItem(db: Electric["db"], item: WsItem) {
  if (!item?.contentId) return;
  return db.item.upsert({
    where: {
      id: item.id,
    },
    create: {
      id: item.id,
      createdAt: item.createdAt,
      feedId: item.feedId,
      accountId: item.accountId,
      contentId: item.contentId,
      deletedAt: item.deletedAt,
      groupId: item.groupId,
      loadedContent: 0,
      isSilent: item.isSilent ? 1 : 0,
    },
    update: {
      createdAt: item.createdAt,
      feedId: item.feedId,
      accountId: item.accountId,
      deletedAt: item.deletedAt,
      isSilent: item.isSilent ? 1 : 0,
    },
  });
}

export async function createManyWsDisplayArtifact(
  db: Electric["db"],
  records: WsDisplayArtifact[],
) {
  const data = records.map((artifact) => ({
    id: artifact.id,
    title: artifact.title || null,
    description: artifact?.description || null,
    createdAt: artifact.createdAt,
    contentId: artifact.contentId,
    deletedAt: artifact.deletedAt || null,
  }));

  if (data.length === 0) return;
  return db.display_artifact.createMany({ data, skipDuplicates: true });
}
export async function upsertWsDisplayArtifact(
  db: Electric["db"],
  artifact: WsDisplayArtifact,
) {
  if (!artifact.contentId) return;
  return db.display_artifact.upsert({
    where: {
      id: artifact.id,
    },
    create: {
      id: artifact.id,
      title: artifact.title,
      description: artifact?.description,
      createdAt: artifact.createdAt,
      contentId: artifact.contentId,
      deletedAt: artifact.deletedAt,
    },
    update: {
      title: artifact.title,
      description: artifact?.description,
      createdAt: artifact.createdAt,
      contentId: artifact.contentId,
      deletedAt: artifact.deletedAt,
    },
  });
}

export async function createManyWsTranscription(
  db: Electric["db"],
  records: WsTranscription[],
) {
  const data = records.map((transcription) => ({
    id: transcription.id,
    createdAt: transcription.createdAt,
    contentId: transcription.contentId,
    transcriptionContent: transcription.transcription,
    transcriptionType: transcription.transcriptionType,
    backendModel: transcription.model || "",
    confidence: transcription.confidence || null,
    language: transcription.language || "",
    priority: transcription.priority || 0,
    executionTime: transcription.executionTime || 0,
    url: transcription.url || "",
    translatedFrom: transcription.translatedFrom || "",
    format: transcription.format,
  }));

  if (data.length === 0) return;
  return db.transcription.createMany({ data, skipDuplicates: true });
}
export async function upsertWsTranscription(
  db: Electric["db"],
  transcription: WsTranscription,
) {
  return db.transcription.upsert({
    where: {
      id: transcription.id,
    },
    create: {
      id: transcription.id,
      createdAt: transcription.createdAt,
      contentId: transcription.contentId,
      transcriptionContent: transcription.transcription,
      transcriptionType: transcription.transcriptionType,
      backendModel: transcription.model,
      confidence: transcription.confidence,
      language: transcription.language,
      priority: transcription.priority,
      executionTime: transcription.executionTime,
      url: transcription.url,
      translatedFrom: transcription.translatedFrom,
      format: transcription.format,
    },
    update: {
      createdAt: transcription.createdAt,
      contentId: transcription.contentId,
      transcriptionContent: transcription.transcription,
      transcriptionType: transcription.transcriptionType,
      backendModel: transcription.model,
      confidence: transcription.confidence,
      language: transcription.language,
      priority: transcription.priority,
      executionTime: transcription.executionTime,
      url: transcription.url,
      translatedFrom: transcription.translatedFrom,
      format: transcription.format,
    },
  });
}

export async function createManyWsAudioEncoding(
  db: Electric["db"],
  records: WsAudioEncoding[],
) {
  const data = records.map((e) => ({
    id: e.id,
    contentId: e.contentId,
    codec: e.codec || null,
    duration: e.duration || 0,
    url: e.url,
    mimeType: e.mimeType || null,
    transcriptionId: e.transcriptionId || null,
    transcriptionType: e.transcriptionType || null,
    language: e.language || null,
    translatedFrom: e.translatedFrom || null,
    priority: e.priority || 0,
    generatedService: e.generatedService || null,
    generatedVoice: e.generatedVoice || null,
  }));

  if (data.length === 0) return;
  return db.audio_encoding.createMany({ data, skipDuplicates: true });
}
export async function upsertWsAudioEncoding(
  db: Electric["db"],
  e: WsAudioEncoding,
) {
  return db.audio_encoding.upsert({
    where: {
      id: e.id,
    },
    create: {
      id: e.id,
      contentId: e.contentId,
      codec: e.codec,
      duration: e.duration,
      url: e.url,
      mimeType: e.mimeType,
      transcriptionId: e.transcriptionId,
      transcriptionType: e.transcriptionType,
      language: e.language,
      translatedFrom: e.translatedFrom,
      priority: e.priority,
      generatedService: e.generatedService,
      generatedVoice: e.generatedVoice,
    },
    update: {
      contentId: e.contentId,
      codec: e.codec,
      duration: e.duration,
      url: e.url,
      mimeType: e.mimeType,
      transcriptionId: e.transcriptionId,
      transcriptionType: e.transcriptionType,
      language: e.language,
      translatedFrom: e.translatedFrom,
      priority: e.priority,
      generatedService: e.generatedService,
      generatedVoice: e.generatedVoice,
    },
  });
}

export async function upsertWsAudioQueueItem(
  db: Electric["db"],
  aqi: WsAudioQueueItem,
) {
  return db.audio_queue_item.upsert({
    where: { id: aqi.id },
    create: {
      id: aqi.id,
      itemId: aqi.itemId,
      createdAt: aqi.createdAt,
    },
    update: {
      id: aqi.id,
      itemId: aqi.itemId,
      createdAt: aqi.createdAt,
    },
  });
}

export async function deleteWsAudioQueueItem(
  db: Electric["db"],
  aqi: WsAudioQueueItem,
) {
  return db.audio_queue_item.delete({
    where: { id: aqi.id },
  });
}

export async function deleteAllWsAudioQueueItems(db: Electric["db"]) {
  return db.audio_queue_item.deleteMany();
}

export async function createManyWsLink(db: Electric["db"], records: WsLink[]) {
  const data = records.map((l) => ({
    id: l.id,
    contentId: l.contentId,
    url: l.url || null,
    description: l.description || null,
    title: l.title || null,
    image: l.image || null,
    linkOrder: l.linkOrder || 0,
  }));

  if (data.length === 0) return;
  return db.link.createMany({ data, skipDuplicates: true });
}
export async function upsertWsLink(db: Electric["db"], l: WsLink) {
  return db.link.upsert({
    where: {
      id: l.id,
    },
    create: {
      id: l.id,
      contentId: l.contentId,
      url: l.url,
      description: l.description,
      title: l.title,
      image: l.image,
      linkOrder: l.linkOrder,
    },
    update: {
      contentId: l.contentId,
      url: l.url,
      description: l.description,
      title: l.title,
      image: l.image,
      linkOrder: l.linkOrder,
    },
  });
}

export async function createManyWsFile(db: Electric["db"], records: WsFile[]) {
  const data = records.map((f) => ({
    id: f.id,
    contentId: f.contentId,
    url: f.url || null,
    name: f.name || null,
    mimeType: f.mimeType || null,
  }));
  if (data.length === 0) return;
  return db.file.createMany({ data, skipDuplicates: true });
}
export async function upsertWsFile(db: Electric["db"], f: WsFile) {
  return db.file.upsert({
    where: {
      id: f.id,
    },
    create: {
      id: f.id,
      contentId: f.contentId,
      url: f.url,
      name: f.name,
      mimeType: f.mimeType,
    },
    update: {
      contentId: f.contentId,
      url: f.url,
      name: f.name,
      mimeType: f.mimeType,
    },
  });
}

export async function createManyWsCallRecord(
  db: Electric["db"],
  records: WsCallRecord[],
) {
  const data = records.map((c) => ({
    id: c.id,
    contentId: c.contentId,
    appPhoneNumberId: c.appPhoneNumberId || null,
    direction: c.direction || null,
    fromNumber: c.from || null,
    toNumber: c.to || null,
  }));

  if (data.length === 0) return;
  return db.call_record.createMany({ data, skipDuplicates: true });
}
export async function upsertWsCallRecord(db: Electric["db"], c: WsCallRecord) {
  return db.call_record.upsert({
    where: {
      id: c.id,
    },
    create: {
      id: c.id,
      contentId: c.contentId,
      appPhoneNumberId: c.appPhoneNumberId,
      direction: c.direction,
      fromNumber: c.from,
      toNumber: c.to,
    },
    update: {
      contentId: c.contentId,
      appPhoneNumberId: c.appPhoneNumberId,
      direction: c.direction,
      fromNumber: c.from,
      toNumber: c.to,
    },
  });
}

export async function createManyWsEvent(
  db: Electric["db"],
  records: WsEvent[],
) {
  const chunkSize = 1000;

  const data = records.map((c) => ({
    id: c.id,
    accountId: c.accountId,
    createdAt: c.createdAt,
    name: c.name,
    contentId: c.contentId || null,
    feedId: c.feedId || null,
    itemId: c.itemId || null,
  }));
  const chunkedData = chunkWithMinSize(data, chunkSize);

  if (data.length === 0) return;
  const promises = [];
  for (const chunk of chunkedData) {
    promises.push(
      db.account_event.createMany({ data: chunk, skipDuplicates: true }),
    );
  }
  let promiseResults = 0
 await Promise.all(promises).then((results) => {
    promiseResults = results?.length });
  return promiseResults;
}
export async function upsertWsEvent(db: Electric["db"], c: WsEvent) {
  return db.account_event.upsert({
    where: {
      id: c.id,
    },
    create: {
      id: c.id,
      accountId: c.accountId,
      createdAt: c.createdAt,
      name: c.name,
      contentId: c.contentId,
      feedId: c.feedId,
      itemId: c.itemId,
    },
    update: {
      accountId: c.accountId,
      createdAt: c.createdAt,
      name: c.name,
      contentId: c.contentId,
      feedId: c.feedId,
      itemId: c.itemId,
    },
  });
}

export async function upsertDirectWsInvitation(
  db: Electric["db"],
  c: DirectWorkspaceInvitation,
) {
  return db.direct_ws_invitation.upsert({
    where: {
      id: c.id,
    },
    create: {
      id: c.id,
      email: c.email,
      phoneNumber: c.phoneNumber,
      workspaceMembershipId: c.workspaceMembershipId,
      name: c.name,
      claimedAt: c.claimedAt,
      claimedBy: c.claimedBy,
      createdAt: c.createdAt,
      updatedAt: c.updatedAt,
    },
    update: {
      email: c.email,
      phoneNumber: c.phoneNumber,
      workspaceMembershipId: c.workspaceMembershipId,
      name: c.name,
      claimedAt: c.claimedAt,
      claimedBy: c.claimedBy,
      createdAt: c.createdAt,
      updatedAt: c.updatedAt,
    },
  });
}

export async function upsertWsDraft(db: Electric["db"], c: WsWorkflowItem) {
  return db.workflow_item.upsert({
    where: {
      id: c.id,
    },
    create: {
      id: c.id,
      contentId: c.contentId,
      createdAt: c.createdAt,
      deletedAt: c.deletedAt,
      workspaceId: c.workspaceId,
      displayName: c.displayName,
    },
    update: {
      contentId: c.contentId,
      createdAt: c.createdAt,
      deletedAt: c.deletedAt,
      workspaceId: c.workspaceId,
      displayName: c.displayName,
    },
  });
}

export async function deleteWsDraft(
  db: Electric["db"],
  workflowItemId: string,
) {
  return db.workflow_item.delete({
    where: { id: workflowItemId },
  });
}

export async function upsertWsScheduleTrigger(
  db: Electric["db"],
  c: WsScheduleTrigger,
) {
  return db.schedule_trigger.upsert({
    where: {
      id: c.id,
    },
    create: {
      id: c.id,
      oneTimeSchedule: c.oneTimeSchedule || null,
      cronSchedule: c.cronSchedule || null,
      timezone: c.timezone || null,
      workspaceId: c.workspaceId,
      broadcastActionId: c.broadcastActionId,
      createdAt: c.createdAt,
      updatedAt: c.updatedAt,
      enabled: c.enabled ? 1 : 0,
    },
    update: {
      id: c.id,
      oneTimeSchedule: c.oneTimeSchedule || null,
      cronSchedule: c.cronSchedule || null,
      timezone: c.timezone || null,
      workspaceId: c.workspaceId,
      broadcastActionId: c.broadcastActionId,
      createdAt: c.createdAt,
      updatedAt: c.updatedAt,
      enabled: c.enabled ? 1 : 0,
    },
  });
}

export async function upsertWsBroadcastAction(
  db: Electric["db"],
  c: WsBroadcastAction,
) {
  return db.broadcast_action.upsert({
    where: {
      id: c.id,
    },
    create: {
      id: c.id,
      workspaceId: c.workspaceId,
      workflowItemId: c.workflowItemId,
      broadcastId: c.broadcastId,
      createdAt: c.createdAt,
      updatedAt: c.updatedAt,
    },
    update: {
      id: c.id,
      workspaceId: c.workspaceId,
      workflowItemId: c.workflowItemId,
      broadcastId: c.broadcastId,
      createdAt: c.createdAt,
      updatedAt: c.updatedAt,
    },
  });
}

export async function upsertWsPublishedDraft(
  db: Electric["db"],
  c: WsPublishedWorkflowItem,
) {
  return db.published_workflow_item.upsert({
    where: {
      id: c.id,
    },
    create: {
      id: c.id,
      contentId: c.contentId,
      createdAt: c.createdAt,
      workspaceId: c.workspaceId,
      workflowItemId: c.workflowItemId,
      broadcastId: c.broadcastId,
      accountId: c.accountId,
    },
    update: {
      contentId: c.contentId,
      createdAt: c.createdAt,
      workspaceId: c.workspaceId,
      workflowItemId: c.workflowItemId,
      broadcastId: c.broadcastId,
      accountId: c.accountId,
    },
  });
}

export async function upsertWsBroadcastRecipient(
  db: Electric["db"],
  c: WsBroadcastRecipient,
) {
  return db.broadcast_recipient.upsert({
    where: {
      id: c.id,
    },
    create: {
      id: c.id,
      broadcastId: c.broadcastId,
      workspaceId: c.workspaceId,
      feedId: c.feedId,
      workspaceMembershipId: c.workspaceMembershipId,
      createdAt: c.createdAt,
    },
    update: {
      broadcastId: c.broadcastId,
      workspaceId: c.workspaceId,
      feedId: c.feedId,
      workspaceMembershipId: c.workspaceMembershipId,
      createdAt: c.createdAt,
    },
  });
}

export async function createManyWsTemplate(
  db: Electric["db"],
  records: WsTemplate[],
) {
  const data = records.map((c) => ({
    id: c.id,
    name: c.name || "",
    template: c.template || "",
    workspaceId: c.workspaceId,
    authorId: c.authorId || null,
    createdAt: c.createdAt,
    updatedAt: c.updatedAt,
    deletedAt: c.deletedAt || null,
  }));

  if (data.length === 0) return;
  return db.template.createMany({ data, skipDuplicates: true });
}

export async function upsertWsTemplate(db: Electric["db"], c: WsTemplate) {
  return db.template.upsert({
    where: {
      id: c.id,
    },
    create: {
      id: c.id,
      name: c.name,
      template: c.template,
      workspaceId: c.workspaceId,
      authorId: c.authorId,
      createdAt: c.createdAt,
      updatedAt: c.updatedAt,
      deletedAt: c.deletedAt,
    },
    update: {
      name: c.name,
      template: c.template,
      updatedAt: c.updatedAt,
      deletedAt: c.deletedAt,
    },
  });
}

export async function upsertWsHandsFreeStatus(
  db: Electric["db"],
  hfs: {
    id: string;
    enabled: number;
    timestamp: string;
  },
) {
  return db.ws_hands_free_status.upsert({
    where: {
      id: hfs.id,
    },
    create: {
      id: hfs.id,
      enabled: hfs.enabled,
      timestamp: hfs.timestamp,
    },
    update: {
      enabled: hfs.enabled,
      timestamp: hfs.timestamp,
    },
  });
}

export async function createManyWsPAM(db: Electric["db"], records: WsPAM[]) {
  const data = records.map((c) => ({
    id: c.id,
    contentId: c.contentId,
    createdAt: c.createdAt,
    updatedAt: c.updatedAt,
    vadResult: JSON.stringify(c.vadResult),
  }));

  if (data.length === 0) return;
  return db.pipeline_artifact_metadata.createMany({
    data,
    skipDuplicates: true,
  });
}
export async function upsertWsPAM(db: Electric["db"], pam: WsPAM) {
  if (!pam?.contentId) return;
  return db.pipeline_artifact_metadata.upsert({
    where: {
      id: pam.id,
    },
    create: {
      id: pam.id,
      contentId: pam.contentId,
      vadResult: JSON.stringify(pam.vadResult),
      createdAt: pam.createdAt,
    },
    update: {
      contentId: pam.contentId,
      vadResult: JSON.stringify(pam.vadResult),
      createdAt: pam.createdAt,
    },
  });
}

export async function createManyWsCommandAliases(
  db: Electric["db"],
  records: WsCommandAlias[],
) {
  const data = records.map((c) => ({
    id: c.id,
    alias: c.alias,
    workspaceId: c.workspaceId,
    workspaceMembershipId: c.workspaceMembershipId,
    feedId: c.feedId,
    createdAt: c.createdAt,
  }));

  console.log("Creating many command aliases", data);
  if (data.length === 0) return;
  return db.workspace_command_alias.createMany({ data, skipDuplicates: true });
}

export async function upsertWsCommandAlias(
  db: Electric["db"],
  c: WsCommandAlias,
) {
  return db.workspace_command_alias.upsert({
    where: {
      id: c.id,
    },
    create: {
      id: c.id,
      alias: c.alias,
      workspaceId: c.workspaceId,
      workspaceMembershipId: c.workspaceMembershipId,
      feedId: c.feedId,
      createdAt: c.createdAt,
    },
    update: {
      alias: c.alias,
      workspaceId: c.workspaceId,
      workspaceMembershipId: c.workspaceMembershipId,
      feedId: c.feedId,
      createdAt: c.createdAt,
    },
  });
}

export async function getCommandAliasesByFeedId(
  db: Electric["db"],
  feedId: string,
) {
  try {
    const commandAliases = await db.raw({
      sql: `SELECT
              workspace_membership.accountId,
              workspace_command_alias.*
            FROM
              workspace_command_alias
            INNER JOIN workspace_membership ON workspace_membership.id = workspace_command_alias.workspaceMembershipId
            WHERE
              workspace_command_alias.feedId = ?
            `,
      args: [feedId],
    });
    return commandAliases;
  } catch (e) {
    console.log("Error getting command aliases", e);
  }
}

export async function getOtherFeedAccountsByFeedId(
  db: Electric["db"],
  feedId: string,
) {
  try {
    const otherFeedAccounts = await db.raw({
      sql: `	SELECT
              permission.workspace_membershipId,
              workspace_membership.accountId,
              workspace_membership.role,
              account.name,
              account.email
            FROM
              permission
            INNER JOIN workspace_membership ON workspace_membership.id = permission.workspace_membershipId
            INNER JOIN account ON account.id = workspace_membership.accountId
            WHERE
              permission.enabled = 1
                AND
              permission.feedId = ?
                AND
              permission.workspace_membershipId IS NOT NULL
                AND
              account.mine = 0
            GROUP BY permission.workspace_membershipId`,
      args: [feedId],
    });

    const otherAdmins = otherFeedAccounts?.filter(
      (account) => account.role === "admin",
    );

    const otherOrganizers = otherFeedAccounts?.filter(
      (account) => account.role === "member",
    );

    const otherMembers = otherFeedAccounts?.filter(
      (account) => account.role === "limitedMember",
    );
    return { otherFeedAccounts, otherAdmins, otherOrganizers, otherMembers };
  } catch (e) {
    console.log("Error getting feed accounts by role", e);
  }
}

export async function getWorkspaceMembershipRole(
  db: Electric["db"],
  accountId: string,
  event: { feedId?: string; itemId?: string },
) {
  if (event.feedId) {
    const myWorkspaceMembership = await db.raw({
      sql: `SELECT * FROM workspace_membership 
        JOIN feed ON feed.workspaceId = workspace_membership.workspaceId
        WHERE feed.id = ? AND workspace_membership.accountId = ? 
        AND workspace_membership.status = 'active'
        `,
      args: [event.feedId, accountId],
    });
    return myWorkspaceMembership?.[0]?.role as string;
  } else {
    if (event.itemId) {
      const myWorkspaceMembership = await db.raw({
        sql: `SELECT * FROM workspace_membership 
          JOIN feed ON feed.workspaceId = workspace_membership.workspaceId
          JOIN item ON item.feedId = feed.id
          WHERE item.id = ? AND workspace_membership.accountId = ?
          AND workspace_membership.status = 'active'
          `,
        args: [event.itemId, accountId],
      });
      return myWorkspaceMembership?.[0]?.role as string;
    }
  }
}

export async function updateUnreadItemByEvents(
  db: Electric["db"],
  event: WsEvent,
  myAccountId: string,
) {
  const events = unreadClearEvents;

  const isClearEvent =
    events.includes(event.name) && (event.feedId || event.itemId);
  if (isClearEvent) {
    const myCurrentWorkspaceRole = await getWorkspaceMembershipRole(
      db,
      myAccountId,
      event,
    );
    const commandAliases = await getCommandAliasesByFeedId(db, event?.feedId);
    const isAliasChannel = commandAliases?.length > 0;

    const isOrganizer = myCurrentWorkspaceRole === "member" && isAliasChannel;
    const { otherOrganizers } = await getOtherFeedAccountsByFeedId(
      db,
      event?.feedId,
    );
    const mappedOrganizers = otherOrganizers?.map(
      (organizer) => organizer.accountId as string,
    );

    const isOrganizerEvent = mappedOrganizers?.includes(event?.accountId);
    let itemsToUpdate = [];
    if (event?.accountId === myAccountId || (isOrganizer && isOrganizerEvent)) {
      if (event?.itemId) {
        itemsToUpdate.push(event?.itemId);
      } else {
        const unreadItems = await db.item.findMany({
          where: {
            feedId: event?.feedId,
            unread: 1,
          },
        });
        itemsToUpdate = unreadItems?.map((item) => item?.id);
      }
    }

    if (itemsToUpdate?.length > 0) {
      return await db.item.updateMany({
        where: {
          id: { in: itemsToUpdate },
        },
        data: {
          unread: 0,
        },
      });
    }
  }
  return false;
}

export async function updateUnreadItemByFeedId(
  db: Electric["db"],
  item: WsItem,
  myAccountId: string,
) {
  const checkedItem = await db.item.findFirst({
    where: {
      id: item.id,
    },
  });

  if (!checkedItem || checkedItem?.unread === 1) return false;
  console.log("checkedItem", checkedItem?.unread);

  const feedId = item?.feedId;
  const commandAliases = await getCommandAliasesByFeedId(db, feedId);
  const isAliasChannel = commandAliases?.length > 0;

  const myCurrentWorkspaceRole = await getWorkspaceMembershipRole(
    db,
    myAccountId,
    { feedId },
  );

  const isOrganizer = myCurrentWorkspaceRole === "member" && isAliasChannel;
  const { otherMembers, otherOrganizers } = await getOtherFeedAccountsByFeedId(
    db,
    feedId,
  );
  const mappedMembers = otherMembers?.map(
    (member) => member.accountId as string,
  );
  const mappedOrganizers = otherOrganizers?.map(
    (organizer) => organizer.accountId as string,
  );

  const feedReadPermission = await getFeedPermission(db, myAccountId, feedId);
  let shouldMarkItemAsUnread = false;
  let isDriverMessage = false;
  let isOrganizerMessage = false;
  // Rules for marking items as unread
  // Organizer
  // 1. If the user is an organizer and the feed is an alias channel, mark all items as unread from members only
  // 2. If the user is an organizer and the feed is not an alias channel, mark all items as unread

  // Admin
  // 1. If the user is an admin and the feed is an alias channel or non-alias channel, mark all items as unread

  // Member
  // 1. If the user is a member and the feed is an alias channel or non-alias channel, mark all items as unread

  if (isOrganizer && isAliasChannel) {
    mappedOrganizers.push(myAccountId);
    const event = await db.account_event.findFirst({
      where: {
        OR: [{ feedId }, { itemId: item.id }],
        accountId: { in: mappedOrganizers },
        name: { in: unreadClearEvents },
      },
      orderBy: { createdAt: "desc" },
    });
    const eventDate = !event ? feedReadPermission?.updatedAt || feedReadPermission?.createdAt || '' : event?.createdAt;
    shouldMarkItemAsUnread = 
      item.accountId !== myAccountId &&
      mappedMembers.includes(item.accountId) &&
      eventDate <= item?.createdAt;
    isDriverMessage = mappedMembers.includes(item.accountId);
    isOrganizerMessage = mappedOrganizers.includes(item.accountId);
  } else {
    const event = await db.account_event.findFirst({
      where: {
        OR: [{ feedId }, { itemId: item.id }],
        accountId: myAccountId,
        name: { in: unreadClearEvents },
      },
      orderBy: { createdAt: "desc" },
    });
    const eventDate = !event ? feedReadPermission?.updatedAt || feedReadPermission?.createdAt || '' : event?.createdAt;
    shouldMarkItemAsUnread =
      item.accountId !== myAccountId && eventDate <= item?.createdAt;
  }

  if (shouldMarkItemAsUnread) {
    return await db.item.update({
      where: {
        id: item.id,
      },
      data: {
        unread: 1,
        isDriverMessage: isDriverMessage ? 1 : 0,
        isOrganizerMessage: isOrganizerMessage ? 1 : 0,
      },
    });
  }
  return false;
}

export async function getFeedPermission(db: Electric["db"],
  myAccountId: string, feedId:string, permissionName: string = 'read') {
    const myWorkspaceMembership = await db.raw({
        sql: `SELECT * FROM workspace_membership 
          JOIN feed ON feed.workspaceId = workspace_membership.workspaceId
          WHERE workspace_membership.accountId = ?
          AND workspace_membership.status = 'active'
          `,
        args: [myAccountId],
      });

    const feedReadPermission = await db.permission.findFirst({
      where: {
        feedId: feedId,
        workspace_membershipId: myWorkspaceMembership[0]?.id as string,
        name: permissionName,
        enabled: 1,
      },
    });

    return feedReadPermission;
}

export async function upsertDeviceRegistration(db: Electric["db"],
  deviceRegistration: DeviceRegistration) {
  const deviceRegistrationId = localStorage.getItem("deviceRegistrationId");
  // save to local storage on upsert
  if (deviceRegistrationId !== deviceRegistration?.id) {
    localStorage.setItem("deviceRegistrationId", `"${deviceRegistration?.id}"`);
  }

  return db.device_registration.upsert({
    where: {
      id: deviceRegistration.id,
    },
    create: {
      id: deviceRegistration.id,
      surface: deviceRegistration.surface,
      deviceOsName: deviceRegistration.deviceOsName,
      deviceOsVersion: deviceRegistration.deviceOsVersion,
      deviceModel: deviceRegistration.deviceModel,
      friendlyName: deviceRegistration.friendlyName,
      drivingDetectionEnabled: deviceRegistration.drivingDetectionEnabled ? 1 : 0,
      wakeWordSensitivity: deviceRegistration.wakeWordSensitivity,
      commandSensitivity: deviceRegistration.commandSensitivity,
      silenceDetectionInitialThreshold: deviceRegistration.silenceDetectionInitialThreshold,
      silenceDetectionFinalThreshold: deviceRegistration.silenceDetectionFinalThreshold,
      commandTimingWindowMs: deviceRegistration.commandTimingWindowMs,
      recordingTimeoutWindowMs: deviceRegistration.recordingTimeoutWindowMs,
      createdAt: deviceRegistration.createdAt,
      updatedAt: deviceRegistration.updatedAt,
    },
    update: {
      surface: deviceRegistration.surface,
      deviceOsName: deviceRegistration.deviceOsName,
      deviceOsVersion: deviceRegistration.deviceOsVersion,
      deviceModel: deviceRegistration.deviceModel,
      friendlyName: deviceRegistration.friendlyName,
      drivingDetectionEnabled: deviceRegistration.drivingDetectionEnabled ? 1 : 0,
      wakeWordSensitivity: deviceRegistration.wakeWordSensitivity,
      commandSensitivity: deviceRegistration.commandSensitivity,
      silenceDetectionInitialThreshold: deviceRegistration.silenceDetectionInitialThreshold,
      silenceDetectionFinalThreshold: deviceRegistration.silenceDetectionFinalThreshold,
      commandTimingWindowMs: deviceRegistration.commandTimingWindowMs,
      recordingTimeoutWindowMs: deviceRegistration.recordingTimeoutWindowMs,
      updatedAt: deviceRegistration.updatedAt,
    },
  });
}
