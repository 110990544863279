import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { useParams } from "react-router-dom";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { ActionContext } from "@/models/ActionsProvider";
import { useCallback, useContext, useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  gridStringOrNumberComparator,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { DataGridPagination } from "../Pagination";
import { LoadingButton } from "@mui/lab";
import Loading from "../Loading/Index";
import Locator from "@/locator";
import { UserManagementContext } from "@/models/UserManagementContextProvider";
import { DataContext } from "@/models/DataProvider";
import { ArrowDropDown, ArrowDropUp, Check } from "@mui/icons-material";

export default function WorkspaceInvitesTable() {
  const { db } = useElectric();
  const { removeMember, updateMemberRole } = useContext(ActionContext);
  const { ampli } = useContext(TrackingContext);
  const { myAccount, availableWorkspaceRoles, getWorkspaceRoleLabel } =
    useContext(DataContext);
  const { activeSearchField, searchFilter, handleSearchFilter } = useContext(
    UserManagementContext,
  );
  const params = useParams();
  const workspaceId = params?.workspaceId as string as string;
  const theme = useTheme();
  const [revokingId, setRevokingId] = useState<string>("");
  const [roleMenuEl, setRoleMenuEl] = useState<null | HTMLElement>(null);
  const [roleMenuOpen, setRoleMenuOpen] = useState<string>();
  const [updatingMemberId, setUpdatingMemberId] = useState<string>();
  const roleOptions = Array?.from(availableWorkspaceRoles?.values())?.filter(
    (r) => r?.enabled,
  );

  const closeRoleMenu = () => {
    setRoleMenuEl(null);
    setRoleMenuOpen("");
  };

  const { results: workspaceInvites } = useLiveQuery(() => {
    if (!workspaceId) return;
    return db.liveRaw({
      sql: `
			SELECT 
				direct_ws_invitation.email, 
				direct_ws_invitation.phoneNumber,
				direct_ws_invitation.name,
				direct_ws_invitation.createdAt as date,
				direct_ws_invitation.workspaceMembershipId as id, 
				workspace_membership.status as membershipStatus,
        workspace_membership.role
			FROM 
				direct_ws_invitation
			JOIN 
				workspace_membership 
			ON 
				direct_ws_invitation.workspaceMembershipId = workspace_membership.id
			WHERE 
				workspace_membership.workspaceId = ?
			AND 
				workspace_membership.status = 'active'
			AND 
				direct_ws_invitation.claimedBy is null
			ORDER BY 
				direct_ws_invitation.createdAt DESC`,
      args: [workspaceId],
    });
  }, [workspaceId]) as {
    results: {
      id: string;
      name: string;
      email: string;
      phoneNumber: string;
      date: string;
      membershipStatus: string;
      role: string;
    }[];
  };

  const formatDate = (date: string) => {
    return format(new Date(date), "MMM dd, yyyy");
  };

  const updateWorkspaceMemberRole = useCallback(
    async (workspaceId, membershipId, currentRole, newRole) => {
      try {
        setUpdatingMemberId(membershipId);
        if (
          currentRole === "admin" &&
          (newRole === "member" || newRole === "limitedMember")
        ) {
          ampli.downgradePermissionMember();
        }
        if (
          (currentRole === "member" || currentRole === "limitedMember") &&
          newRole === "admin"
        ) {
          ampli.upgradePermissionAdmin();
        }
        await updateMemberRole(workspaceId, [membershipId], newRole);
      } catch (e) {
        console.log(e);
      }
      setUpdatingMemberId("");
    },
    [ampli, updateMemberRole],
  );

  const revokeInvite = useCallback(
    async (workspaceMembershipId: string) => {
      try {
        setRevokingId(workspaceMembershipId);
        if (workspaceId) {
          ampli.revokePendingInvite();
          // happy path for workspaces mvp, set the invite membership status to 'inactive', which follows the same functionality of updating a member
          await removeMember(workspaceId, [workspaceMembershipId]);
        }
      } catch (e) {
        console.log(e);
      }
      setRevokingId("");
    },
    [ampli, workspaceId, removeMember],
  );

  const columns: GridColDef[] = [
    {
      field: "contact",
      headerName: "Contact",
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
      sortComparator: gridStringOrNumberComparator,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.email || params.row.phoneNumber,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <Typography fontWeight={700}>
              {params?.row?.email || params?.row?.phoneNumber}
            </Typography>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <Typography fontWeight={700}>{params.row.name}</Typography>
          </>
        );
      },
    },
    {
      field: "date",
      headerName: "Invitation Date",
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatDate(params?.row?.date);
      },
    },
    {
      field: "role",
      headerName: "Type",
      minWidth: 120,
      maxWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams) => {
        const memberTestId = params.row?.email || params.row?.phoneNumber;
        return (
          <Box>
            {params.row.mine ? (
              <Typography sx={{ textTransform: "capitalize", pr: 4.2, pl: 1 }}>
                {getWorkspaceRoleLabel(params.row.role) || params.row.role}
              </Typography>
            ) : (
              <>
                <IconButton
                  onClick={(e) => {
                    setRoleMenuEl(e.currentTarget);
                    setRoleMenuOpen(params.row.id);
                  }}
                  aria-label={
                    Locator.workspaceNav.members.activeMembers.roleSelect
                  }
                  data-testid={Locator.workspaceNav.members.activeMembers.roleSelectTestId(
                    memberTestId,
                  )}
                  sx={{
                    textTransform: "capitalize",
                    background: "transparent",
                  }}
                >
                  <Typography>
                    {getWorkspaceRoleLabel(params.row.role) || params.row.role}
                  </Typography>
                  {params.row.id === roleMenuOpen ? (
                    <ArrowDropUp role="img" />
                  ) : (
                    <ArrowDropDown role="img" />
                  )}
                </IconButton>
                {roleMenuEl && params.row.id === roleMenuOpen && (
                  <Menu
                    anchorEl={roleMenuEl}
                    open={params.row.id === roleMenuOpen}
                    onClose={closeRoleMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    sx={{
                      mt: 1,
                      ml: 1,
                    }}
                    slotProps={{
                      paper: {
                        sx: {
                          width: 185,
                          px: 1,
                          py: 1.5,
                        },
                      },
                    }}
                  >
                    {roleOptions?.map((r) => (
                      <MenuItem
                        sx={{
                          maxHeight: 40,
                          gap: 1,
                          px: 2,
                          py: 1,
                          borderRadius: "4px",
                          background:
                            params.row.role === r?.role
                              ? theme.palette.secondary.main
                              : "transparent",
                          border:
                            params.row.role === r?.role
                              ? "1.5px solid"
                              : "none",
                          borderColor:
                            params.row.role === r?.role
                              ? theme.palette.secondary.light
                              : "none",
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                        key={`role-option-${r?.role}`}
                        onClick={() =>
                          updateWorkspaceMemberRole(
                            workspaceId,
                            params.row.id,
                            params.row.role,
                            r?.role,
                          )
                        }
                        disabled={params.row.id === updatingMemberId}
                      >
                        <Stack
                          sx={{
                            position: "relative",
                            maxWidth: 24,

                            alignItems: "center",
                          }}
                        >
                          {params.row.role === r?.role ? (
                            <Check />
                          ) : (
                            <Box sx={{ width: 24 }}></Box>
                          )}
                          {params.row.role !== r?.role &&
                          params.row.id === updatingMemberId ? (
                            <Loading variant="disabled" size="tiny" />
                          ) : null}
                        </Stack>
                        {r?.label || r?.role}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              </>
            )}
          </Box>
        );
      },
    },
    {
      field: "revoke",
      headerName: "",
      width: 115,
      editable: false,
      sortable: false,
      align: "right",
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row?.mine) {
          const pendingInviteId = params.row?.email || params.row?.phoneNumber;
          return (
            <LoadingButton
              loading={revokingId === params.row.id}
              onClick={() => revokeInvite(params.row.id)}
              aria-label={
                Locator.workspaceNav.members.pendingInvites.revokeButton
              }
              data-testid={Locator.workspaceNav.members.pendingInvites.revokeTestId(
                pendingInviteId,
              )}
              sx={{
                padding: "8px 12px",
                fontSize: "0.875rem",
                background: theme.palette.secondary.dark,
                maxWidth: "85px",
                lineHeight: 1,
              }}
            >
              REVOKE
            </LoadingButton>
          );
        }
        return null;
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 1300,
        overflow: "hidden",
        height: "100%",
      }}
    >
      {workspaceInvites?.length > 0 ? (
        <DataGrid
          rows={workspaceInvites}
          columns={columns}
          pagination
          pageSizeOptions={[100, 60, 20]}
          slots={{
            pagination: DataGridPagination,
            toolbar: GridToolbarQuickFilter,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              inputRef: activeSearchField,
              inputProps: {
                placeholder: "Search for members",
              },
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 20 },
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [searchFilter],
              },
            },
          }}
          onFilterModelChange={handleSearchFilter}
          hideFooter={workspaceInvites?.length === 0}
        />
      ) : (
        <Stack
          sx={{
            width: "100%",
            py: 1.5,
            px: 2,
            background: theme.palette.secondary.main,
            borderRadius: 1.5,
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 600 }}>No pending invites</Typography>
        </Stack>
      )}
    </Box>
  );
}
